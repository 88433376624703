import React from 'react'

const BigRing = ({ size, border ,opacity}) => {
    return (
        <div className={` w-[${size}px] h-[${size}px] 
        border-[${border}px] rounded-full border-violet-600 border-opacity-${opacity} `} >

        </div>
    )
}

export default BigRing