import React, { useContext } from 'react'
import GetInTouch from './GetInTouch'
import { AppStore } from '../Context/AppContext'
import { useNavigate } from 'react-router'
import RecentBlog from './Home/RecentBlog'

const LeftColumn = () => {
    let { services } = useContext(AppStore)
    let navigate = useNavigate()

    return (
        <div>
            {/* Services */}
            <main className='rounded-[2rem] text-center worldbg roman py-3 mb-4 text-slate-200  ' >
                <h3 className='text-3xl lg:text-4xl border-b-[1px] py-2 ' >Services  </h3>
                {
                    services && services.map((obj, index) => (
                        <div onClick={() => navigate(`/services/${obj.slug ? obj.slug : 'periodical_bas_preparations'}`)}
                            className={` my-2 py-2 
                        ${index == services.length - 1 ? '' : " border-b-[1px]"} cursor-pointer `} >
                            {obj.title}
                        </div>
                    ))
                }
            </main>

            <GetInTouch mainCss=" worldbg " />
            <RecentBlog />
        </div>
    )
}

export default LeftColumn