import React from 'react'

const WhyChooseUsSection1 = () => {
    let data = [
        {
            title: 'Data Security',
            img: '../../Assets/advanceSecurity.png',
            content: `We know how important your financial data is and do everything we can to protect it. At Accounts Point, we prioritize the privacy and security of your sensitive information and use advanced encryption and multi layered protection protocols. 
            From safeguarding sensitive details to following strict privacy practices, we leave no room for error!`
        },
        {
            title: 'Robust Infrastructure',
            img: '../../Assets/SmoothOperation.png',
            content: `From simple bookkeeping to complex financial analysis, we have our infrastructure built to handle it all. Using advanced accounting software, secure servers, and cloud platforms, we ensure accuracy and efficiency. With modern tools, real-time communication, and seamless processes,
             our services are always timely, error-free, and tailored to meet your financial needs.`
        },
        {
            title: 'Competitive Pricing',
            img: '../../Assets/lowCost.png',
            content: `Running a business comes with expenses, and we make sure managing finances isn’t one of them. Accounts Point offers affordable, high-quality services with no hidden charges. We personalize solutions to your specific financial needs,
             ensuring excellent value and reliable support while staying within your budget at a fair, transparent price.`
        },
    ]
    return (
        <div className='py-4 ' >
            <h4 className='text-5xl roman text-center fw-semibold ' > Why Accounts point ? </h4>
            <p className='text-slate-600 poppins text-center w-[70%] lg:w-[40%] mx-auto my-3 ' >
                We go above & beyond to provide our clients with exceptional service, value, & expertise.
            </p>
            <main className='row container my-3 mx-auto  '>
                {
                    data.map((obj) => (
                        <div className={`col-lg-4  `} >
                            <section className=' ' >
                                <img src={obj.img} alt="Card Image" />
                                <h4 className='roman my-2 text-xl fw-semibold ' > {obj.title} </h4>
                                <p className=' poppins text-sm text-slate-500 my-3 ' >
                                    {obj.content}
                                </p>
                            </section>
                        </div>
                    ))
                }

            </main>
        </div>
    )
}

export default WhyChooseUsSection1

