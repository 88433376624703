import React, { useContext, useEffect, useState } from 'react'
import { AppStore } from '../../Context/AppContext'
import { useNavigate } from 'react-router'
import ServiceCard from '../ServiceCard'
import { Carousel } from 'react-bootstrap'

const Ourservices = () => {
    let { services } = useContext(AppStore)
    let navigate = useNavigate()
    let [chunkSize, setChunkSize] = useState(6)
    const [serviceChunks, setServiceChunks] = useState([]);
    const updateChunkSize = () => {
        const width = window.innerWidth;
        if (width < 640) {
            setChunkSize(2); // Mobile
        } else if (width < 1024) {
            setChunkSize(4); // Tablet
        } else {
            setChunkSize(6); // Laptop
        }
    };

    useEffect(() => {
        updateChunkSize(); // Set initial chunk size
        window.addEventListener("resize", updateChunkSize); // Listen for resize
        return () => window.removeEventListener("resize", updateChunkSize); // Cleanup
    }, []);

    useEffect(() => {
        if (services?.length) {
            const chunks = [];
            for (let i = 0; i < services.length; i += chunkSize) {
                chunks.push(services.slice(i, i + chunkSize));
            }
            setServiceChunks(chunks);
        }
    }, [services, chunkSize]);
    return (
        <div className=' py-5 pb-sm-0' >
            <main className='container row mx-auto ' >
                <div className='text-center ' >
                    <h2 className=' txtclrv poppins fw-semibold my-3 text-xl ' >Our Service </h2>
                    <p className='w-[80%] mx-auto text-4xl sm:text-5xl roman fw-semibold ' >
                        Stay on Track with Expert Tax, Finance, and Accounting Help
                    </p>
                </div>
                {/* {
                    services && services.map((obj) => (
                        <ServiceCard obj={obj} />
                    ))
                } */}
            </main>
            <Carousel indicators className="my-4 py-4">
                {serviceChunks.map((chunk, index) => (
                    <Carousel.Item key={index} interval={3000}>
                        <main className="row col-12 col-sm-11 min-h-[80vh] mx-auto">
                            {chunk.map((obj) => (
                                <ServiceCard key={obj.id} obj={obj} />
                            ))}
                        </main>
                    </Carousel.Item>
                ))}
            </Carousel>
          
        </div>
    )
}

export default Ourservices