import React from 'react'
import InstagramIcon from '../../Icons/InstagramIcon'
import FacebookIcon from '../../Icons/FacebookIcon'
import LinkedInIcon from '../../Icons/LinkedInIcon'

const SocialTag = ({ css }) => {
    return (
        <div className='fixed top-1/2 right-0 -translate-y-1/2 z-[200] p-3 ' >
            <a href="https://www.instagram.com/accountspoint_/" target='_blank'
                className={` duration-300 flex rounded-full p-2 my-2 ${css ? css : "hover:bg-slate-400 txtclrac hover:bg-opacity-35"} `} >
                <InstagramIcon size={20} />
            </a>
            <a href="https://www.facebook.com/people/Accounts-Point/100075747411020/" target='_blank'
                className={` duration-300 flex rounded-full p-2 my-2 ${css ? css : "hover:bg-slate-400 txtclrac hover:bg-opacity-35"} `} >
                <FacebookIcon size={20} />
            </a>
            <a href="https://www.linkedin.com/company/accounts-point/" target='_blank'
                className={` duration-300  flex rounded-full p-2 my-2 ${css ? css : "hover:bg-slate-400 txtclrac hover:bg-opacity-35"} `} >
                <LinkedInIcon size={20} />
            </a>
        </div>
    )
}

export default SocialTag