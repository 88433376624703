import React, { useContext, useEffect } from 'react'
import { AppStore } from '../Context/AppContext'
import Banner from '../Components/Home/Banner'
import Footer from '../Components/Home/Footer'
import BlogCard from '../Components/BlogCard'
import { Spinner } from 'react-bootstrap'
import BlogPagination from '../Components/BlogPagination'

const AllBlogs = () => {
  let { allblog, getBlog, loading } = useContext(AppStore)
  useEffect(() => {
    console.log(allblog);

    if (!allblog)
      getBlog()
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className=' bg-[rgb(255,255,255)] ' >
      <Banner title='Blogs ' page
        content='We are committed to helping your business succeed. 
        We will work with you to simplify your life & reach your goals' />

      {/* Dummy Content */}
      <main className='container items-center row mx-auto py-4 ' >
        <section className=' col-lg-6 ' >
          <h2 className='txtclrv poppins text-xl fw-medium ' >
            Insights on Financial Growth & Accounting Solutions </h2>
          <p className=' text-4xl sm:text-6xl my-3 roman fw-semibold ' >
            Insights on Financial Growth & Accounting Solutions
          </p>
        </section>
        <section className='col-lg-6 ' >
          <p className='poppins text-slate-700  w-[80%] ' >
            Learn how Accounts provides innovative accounting solutions to
            streamline your business operations and enhance financial performance.
          </p>
        </section>
      </main>
      {loading ? <div className='min-h-[50vh] flex ' >
        <Spinner className=' m-auto ' />
      </div> : <>
        {/* Two big Blog */}
        {allblog && <div className='row container mx-auto items-center ' >
          <BlogCard data={allblog[0]} size=" col-lg-6  " />
        </div>}
        <main className='row container  mx-auto ' >
          {
            allblog && allblog.slice(1, 3).map((obj) => (
              <div className='col-lg-6 p-3 ' >
                <BlogCard data={obj} />
              </div>
            ))
          }
        </main>
        {/* Other Blogs */}
        {/* <main className='row container  mx-auto ' >
          {
            allblog && allblog.slice(3).map((obj) => (
              <div className='col-lg-4 p-4 ' >
                <BlogCard data={obj} />
              </div>
            ))
          }
        </main> */}
        <BlogPagination projects={allblog?.slice(3)} />
      </>}
      <Footer />
    </div>
  )
}

export default AllBlogs