import React from 'react'
import BigRing from '../../Icons/BigRing'

const OurTeam = () => {
    let team = [
        {
            img: '../../Assets/testimonialImg.png',
            name: 'CHIDANAND POOJARIKODI',
            description: `With 15+ years of experience across industries like IT, FMCG, and manufacturing, Chidanand specializes in building strong relationships with clients. He’s an expert in accounts, auditing, taxation, and financial services, 
            helping businesses smoothly transition their accounting practices for better efficiency and support.`
        },
        {
            img: '../../Assets/testimonialImg.png',
            name: 'CHANDRAKANTH MENDON',
            description: `Chandrakanth brings 13 years of finance leadership, helping companies scale and make strategic decisions. At Accounts Point, he leads a team of finance directors, ensuring clients get exceptional 
            support and improved financial visibility, efficiency, and scalability for better business growth.`
        },
        {
            img: '../../Assets/testimonialImg.png',
            name: 'N. RAVEENDRANATH KAUSHIK',
            description: `With over 11 years of experience, Kaushik has worked in the public sector, manufacturing, and finance industries. At Accounts Point, he focuses on improving processes through re-engineering, with expertise in costing, 
            pricing, budgeting, and MIS. He has successfully transitioned and migrated clients' accounting systems.`
        },
    ]
    return (
        <>
            <div className='py-5 relative overflow-hidden ' >
                <div className=' absolute z-0 right-0 top-0 -translate-y-1/3 translate-x-1/2' >
                    <BigRing size={350} border={50} opacity={30} />
                </div>
                <main className='mx-auto container relative z-10 ' >
                    <h2 className='text-center poppins text-3xl sm:text-5xl fw-semibold ' >The Faces Behind <br /> Your Financial Management </h2>
                    <p className='w-[80%] sm:w-[60%] text-center mx-auto
                     lg:w-[40%] poppins text-slate-500 my-3 ' >
                        Your business deserves the best, and our experts are here to provide the service,
                        support, and expertise to make that happen.
                    </p>
                    <section className=' my-[40px] flex justify-between flex-wrap ' >
                        {
                            team.map((obj, index) => (
                                <div className='shadow sm:w-[32%] rounded p-3' >
                                    <img src={obj.img} alt="Owner" className='w-[6rem] h-[6rem] object-cover rounded' />
                                    <p className='text-xl text-slate-800 poppins fw-medium my-2 ' >{obj.name} </p>
                                    <p className=' poppins text-[#787785] ' >
                                        {obj.description}
                                    </p>
                                </div>

                            ))
                        }
                    </section>
                </main>
            </div>
            {/* Our client */}
            <main className='bg-[#ebe7f7] mx-auto py-4 ' >
                <section className='row col-12 col-sm-11 mx-auto my-3 ' >
                    <div className=' text-center my-3 order-sm-2 ' >
                        <h5 className='txtclrv poppins text-xl fw-semibold ' >  Our Clients </h5>

                        <h4 className='text-4xl sm:text-5xl lg:text-6xl poppins
                        fw-medium ' >Milestones of Excellence </h4>
                        <p className='w-[90%] sm:w-[80%] mx-auto fw-medium text-slate-500 my-2 ' >
                            We are dedicated to providing top-notch solutions that meet our clients’ needs. Through innovation and hard work,
                            we ensure successful outcomes that build long-term growth and partnerships around the world.
                        </p>
                    </div>
                    <div className='col-6 text-center col-md-3 my-3 my-sm-5 order-2 flex flex-col justify-between ' >
                    
                        <div className=' flex flex-col items-center justify-center fw-semibold 
                        poppins   my-sm-0 md:w-fit   ' >
                            <p className='text-4xl sm:text-6xl tracking-wider txtgrd ' >200+</p>
                            <p className='text-2xl fw-normal ' >Happy Clients </p>
                        </div>
                    </div>
                    <div className='col-6 text-center col-md-3 my-3 my-sm-5 order-2  ' >
                        <div className=' flex flex-col items-center justify-center fw-semibold 
                        poppins my-2 my-sm-0 md:w-fit' >
                            <p className='text-4xl sm:text-6xl tracking-wider txtgrd ' >500+</p>
                            <p className='text-2xl fw-normal ' >Projects Delivered </p>
                        </div>
                    </div>
                    <div className='col-6 text-center col-md-3 my-3 my-sm-5 order-2  ' >
                        <div className=' flex flex-col items-center justify-center fw-semibold 
                        poppins my-2 my-sm-0 md:w-fit' >
                            <p className='text-4xl sm:text-6xl tracking-wider txtgrd ' >15+</p>
                            <p className='text-2xl fw-normal ' >Years of Experience</p>
                        </div>
                    </div>
                    <div className='col-6 text-center col-md-3 my-3 my-sm-5 order-2 ' >
                        <div className=' flex flex-col items-center justify-center fw-semibold 
                        poppins my-2 my-sm-0 md:w-fit ms-md-auto ' >
                            <p className='text-4xl sm:text-6xl tracking-wider txtgrd ' >10+</p>
                            <p className='text-2xl fw-normal ' > Accounting Tools </p>
                        </div>
                    </div>
                </section>

            </main>
        </>
    )
}

export default OurTeam