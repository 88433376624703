import React from 'react';
import StarIcon from '../../Icons/StarIcon';

const RatingCom = ({ num = 3 }) => {
    return (
        <div className='flex gap-3 items-center'>
            {Array.from({ length: num }, (_, index) => (
                <span key={index} className='text-[#DCE100]'>
                    <StarIcon />
                </span>
            ))}
        </div>
    );
};

export default RatingCom;
