import React, { useEffect, useState } from 'react'
import Banner from '../Components/Home/Banner'
import Footer from '../Components/Home/Footer'
import { Route, Routes, useNavigate } from 'react-router'
import EMICalculator from '../Components/About/EMICalculator'
import SipCalculator from '../Components/About/SipCalculator'

const ResourcePage = () => {
  let [activeSection, setActiveSection] = useState('emi')
  let navigate = useNavigate()
  let flags = [
    {
      nation: 'Australia',
      flag: '../Assets/Austriallia.png'
    },
    {
      nation: 'India',
      flag: '../Assets/india.png',
    },
    {
      nation: 'United States',
      flag: '../Assets/USA.png',
    },
    {
      nation: 'United Kingdom',
      flag: '../Assets/UK.png',
    },
    {
      nation: 'Singapore',
      flag: '../Assets/singapore.png',
    },
    {
      nation: 'UAE',
      flag: '../Assets/UAE.png',
    },
    {
      nation: 'New Zealand',
      flag: '../Assets/Newzealand.png',
    },
  ]
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Banner title='Resources for Better Financial Strategy' page
        content='Stay informed with real-time financial data and actionable insights to help guide your business decisions.' />
      <div className='container flex items-center gap-3 mx-auto py-4 poppins fw-semibold  tracking-wide ' >
        <button onClick={() => navigate('/resources')} className={` p-2 duration-500 rounded ${activeSection == 'emi' ? " text-slate-200 bggrd " : ' txtclrv '} `} >
          EMI Calculator
        </button>
        <button onClick={() => navigate('/resources/sipCalculator')} className={` p-2 duration-500 rounded ${activeSection == 'sip' ? " text-slate-200 bggrd " : ' txtclrv '} `} >
          SIP Calculator
        </button>
      </div>
      <div className='container mx-auto ' >
        <Routes>
          <Route path='/' element={<EMICalculator setActiveSection={setActiveSection} />} />
          <Route path='/sipCalculator' element={<SipCalculator setActiveSection={setActiveSection} />} />
        </Routes>

      </div>
      <main className=' my-3 mb-0 py-4 bg-[#ece8f7] ' >
        <div className='container ' >

          <h3 className='text-3xl fw-semibold sm:text-5xl roman text-slate-800 ' > Quick Links </h3>
          <div className='flex flex-wrap my-10 ' >
            {
              flags.map((obj) => (
                <div className='text-center  my-3 w-1/2 sm:w-1/4 ' >
                  <div className='text-center w-fit ' >

                    <img src={obj.flag} alt={obj.nation} loading='lazy' className='rounded' />
                    <p className='text-slate-800 roman fw-semibold my-2 ' > {obj.nation} </p>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default ResourcePage