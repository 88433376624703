import React from 'react'
import { useNavigate } from 'react-router'

const ServiceCard = ({ obj }) => {
    let navigate = useNavigate()
    return (
        <div className='col-sm-6 col-lg-4 my-3 ' >
            <article className='px-2 px-sm-3 flex flex-col justify-between bg-white shadow h-full rounded-xl  ' >
                <div>
                    <img loading='lazy' width={70} src={obj.icon ? obj.icon : require('../GIF/file.gif')} className=' ' alt="File" />
                    <p className='text-xl text-wrap sm:text-2xl my-4 sm:leading-[150%] roman fw-semibold mb-0 ' > {obj.title ? obj.title : "Periodical BAS Preparations"} </p>
                    <p className=' text-sm sm:text-base fw-medium my-2 text-[#AFAFAF] ' >
                        {obj.content ? obj.content : ` Ensuring accurate BAS preparation is crucial for
                                maintaining compliance with tax laws and avoiding penalties or fines.`}
                    </p>
                </div>
                <button onClick={() => navigate(`/services/${obj.slug ? obj.slug : 'periodical_bas_preparations'}`)}
                    className='rounded p-2 w-fit border-[1.5px] border-violet-600 my-3 roman fw-semibold ' >
                    Learn More
                </button>
            </article>
        </div>
    )
}

export default ServiceCard