import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router';
import BlogCard from './BlogCard';

const BlogPagination = ({ projects }) => {
    let [data, setdata] = useState()
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setcurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [showOffcanvas, setOffcanvas] = useState(false)
    const itemsPerPage = 12;
    useEffect(() => {
        if (projects) {
            setdata(projects)
        }
    }, [projects])
    useEffect(() => {
        if (data) {
            const endOffset = itemOffset + itemsPerPage;
            setcurrentItems(data.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(data.length / itemsPerPage));
        }
    }, [itemOffset, data])
    useEffect(() => {
        window.scroll(0, 0);
    }, [data])
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        setItemOffset(newOffset);
        // window.scroll(0, 0)
        document.getElementById('allBlog').scrollIntoView({ behavior: 'smooth', block: 'start' })
    };
    let navigate = useNavigate()
    return (
        <div className=' ' id='allBlog' >
            <main className='row container  mx-auto ' >
                {
                    currentItems && currentItems.map((obj) => (
                        <div className='col-lg-4 p-4 ' >
                            <BlogCard data={obj} />
                        </div>
                    ))
                }
            </main>
            <ReactPaginate
                breakLabel="..."
                nextLabel={null}
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={pageCount}
                previousLabel={null}
                containerClassName='pagination'
                pageLinkClassName='page-num'
                previousLinkClassName='page-num'
                nextLinkClassName='page-num'
                activeLinkClassName='active'
            />
        </div>
    )
}

export default BlogPagination
