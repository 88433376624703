import React, { useEffect, useState } from 'react'
import Banner from '../Components/Home/Banner'
import Footer from '../Components/Home/Footer'
import ContiresWeWork from '../Components/Home/ContiresWeWork'
import RightArrow from '../Icons/RightArrow'
import OurTeam from '../Components/About/OurTeam'

const AboutPage = () => {
    let [selectedIndex, setSelectedIndex] = useState()
    let whyChooseUs = [
        {
            title: 'Qualified Financial experts at your service',
            content: `Get expert advice from professionals who simplify complex concepts and provide clear, personalized financial solutions to manage your finances. `,
        },
        {
            title: 'Real-time updates and timely reminders',
            content: `Receive accurate, real-time financial updates and timely reminders to ensure you never miss important deadlines or opportunities.`,
        },
        {
            title: 'Leveraging the latest tech for financial accuracy',
            content: `We use cutting-edge technology to ensure your financial data is accurate, secure, and tailored for effective decision-making.`,
        },
        {
            title: 'Customized strategies to fit your needs',
            content: `Our team creates personalized financial strategies that cater to your specific needs, helping you achieve lasting financial success.`,
        },
        {
            title: 'Lowering financial risks with smart strategies',
            content: `Minimize risks and protect your finances with smart, well-thought-out strategies that help you make informed decisions.`,
        },
    ]
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Banner page title='About Us' img2="../Assets/aboutBannerNew.png"
                content={`Get to know us better and the experts helping businesses just like 
                yours to thrive by simplifying financial management and ensuring accuracy.`} />
            {/* Who we are */}
            <main className='row container py-5  min-h-[50vh] mx-auto items-center ' >
                <section className='col-md-6 ' >
                    <img src={require('../Assets/aboutSection1.png')} alt="AboutSection"
                        className='  ' />
                </section>
                <section className='col-md-6 ' >
                    <h2 className='txtclrv text-xl my-3 poppins fw-semibold  ' >Who we are </h2>
                    <h6 className='text-3xl sm:text-5xl my-3  fw-semibold lg:text-6xl roman ' >15+ Years of Financial Expertise </h6>
                    <p className='poppins text-slate-500 leading-[180%] fw-medium my-4 ' >
                        At Accounts Point, we take the burden of financial management off your shoulders. As a leading Business Process Outsourcing (BPO)
                        firm located in India, we specialize in financial  management services such as accounting, bookkeeping, business analysis, payroll
                        processing, and taxation. With over 15 years of experience, our dedicated team ensures your
                        financial tasks are handled with precision and care.
                    </p>
                    <p className='poppins text-slate-500 leading-[180%] fw-medium my-4 ' >
                        We work with clients around the world, including businesses in the USA, Australia, Canada, the UK, and Singapore, providing personalized solutions that keep things running smoothly. Whether it's sending you timely updates or reminding you of important deadlines, we’re always here to make sure your finances stay on track, while you focus on scaling your business.
                        Our approach is all about providing a personalized service that fits the unique needs of each client.
                    </p>
                </section>
            </main>
            {/* Why Choose us */}
            <main className='worldbg items-center px-0 gap-4 mx-0 row container-fluid ' >
                <section className='col-md-6 px-0 ' >
                    <img src={require('../Assets/aboutpageGroup1.png')} alt="AboutPage"
                        className='' />
                </section>
                <section className=' col-md-5  ' >
                    <h5 className='text-3xl sm:text-5xl roman lg:text-6xl text-slate-200 fw-semibold ' >Why <br /> Choose us </h5>
                    <p className='text-slate-500 w-[90%] my-3 poppins fw-medium ' >
                        We go above & beyond to provide our clients with exceptional service, value, & expertise.
                    </p>
                    {
                        whyChooseUs.map((obj, index) => (
                            <div onMouseEnter={() => setSelectedIndex(index)}
                                onMouseLeave={() => setSelectedIndex(-1)}
                                className={` cursor-pointer  `} >
                                <div className='flex justify-between p-3 border-[1px] rounded text-slate-200 items-center ' >
                                    <p className='roman mb-0 text-xl fw-semibold ' >{obj.title} </p>
                                    <span className={` ${selectedIndex == index ? ' rotate-90 ' : ''} duration-700 `} > <RightArrow /> </span>
                                </div>
                                <p className={` ${selectedIndex == index ? '  my-2 ' : ' translate-y-3 opacity-0 max-h-0 '} p-3 mb-0
                                my-0 overflow-hidden duration-700 poppins text-slate-500 text-sm`} >
                                    {obj.content}
                                </p>
                            </div>
                        ))
                    }
                </section>
            </main>
            <OurTeam />
            <ContiresWeWork />
            <Footer />
        </div>
    )
}

export default AboutPage