import React from 'react'
import AccountingIcon from '../../Icons/AccountingIcon'
import TaxationIcon from '../../Icons/TaxationIcon'
import PayrollIcon from '../../Icons/PayrollIcon'
import InvoiceIcon from '../../Icons/InvoiceIcon'
import FinancialIcon from '../../Icons/FinancialIcon'
import { useNavigate } from 'react-router'

const ServicesSection = () => {
    let navigate = useNavigate()
    let serviceSectionData = [
        {
            id: 'accounting',
            splTitle: 'Accounting',
            title: '& Book keeping Services',
            content1: `Managing financial records accurately is essential for any business. Our accounting & bookkeeping services ensure your financial data is well-organized, error-free, and up to date. We handle general ledger maintenance, bank reconciliations, 
            cash flow tracking, expense management, and tax-ready reports, allowing you to focus on business growth.`,
            content2: `Our team is experienced with QuickBooks, Xero, Sage, MYOB, and other leading accounting tools, ensuring seamless integration with your existing system. Whether
             you're a small business, startup, or large enterprise, we provide scalable solutions tailored to your needs.`,
            pointHeading: 'Why struggle with complex financial management? Let our experts handle your accounts efficiently!',
            points: [
                `✅  Save up to 50% on accounting costs`,
                `✅  Compliant with international accounting standards`,
                `✅  Data security & confidentiality guaranteed`
            ],
            img: '../../Assets/accountingBookkeeping.png',
            Icon: AccountingIcon,
            cardHigh: 'Accounting',
            cardTitle: '& Book keeping'
        },
        {
            id: 'accounting2',
            splTitle: 'Taxation & Compliance',
            title: '(IT Returns & BAS Preparation)',
            content1: `Tax compliance can be overwhelming, especially when managing multiple tax laws across different countries. We provide expert taxation services, ensuring accurate IT return filing for individuals, companies, and trusts, 
            along with BAS preparation & lodgment for Australian businesses.`,
            content2: `Our taxation experts stay updated with the latest regulations, helping you minimize tax liabilities while ensuring full compliance. Whether you need GST, VAT, corporate tax, or individual tax filing, 
            we offer customized solutions that fit your business needs.`,
            pointHeading: 'Avoid penalties & late fees! Let us handle your tax compliance today.',
            points: [
                `✅ Hassle-free tax return filing for global clients`,
                `✅ Expert BAS & GST preparation for Australian businesses`,
                `✅ Accurate tax planning to maximize savings`
            ],
            img: '../../Assets/taxation.png',
            Icon: TaxationIcon,
            cardHigh: 'Taxation',
            cardTitle: '& Compliance'
        },
        {
            id: 'accounting3',
            splTitle: 'Payroll Processing ',
            title: '& Superannuation Management',
            content1: `Managing payroll efficiently is crucial to keeping your employees satisfied and compliant with legal requirements. Our payroll processing services include salary calculations, payslip generation, 
            tax & superannuation deductions, and payroll reporting.`,
            content2: `We ensure that your payroll is processed on time, accurately, and in full compliance with local tax laws. Whether you run a small team or a large workforce,
             our outsourced payroll solutions eliminate administrative headaches and reduce costs significantly.`,
            pointHeading: 'Stop spending hours on payroll! Let our experts manage it for you.',
            points: [
                `✅ End-to-end payroll management for global businesses`,
                `✅ Superannuation & tax compliance handled seamlessly`,
                `✅ Error-free payroll processing with complete data security.`
            ],
            img: '../../Assets/payrollProcess.png',
            Icon: PayrollIcon,
            cardHigh: 'Payroll',
            cardTitle: 'Processing'
        },
        {
            id: 'accounting4',
            splTitle: 'Invoice',
            title: '& Expense Management',
            content1: `Tracking invoices and managing expenses manually can lead to errors and missed payments. We provide efficient invoice management services, including invoice generation, payment tracking,
             overdue reminders, and vendor/supplier reconciliation.`,
            content2: `Our system helps you reduce outstanding receivables, streamline cash flow, and ensure accurate financial reporting. With automated processes and expert monitoring, 
            you’ll never miss a payment or lose track of your expenses again.`,
            pointHeading: 'No more late payments! Get your invoicing streamlined today.',
            points: [
                `✅ Automated invoice tracking & real-time reporting.`,
                `✅ Error-free accounts payable & receivable management.`,
                `✅ Better cash flow management for improved business growth.`
            ],
            img: '../../Assets/invoiceExpert.png',
            Icon: InvoiceIcon,
            cardHigh: 'Invoice',
            cardTitle: '& Expense'
        },
        {
            id: 'accounting5',
            splTitle: 'Financial Reporting,',
            title: 'Budgeting & Forecasting',
            content1: `Understanding your financial health is crucial for business success. Our financial reporting & forecasting services provide detailed insights, customized reports, 
            and budget planning strategies to help you make informed decisions.`,
            content2: `We create monthly, quarterly, and annual financial reports, along with budget forecasts that align with your business goals. Whether you need profit & loss analysis, cash flow projections, or variance reports,
             we ensure accurate and easy-to-understand financial insights.`,
            pointHeading: 'Stay ahead of financial risks & opportunities! Get expert reports today.',
            points: [
                `✅ Clear, customized reports tailored to your business`,
                `✅ Strategic budgeting & forecasting to optimize growth`,
                `✅ Accurate financial analysis for better decision-making`
            ],
            img: '../../Assets/finnalcialReporting.png',
            Icon: FinancialIcon,
            cardHigh: 'Financial',
            cardTitle: 'Reporting'
        },
    ]
    return (
        <div>
            <main className='lightbg min-h-[30vh] py-4 pb-0' >
                <h6 className=' p-2 px-3 bg-[#b8cdf6] flex gap-2 items-center txtclrac w-fit rounded-full fw-medium lg:fw-semibold mx-auto ' >
                    <p className='w-[0.4rem] h-[0.4rem] bgclrac rounded-full mb-0 ' ></p> Our Services </h6>
                <p className='text-center text-3xl lg:text-4xl my-3 fw-medium lg:fw-semibold w-[80%] lg:w-[70%] mx-auto text-slate-800 ' >
                    Reliable Financial Solutions to Handle Your Business Finances & More success stories
                </p>
                {/* Box */}
                <section className='col-12  col-sm-11 flex flex-wrap items-center mx-auto my-4 mb-0  ' >
                    {
                        serviceSectionData.map((obj, index) => (
                            <article className={` p-3 my-2 w-[50%] sm:w-[30%] lg:w-[20%] `} >
                                <a href={`#${obj.id}`}
                                    className='cursor-pointer hover:scale-[1.01] text-decoration-none
                                 duration-300 bg-white p-3 rounded shadow flex gap-3 items-center ' >
                                    <obj.Icon size={40} />
                                    <div className='txtclrac fw-semibold sm:text-xl ' >
                                        <h4  >{obj.cardHigh} </h4>
                                        <p className='text-slate-800 text-sm sm:text-lg ' >{obj.cardTitle} </p>
                                    </div>
                                </a>
                            </article>
                        ))
                    }

                </section>
            </main>
            {
                serviceSectionData.map((obj, index) => (
                    <main id={obj.id} className={` ${index % 2 == 0 ? ' lightbg ' : ' bg-white '} py-4 min-h-[50vh]`} >
                        <article className='  col-12 col-sm-11 mx-auto row my-3' >
                            <section className={` ${index % 2 == 0 ? '  ' : ' order-2 '} col-lg-6 my-3 `} >
                                <img src={obj.img} alt="Content Image" loading='lazy'
                                    className='w-full h-full rounded-2xl' />
                            </section>
                            <section className=' flex justify-around flex-col my-3 col-lg-6 ' >
                                <div className=' ' >
                                    <h2 className='poppins text-3xl sm:text-4xl fw-medium ' > <span className='txtclrac ' >{obj.splTitle} </span> {obj.title} </h2>
                                    <p className='text-[#353535] my-3' >
                                        {obj.content1}
                                    </p>
                                    <p className='text-[#353535] my-3' >
                                        {obj.content2}
                                    </p>
                                    <h4 className=' text-slate-900 tracking-wide text-xl fw-medium mb-3' > {obj.pointHeading} </h4>
                                    {obj.points.map((val) => (
                                        <p className='my-1 text-sm text-[#353535]' > {val} </p>
                                    ))}
                                </div>
                                <button onClick={() =>
                                    document.getElementById('getfreeQuote').scrollIntoView({ behavior: 'smooth', block: 'center' })} className='bgclrac p-2 px-3 rounded text-slate-50 w-fit text-sm ' >
                                    Get Free Quote
                                </button>
                            </section>

                        </article>
                    </main>
                ))
            }
        </div>
    )
}

export default ServicesSection