import React from 'react'

const ContiresWeWork = () => {
    let flags = [
        {
            nation: 'Australia',
            flag: '../../Assets/Austriallia.png'
        },
        {
            nation: 'India',
            flag: '../../Assets/india.png',
        },
        {
            nation: 'United States',
            flag: '../../Assets/USA.png',
        },
        {
            nation: 'United Kingdom',
            flag: '../../Assets/UK.png',
        },
        {
            nation: 'Singapore',
            flag: '../../Assets/singapore.png',
        },
        {
            nation: 'UAE',
            flag: '../../Assets/UAE.png',
        },
        {
            nation: 'New Zealand',
            flag: '../../Assets/Newzealand.png',
        },
    ]
    return (
        <div className=' relative ' >
            {/* <div className='polygon relative top-[0.7px] worldbg p-0 border-0 min-h-[200px] m-0   ' >
            </div> */}
            <div className='polygon d-none  absolute bg-red-300 -translate-y-[100%] worldbg p-0 border-0 min-h-[200px] m-0   ' >
            </div>
            <main className='worldbg min-h-[50vh] m-0 overflow-hidden relative py-3 container-fuild px-0 mx-0 ' >
                <article className=' row container relative z-1 mx-auto ' >

                    <section className='h-full col-lg-8 p-3 ' >
                        <h2 className='roman text-4xl sm:text-5xl w-[90%] lg:text-7xl text-slate-200 '>
                            Serving Businesses Across the Globe </h2>
                        <p className='text-slate-400 lg:w-[70%] my-4 poppins  ' >
                            We bring global expertise to the table with a deep understanding of local financial regulations.
                            Our team serves businesses worldwide,
                            providing reliable accounting services that keep you compliant no matter where you operate.
                        </p>
                        <div className='flex flex-wrap my-10 ' >
                            {
                                flags.map((obj) => (
                                    <div className='text-center my-3 w-1/2 sm:w-1/4 ' >
                                        <div className='text-center w-fit ' >
                                            <img src={obj.flag} alt={obj.nation} className=' rounded ' loading='lazy' />
                                            <p className='text-slate-200 roman fw-semibold my-2 ' > {obj.nation} </p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </section>
                </article>
                <video src={require('../../Assets/earthloop.mp4')} autoPlay={true} loop='infinite'
                    className=' bottom-0 sm:top-0 -right-[30%] absolute w-[100%] -rotate-[21deg] sm:w-[90%] z-0  ' ></video>
            </main>

        </div>
    )
}

export default ContiresWeWork