import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import TriangleIcon from '../Icons/TriangleIcon'
import NumberCounter from '../Components/NumberCounter'
import ContactSection from '../Components/AccountsBookkeeping/ContactSection'
import ServicesSection from '../Components/AccountsBookkeeping/ServicesSection'
import VideoPlayer from '../Components/VideoPlayer'
import TestimonialSection from '../Components/AccountsBookkeeping/TestimonialSection'
import WhyChooseUs from '../Components/AccountsBookkeeping/WhyChooseUs'
import SocialTag from '../Components/AccountsBookkeeping/SocialTag'

const AccountingAndBookKeeping = () => {
    let [show, setShow] = useState()
    let navigate = useNavigate()
    return (
        <div className=' poppins relative overflow-hidden ' >
            <VideoPlayer show={show} setShow={setShow} />
            <SocialTag />
            {/* Hero section */}
            <div className='min-h-[100vh] ' >

                <main className=' bgclrac h-[90vh]  overflow-hidden relative w-full' >
                    <img  src={require('../Assets/accountingbookeepingserviceBanner.png')}
                        loading='lazy' alt="AccountingBanner"
                        className='cursor-pointer absolute top-1/2 -translate-y-1/2 right-0 d-none d-sm-block h-[90vh] lg:w-[55%] object-cover ' />
                    <section className='p-3  top-0 relative z-10 flex justify-between items-center col-12 col-sm-11 mx-auto ' >
                        <img onClick={()=>navigate('/')} src={require('../Assets/accountsPointWhiteLogo.png')}
                            loading='lazy' className='w-[150px] cursor-pointer' alt="White logo" />
                        <button onClick={()=>navigate('/contact-us')} className='bgclrac p-2 rounded px-3 text-slate-50 ' >
                            Contact Us
                        </button>
                    </section>
                    {/* Container section countings */}
                    <div className=' row relative col-12 col-sm-11 mx-auto  my-auto min-h-[65vh] flex ' >
                        <section className=' col-lg-7 my-auto ' >
                            {/* Watch Video */}
                            <article className=' min-h-[15vh]  py-3  relative items-center text-slate-50 flex gap-[18px] ' >
                                <div onClick={() => setShow(true)} className='dropani  bg-slate-50 bg-opacity-40 rounded-full ' >
                                    <button className='flex txtclrac bg-slate-50 w-[3rem] h-[3rem]  rounded-full  items-center justify-center ' >
                                        <TriangleIcon w={40} />
                                    </button>
                                </div>
                                {/* <p className='mb-0 fw-light ' > Watch Video </p> */}
                            </article>
                            <h1 className=' fw-light text-slate-50 text-4xl sm:text-5xl lg:text-5xl tracking-wide ' >
                                Outsource Accounting & Bookkeeping <span className=' fw-semibold '>
                                    to Experts in India – Save Time & Costs!"</span>
                            </h1>
                            <p className='text-slate-100 my-3 fw-light tracking-wide w-[80%] ' >
                                Reliable, Cost-Effective & Scalable Accounting Solutions for Businesses in the USA, Australia & More.
                            </p>
                            <button className=' my-3 border-slate-50 border-2 rounded text-slate-50 p-2 px-3 ' >
                                Get In Touch
                            </button>
                        </section>
                    </div>

                </main>
                {/* Statistics */}
                <section className=' col-12 col-sm-11 min-h-[15vh] shadow rounded-xl p-3 row bg-white  mx-auto -translate-y-1/3 sm:-translate-y-1/2 ' >
                    <div className='col-6 col-sm-3 border-e-2 poppins text-center ' >
                        <p className='text-3xl sm:text-4xl tracking-wider txtclrac fw-semibold' >
                            <NumberCounter start={192} num={200} timing={93} /> +</p>
                        <p className='text-xl sm:text-xl fw-normal fw-semibold ' >Happy Clients </p>
                        <p className='text-slate-700 text-sm w-[80%] mx-auto my-2 ' >
                            Trusted by businesses across industries
                        </p>
                    </div>
                    <div className='col-6 col-sm-3 border-e-2 poppins text-center ' >
                        <p className='text-3xl sm:text-4xl tracking-wider txtclrac fw-semibold' >
                            <NumberCounter start={8} num={15} timing={93} /> +</p>
                        <p className='text-xl sm:text-xl fw-normal fw-semibold ' > Years of Experience</p>
                        <p className='text-slate-700 text-sm w-[80%] mx-auto my-2 ' >
                            Proven expertise in accounting & taxation
                        </p>
                    </div>
                    <div className='col-6 col-sm-3 sm:border-e-2 poppins text-center ' >
                        <p className='text-3xl sm:text-4xl tracking-wider txtclrac fw-semibold' >
                            <NumberCounter start={3} num={10} timing={93} /> +</p>
                        <p className='text-xl sm:text-xl fw-normal fw-semibold ' >Accounting Tools Used  </p>
                        <p className='text-slate-700 text-sm w-[80%] mx-auto my-2 ' >
                            Proficient in Xero, QuickBooks, Sage & more
                        </p>
                    </div>
                    <div className='col-6 col-sm-3  poppins text-center ' >
                        <p className='text-3xl sm:text-4xl tracking-wider txtclrac fw-semibold' >
                            <NumberCounter start={0} num={7} timing={93} /> +</p>
                        <p className='text-xl sm:text-xl fw-normal fw-semibold ' >Countries Served  </p>
                        <p className='text-slate-700 text-sm w-[70%] mx-auto my-2 ' >
                            USA, Australia, UK, Canada & more
                        </p>
                    </div>
                </section>
            </div>
            {/* Contact section  */}
            <ContactSection />
            <ServicesSection />
            <TestimonialSection />
            <WhyChooseUs />
            {/* Footer */}
            <main className='poppins py-4 col-12 col-sm-11 mx-auto ' >
                <h5 className=' text-3xl text-center my-2 fw-semibold ' > Get a Free Consultation & Custom Pricing </h5>
                <p className=' text-slate-700 text-sm text-center ' > Headquartered in India | Serving Clients Across the USA, Australia, UK & More </p>
                <section className=' row my-4 py-2 ' >
                    <div className='col-lg-4 my-2 ' >
                        <a href="tel:+919980331886" target='_blank' className='text-slate-700 text-decoration-none block my-2 '>
                            Call/WhatsApp: +91-99-803-31886</a>
                        <a href="mailto:info@accountspoint.com" target='_blank' className='text-slate-700 text-decoration-none block my-2 '>
                            Email :info@accountspoint.com</a>
                        <a href="https://www.accountspoint.com" target='_blank' className='text-slate-700 text-decoration-none block my-2 '>
                            Visit : www.accountspoint.com</a>
                    </div>
                    <div className='col-lg-4 my-2 flex ' >
                        <button onClick={() =>
                            document.getElementById('getfreeQuote').scrollIntoView({ behavior: 'smooth', block: 'center' })}
                            className='p-2 px-3 rounded bgclrac text-white m-auto ' >
                            Get Free Quote
                        </button>
                    </div>
                    <div className=' col-lg-4 my-2 flex ' >
                        <img onClick={() => navigate('/')} src={require('../Assets/accountsPointLogo.png')} alt="Logo"
                            className='ms-auto  cursor-pointer ' />
                    </div>
                </section>
                <hr />
                <div className='mt-3 flex justify-between items-center gap-3 flex-wrap ' >
                    <a href="" className='text-decoration-none text-slate-700 ' >
                        Copyright © 2024 All Rights Reserved.
                    </a>
                    <a href="" className='text-decoration-none text-slate-700 ' >
                        privacy policy / terms and conditions
                    </a>
                </div>
            </main>
        </div>
    )
}

export default AccountingAndBookKeeping
