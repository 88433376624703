import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { port } from '../App'

const JobApplying = ({ availableJobs }) => {
    let [formData, setFormData] = useState({
        name: '',
        job: '',
        DOB: '',
        state: '',
        location: '',
        linkedin_profile: '',
        resume: null
    })
    let handleChange = (e) => {
        let { name, value, files } = e.target
        if (name == 'resume') {
            setFormData((prev) => ({
                ...prev,
                resume: files[0]
            }))
            return
        }
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let [errors, setErrors] = useState({})
    let [inputCredentials, setInputCredentials] = useState()
    useEffect(() => {
        if (availableJobs) {
            console.log([...availableJobs].map((obj) => ({ value: obj.id, label: obj.Title })), 'jobtitle');

            setInputCredentials(
                {
                    name: { label: 'Name', type: 'text', placeholder: '', inputcss: '' },
                    job: {
                        label: 'Job Title', type: 'text', placeholder: '', inputcss: '',
                        options: [...availableJobs].map((obj) => ({ value: obj.id, label: obj.Title }))
                    },
                    DOB: { label: 'DOB', type: 'date', placeholder: '', inputcss: '' },
                    state: { label: 'State', type: 'text', placeholder: '', inputcss: '' },
                    location: { label: 'Location', type: 'text', placeholder: '', inputcss: '' },
                    linkedin_profile: { label: 'Linkedin Profile', type: 'text', placeholder: '', inputcss: '' },
                }
            )
        }
    }, [availableJobs])
    let formValidate = () => {
        let newErrors = {}
        Object.keys(formData).forEach((field) => {
            if (formData[field] == null || formData[field] == '') {
                newErrors[field] = '* This field is required'
            }
        })
        setErrors(newErrors)
        return Object.keys(newErrors).length == 0
    }
    let reset = () => {
        setFormData({
            name: '',
            job: '',
            DOB: '',
            state: '',
            location: '',
            linkedin_profile: '',
            resume: null
        })
    }
    let submitForm = () => {
        console.log(formData);

        if (formValidate()) {
            const formData2 = new FormData()
            formData2.append('DOB', formData.DOB)
            formData2.append('job', formData.job)
            formData2.append('linkedin_profile', formData.linkedin_profile)
            formData2.append('location', formData.location)
            formData2.append('name', formData.name)
            formData2.append('resume', formData.resume)
            formData2.append('state', formData.state)

            axios.post(`${port}/blog/job_apply`, formData2).then((response) => {
                reset()
                toast.success('Form Submitted')
            }).catch((error) => {
                console.log(error);
                toast.error('Error occured')
            })
        }
    }
    return (
        <div className='py-5 bg-[rgb(235,232,246)] mt-3' >
            <main className='flex col-12 col-sm-11 col-lg-9 max-w-[95%] p-3 bg-white
             shadow rounded mx-auto flex-wrap justify-around ' >
                {
                    inputCredentials && Object.keys(inputCredentials).map((field) => {
                        let input = inputCredentials[field]

                        return (
                            <section className='w-[100%] sm:w-[44%] lg:w-[45%] ' >
                                <label htmlFor="" className='poppins text-blue-800 fw-medium text-sm ' > {input.label}
                                    <span className='text-red-600 text-xs ms-2 ' >{errors && errors[field] ? errors[field] : '*'} </span> </label>
                                {input.options ?
                                    <select name={field} onChange={handleChange} value={formData[field]}
                                        className='outline-none block border-[1px] w-full rounded p-2 border-slate-600 my-3 ' id="">
                                        <option value="">Select</option>
                                        {
                                            input.options.map((obj) => (
                                                <option value={obj.value}> {obj.label} </option>
                                            ))
                                        }
                                    </select>
                                    : <input type={input.type ? input.type : 'text'}
                                        value={formData[field]} name={field} onChange={handleChange}
                                        className='outline-none block border-[1px] w-full rounded p-2 border-slate-600 my-3  ' />}
                            </section>
                        )
                    }
                    )}
                <div className='w-[95%] mx-auto ' >
                    <label htmlFor="" className='poppins text-blue-800 fw-medium text-sm ' > Resume
                        <span className='text-red-600 ' >{errors && errors.resume ? errors.resume : '*'}  </span> </label>
                    <div className='w-full flex flex-col items-center border-dotted border-2 rounded p-3 my-2 border-slate-700 ' >
                        <label className='poppins cursor-pointer text-center mx-auto ' htmlFor="resume">
                            Click the Upload and Submit the resume
                        </label>
                        <input type="file" onChange={handleChange} name='resume' id='resume' className=' hidden ' />
                        {formData.resume && <div>
                            File Uploaded : {formData.resume?.name}
                        </div>}
                        <label htmlFor="resume" className=' cursor-pointer mx-auto mt-3 worldbg
                         text-slate-200 p-2 rounded text-sm poppins  ' >
                            Upload File
                        </label>
                    </div>
                </div>
                <div className='w-[95%] mx-auto  ' >
                    <button onClick={submitForm} className='border-blue-800 text-blue-800 fw-medium 
                ms-auto flex my-3 border-[1.3px] rounded p-2 px-3 txtclr ' >
                        Submit
                    </button>
                </div>
            </main>

        </div>
    )
}

export default JobApplying