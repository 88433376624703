import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { AppStore } from '../Context/AppContext'
import { Spinner } from 'react-bootstrap'
import Banner from '../Components/Home/Banner'
import Footer from '../Components/Home/Footer'
import BigRing from '../Icons/BigRing'
import LeftColumn from '../Components/LeftColumn'

const ParticularServicePage = () => {
    let { slug } = useParams()
    let [loading, setLoading] = useState(false)
    let { services } = useContext(AppStore)
    let [data, setData] = useState()
    useEffect(() => {
        window.scrollTo(0, 0)
        if (slug && services) {
            setLoading(true)
            let serviceFound = services.find((obj) => obj.slug == slug)
            setData(serviceFound)
            console.log(serviceFound, 'service');
            setLoading(false)
        }
    }, [slug, services])
    return (
        <div>
            {
                loading && !data ? <div className='min-h-[100vh] flex ' >
                    <Spinner className=' m-auto ' />
                </div> : data &&
                <main className='overflow-hidden ' >
                    <Banner title={data.title} page content={data.bannerContent} />

                    <article className=' py-4 ' >
                        {/* What is that services */}
                        {data.whatContent &&
                            <section className='py-4 text-center container mx-auto' >
                                <h3 className='roman text-4xl fw-semibold ' >
                                    {data.whatContent.qns} </h3>
                                <p className='poppins w-[90%] mx-auto my-3 text-slate-500 sm:w-[80%] ' > {data.whatContent.ans} </p>
                            </section>
                        }

                        <section className='relative container-fluid px-0 m-x0 ' >
                            <img src={require('../Assets/design1.png')} alt="Design" className=' z-0 w-full absolute top-0 ' />
                            {/* Why content */}
                            <article className='container mx-auto items-center row relative z-10 ' >

                                <div className='col-md-6 my-3 relative ' >
                                    <h2 className='roman text-4xl fw-semibold ' >
                                        {data && data.whyContent && data.whyContent.qns ? data.whyContent.qns :
                                            " Why is BAS Important?"}</h2>
                                    <p className='poppins w-[90%] my-3 text-slate-500 sm:w-[80%] ' >
                                        {data && data.whyContent && data.whyContent.content ? data.whyContent.content : `The BAS plays a pivotal role in maintaining the financial
                                        health and legal compliance of a business. By preparing and lodging BAS periodically, businesses can:`}
                                    </p>
                                    <ul className=' poppins text-slate-500 list-disc list-inside ' >
                                        {
                                            data && data.whyContent && data.whyContent.points ? data.whyContent.points.map((val) => (
                                                <li className='my-3 ' > {val} </li>
                                            )) :
                                                <li className='my-3 ' > Stay Compliant </li>}

                                    </ul>
                                </div>
                                <div className='col-md-6 my-3 relative ' >
                                    <img src={require('../Assets/servicecard.png')}
                                        alt="Service Card" className='ms-auto ' />
                                </div>
                            </article>
                            {/* Our Content */}
                            {data && data.offerContent &&
                                <article className='rounded-[2rem] row  worldbg p-4 relative z-10 mx-2 ' >
                                    <section className=' ' >
                                        <article className='col-md-6 col-lg-5 mx-auto text-center' >
                                            <h2 className='text-3xl sm:text-5xl  fw-medium roman text-slate-200 '>
                                                {data.offerContent.title ? data.offerContent.title : " Our Periodical BAS Preparation Service"} </h2>
                                            <p className='poppins text-[#787785] my-3 ' >
                                                {data.offerContent.content ? data.offerContent.content :
                                                    ` We simplify the complexities of BAS preparation with tailored services
                                                from our expert accounting team. Here's what we offer:`}
                                            </p>
                                        </article>
                                    </section>
                                    {/* Array of content */}
                                    {
                                        data.offerContent.listObj?.map((obj) => (
                                            <div className=' col-6 col-lg-4 my-4 text-center flex flex-col gap-4 items-center ' >
                                                <div className=' p-2 bg-slate-50 rounded-full ' >
                                                    <img src={require('../Assets/moneyBag.png')} alt="Money Bag" className=' ' width={50} />
                                                </div>
                                                <h3 className='roman text-slate-200 fw-semibold text-2xl ' > {obj.title ? obj.title : 'Detailed Record Review '} </h3>
                                                <p className=' poppins text-sm  w-[80%] mx-auto text-[#787785] ' >
                                                    {
                                                        obj.content ? obj.content :
                                                            `We review your financial records, including income, expenses, and GST transactions, to ensure accuracy for BAS lodgment.`
                                                    }
                                                </p>
                                            </div>
                                        ))
                                    }
                                </article>}
                            {/* Who Can benifit from our service */}
                            {data && data.whoContent && <main className='row container mx-auto py-4 items-center ' >
                                <section className='col-lg-6 flex flex-wrap justify-between relative ' >
                                    <div className='absolute top-1/2 left-1/2 -translate-x-1/2 z-0 -translate-y-1/2 ' >
                                        <div className='h-[300px] w-[300px] border-[45px] border-violet-600 border-opacity-50 rounded-full ' ></div>
                                    </div>
                                    {
                                        data.whoContent.listObj?.map((obj, index) => (
                                            <div className={` shadow text-slate-200 relative z-10 p-2 sm:p-3 min-h-[30vh] 
                                            rounded-xl bggrd2 w-[45%] text-center my-3 flex flex-col items-center justify-center`} >
                                                <img src={require('../Assets/storeIcon.png')} alt="StoreIcon"
                                                    className=' mx-auto w-[3rem] sm:w-fit ' />
                                                <h4 className='roman text-2xl my-3 fw-medium ' > {obj.title} </h4>
                                                <p className='poppins text-sm text-slate-300 ' >
                                                    {obj.content ? obj.content : "Owners who need assistance navigating complex tax requirements."}
                                                </p>
                                            </div>
                                        ))
                                    }

                                </section>
                                <section className='col-lg-6 text-end ' >
                                    <h2 className='roman text-4xl sm:text-5xl fw-semibold ' >
                                        {data.whoContent.title ? data.whoContent.title : "Who Can Benefit from Our Service?"} </h2>
                                    <p className='poppins text-slate-600 my-4 ' >
                                        {data.whoContent.content ? data.whoContent.content : `  Our services are tailored to meet the needs of individuals and organizations across various fields. Whether you’re seeking to enhance efficiency, simplify complex tasks, or achieve your goals with greater ease, our solutions are designed to provide the support you need. By combining innovation with a customer-focused approach, we ensure that anyone looking for reliable, effective, and adaptable services can benefit from what we offer.
                                        No matter your requirements, we are committed to helping you succeed.`}
                                    </p>
                                </section>
                            </main>}
                            {/* With Left Bar */}
                            <main className='row container mx-auto ' >
                                <section className='col-sm-6 col-md-5 col-lg-4 ' >
                                    <LeftColumn />
                                </section>
                                <section className='col-sm-6 col-md-7 col-lg-8 ' >
                                    {
                                        data && data.rightContent && data.rightContent?.map((obj) => (
                                            <main className={` `} >
                                                <h3 className={`roman my-3 fw-semibold text-3xl sm:text-5xl `} > {obj.title} </h3>
                                                {
                                                    obj.arrycontent?.map((obj2) => (
                                                        <div>
                                                            <h6 className={`fw-semibold roman my-2 text-2xl text-slate-800 `} > {obj2.title} </h6>
                                                            <p className='poppins  text-slate-600 my-3 ' > {obj2.content} </p>
                                                        </div>
                                                    ))
                                                }
                                            </main>
                                        ))
                                    }
                                </section>

                            </main>
                        </section>

                    </article>
                    <Footer />
                </main>
            }

        </div>
    )
}

export default ParticularServicePage