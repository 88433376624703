import axios from 'axios'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { port } from '../App'
import GetQuoteForm from './AccountsBookkeeping/GetQuoteForm'

const GetInTouch = ({ mainCss, name }) => {
    let [formData, setFormData] = useState({
        first_name: '',
        phone: '',
        email: '',
        message: '',
    })
    let handleChange = (e) => {
        let { name, value } = e.target
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let submitForm = () => {
        if (formData.email != '' && formData.first_name != '' && formData.phone != '') {
            axios.post(`${port}/blog/ContactsInformation/`, formData).then((response) => {
                toast.success('Form Submitted')
                setFormData({
                    first_name: '',
                    phone: '',
                    email: '',
                    message: '',
                })
            }).catch((error) => {
                console.log(error);
                toast.error('Error occured')

            })
        }
        else
            toast.warning('Fill all the fields')
    }
    return (
        <div>
            <main className={`min-h-[30vh] poppins text-slate-300 py-4 w-full p-3 rounded-[2rem] ${mainCss ? mainCss : "bg-violet-500/20"} `} >
                {/* <h6 className='text-center text-3xl poppins uppercase   ' >{name ? name : " Get In Touch"} </h6>
                <div className='w-[80%] mx-auto my-3  ' >
                    <label htmlFor="" className='text-sm ' > Enter Your Name </label>
                    <input type="text" name='first_name' onChange={handleChange} value={formData.first_name} className='bg-transparent border-opacity-25 border-[1px]
                    p-2 rounded-sm w-full block outline-none my-2 ' />
                </div>
                <div className='w-[80%] mx-auto my-3  ' >
                    <label htmlFor="" className='text-sm ' > Enter Your Mail </label>
                    <input type="text" name='email' onChange={handleChange} value={formData.email} className='bg-transparent border-opacity-25 border-[1px]
                    p-2 rounded-sm w-full block outline-none my-2 ' />
                </div>
                <div className='w-[80%] mx-auto my-3  ' >
                    <label htmlFor="" className='text-sm ' > Enter Your Phone </label>
                    <input type="text" name='phone' onChange={handleChange} value={formData.phone} className='bg-transparent border-opacity-25 border-[1px]
                    p-2 rounded-sm w-full block outline-none my-2 ' />
                </div>
                <button onClick={submitForm} className='border-[1px] p-2 rounded px-3 mx-auto flex my-4 ' >
                    Submit
                </button> */}
                <GetQuoteForm page />
            </main>
        </div>
    )
}

export default GetInTouch