import React, { useEffect } from 'react'
import Banner from '../Components/Home/Banner'
import Testimonial from '../Components/Home/Testimonial'
import Footer from '../Components/Home/Footer'
import WhyChooseUsSection1 from '../Components/About/WhyChooseUsSection1'
import ContiresWeWork from '../Components/Home/ContiresWeWork'

const HowWeWork = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Banner title='Why Accounts Point?'
                page
                img2='../Assets/howWeWorkBanner.png'
                content='Find out how we simplify complex tasks by handling the hard part of financial management, so you can concentrate on scaling your business.' />
            {/* Our methodlogy */}
            <WhyChooseUsSection1 />
            <main className='py-5 row items-center container mx-auto ' >
                <div>
                    <h2 className=' text-start roman text-4xl sm:text-6xl fw-semibold ' >
                        Finance & Taxation Services BPO Work Process </h2>
                    <p className=' poppins text-slate-500 text-start w-[80%] lg:w-[50%] my-3 fw-medium ' >
                        With our structured approach, you are assured that your financial needs are met precisely and with care.
                    </p>
                </div>
                <section className=' col-md-6 order-1 my-2 ' >
                    <article className='flex gap-2' >
                        <span className='txtgrd roman text-3xl fw-semibold ' >#1</span>
                        <div>
                            <h3 className=' text-3xl roman fw-semibold ' >Document Requisition</h3>
                            <p className='text-slate-500 my-2 w-[90%] poppins ' >
                                We’ll share a list of documents we need for bookkeeping and tax preparation.
                                You provide them, and we handle the rest with care.
                            </p>
                        </div>
                    </article>
                </section>
                <section className='col-md-6 order-2 ' >
                    <img src={require('../Assets/methodology1.png')} alt="Methodology1"
                        className='mx-auto' />
                </section>
                <section className=' col-md-6 order-3 order-md-4 my-2 ' >
                    <article className='flex gap-2' >
                        <span className='txtgrd roman text-3xl fw-semibold ' >#2</span>
                        <div>
                            <h3 className=' text-3xl roman fw-semibold ' >Processing of Documents                           </h3>
                            <p className='text-slate-500 my-2 w-[90%] poppins ' >
                                Scanned copies of all documents are securely stored, organized,
                                and processed using accounting software for easy access and management.</p>
                        </div>
                    </article>
                </section>
                <section className='col-md-6 order-4 order-md-3 ' >
                    <img src={require('../Assets/methodology2.png')} alt="Methodology1"
                        className='mx-auto' />
                </section>
                <section className=' col-md-6 order-5 my-2 ' >
                    <article className='flex gap-2' >
                        <span className='txtgrd roman text-3xl fw-semibold ' >#3</span>
                        <div>
                            <h3 className=' text-3xl roman fw-semibold ' >Data Review and Reporting                          </h3>
                            <p className='text-slate-500 my-2 w-[90%] poppins ' >
                                Financial details are reviewed thoroughly to create accurate, easy-to-read reports that provide valuable insights into your business performance.                           </p>
                        </div>
                    </article>
                </section>
                <section className='col-md-6 order-6 ' >
                    <img src={require('../Assets/methodology3.png')} alt="Methodology1"
                        className='mx-auto' />
                </section>
                <section className=' col-md-6 order-7 md:order-8 my-2 ' >
                    <article className='flex gap-2' >
                        <span className='txtgrd roman text-3xl fw-semibold ' >#4</span>
                        <div>
                            <h3 className=' text-3xl roman fw-semibold ' >Tax Preparation and Compliance</h3>
                            <p className='text-slate-500 my-2 w-[90%] poppins ' >
                                Year-end financials are carefully prepared to meet tax rules, ensuring everything is accurate and fully compliant.
                            </p>
                        </div>
                    </article>
                </section>
                <section className='col-md-6 order-8 md:order-6 ' >
                    <img src={require('../Assets/methodology4.png')} alt="Methodology1"
                        className='mx-auto' />
                </section>
                <section className=' col-md-6 order-9 my-2 ' >
                    <article className='flex gap-2' >
                        <span className='txtgrd roman text-3xl fw-semibold ' >#5</span>
                        <div>
                            <h3 className=' text-3xl roman fw-semibold ' >Report Delivery                      </h3>
                            <p className='text-slate-500 my-2 w-[90%] poppins ' >
                                Finalized reports are delivered accurately, keeping you fully informed about your financial health and confident in your business decisions.
                            </p>
                        </div>
                    </article>
                </section>
                <section className='col-md-6 order-10    ' >
                    <img src={require('../Assets/methodology3.png')} alt="Methodology1"
                        className='mx-auto' />
                </section>
            </main>


            {/* <Testimonial /> */}
            <main className='bg-[#ebe7f7] mx-auto py-4 ' >
                <section className='row col-12 col-sm-11 mx-auto my-3 ' >
                    <div className=' text-center my-3 order-sm-2 ' >
                        <h5 className='txtclrv poppins text-xl fw-semibold ' >  Our Clients </h5>

                        <h4 className='text-4xl sm:text-5xl lg:text-6xl poppins
                        fw-medium my-2' >Milestones of Excellence </h4>
                        <p className='w-[90%] sm:w-[80%] mx-auto fw-medium text-slate-500 my-2 ' >
                            We are dedicated to providing top-notch solutions that meet our clients’ needs. Through innovation and hard work,
                            we ensure successful outcomes that build long-term growth and partnerships around the world.
                        </p>
                    </div>
                    <div className='col-6 text-center col-md-3 my-3 my-sm-5 order-2 flex flex-col justify-between ' >

                        <div className=' flex flex-col items-center justify-center fw-semibold 
                        poppins   my-sm-0 md:w-fit   ' >
                            <p className='text-4xl sm:text-6xl tracking-wider txtgrd ' >200+</p>
                            <p className='text-2xl fw-normal ' >Happy Clients </p>
                        </div>
                    </div>
                    <div className='col-6 text-center col-md-3 my-3 my-sm-5 order-2  ' >
                        <div className=' flex flex-col items-center justify-center fw-semibold 
                        poppins my-2 my-sm-0 md:w-fit' >
                            <p className='text-4xl sm:text-6xl tracking-wider txtgrd ' >500+</p>
                            <p className='text-2xl fw-normal ' >Projects Delivered </p>
                        </div>
                    </div>
                    <div className='col-6 text-center col-md-3 my-3 my-sm-5 order-2  ' >
                        <div className=' flex flex-col items-center justify-center fw-semibold 
                        poppins my-2 my-sm-0 md:w-fit' >
                            <p className='text-4xl sm:text-6xl tracking-wider txtgrd ' >15+</p>
                            <p className='text-2xl fw-normal ' >Years of Experience</p>
                        </div>
                    </div>
                    <div className='col-6 text-center col-md-3 my-3 my-sm-5 order-2 ' >
                        <div className=' flex flex-col items-center justify-center fw-semibold 
                        poppins my-2 my-sm-0 md:w-fit ms-md-auto ' >
                            <p className='text-4xl sm:text-6xl tracking-wider txtgrd ' >10+</p>
                            <p className='text-2xl fw-normal ' > Accounting Tools </p>
                        </div>
                    </div>
                </section>

            </main>
            <ContiresWeWork />
            <Footer dark />
        </div>
    )
}

export default HowWeWork
