import React from 'react'

const AccountingIcon = ({ size }) => {
    return (
        <svg width={size ? size : "60"} height={size ? size : "60"} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M46.25 0H13.75C10.1045 0.00397042 6.60943 1.4539 4.03167 4.03167C1.4539 6.60943 0.00397042 10.1045 0 13.75L0 46.25C0.00397042 49.8955 1.4539 53.3906 4.03167 55.9683C6.60943 58.5461 10.1045 59.996 13.75 60H46.25C49.8955 59.996 53.3906 58.5461 55.9683 55.9683C58.5461 53.3906 59.996 49.8955 60 46.25V13.75C59.996 10.1045 58.5461 6.60943 55.9683 4.03167C53.3906 1.4539 49.8955 0.00397042 46.25 0ZM52.5 46.25C52.5 47.9076 51.8415 49.4973 50.6694 50.6694C49.4973 51.8415 47.9076 52.5 46.25 52.5H45V50C45 46.0218 43.4197 42.2064 40.6066 39.3934C37.7936 36.5804 33.9782 35 30 35C26.0218 35 22.2064 36.5804 19.3934 39.3934C16.5804 42.2064 15 46.0218 15 50V52.5H13.75C12.0924 52.5 10.5027 51.8415 9.33058 50.6694C8.15848 49.4973 7.5 47.9076 7.5 46.25V13.75C7.5 12.0924 8.15848 10.5027 9.33058 9.33058C10.5027 8.15848 12.0924 7.5 13.75 7.5H46.25C47.9076 7.5 49.4973 8.15848 50.6694 9.33058C51.8415 10.5027 52.5 12.0924 52.5 13.75V46.25Z"
                fill="#282828" />
            <path d="M30.0002 29.9998C34.8327 29.9998 38.7502 26.0822 38.7502 21.2498C38.7502 16.4173 34.8327 12.4998 30.0002 12.4998C25.1678 12.4998 21.2502 16.4173 21.2502 21.2498C21.2502 26.0822 25.1678 29.9998 30.0002 29.9998Z"
                fill="#282828" />
        </svg>
    )
}

export default AccountingIcon