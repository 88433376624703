import React from 'react'

const InvoiceIcon = ({ size }) => {
    return (
        <svg width={size ? size : "60"} height={size ? size : "60"} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M60 47.5C60 51.635 56.635 55 52.5 55V57.5C52.5 58.88 51.3825 60 50 60C48.6175 60 47.5 58.88 47.5 57.5V55H46.83C44.1625 55 41.6725 53.565 40.335 51.2525C39.6425 50.0575 40.0525 48.53 41.245 47.8375C42.445 47.145 43.9725 47.555 44.66 48.75C45.1075 49.52 45.9375 50 46.8275 50H52.4975C53.8775 50 54.9975 48.8775 54.9975 47.5C54.9975 46.555 54.32 45.755 53.3875 45.6L45.785 44.3325C42.43 43.775 39.9975 40.9 39.9975 37.5C39.9975 33.365 43.3625 30 47.4975 30V27.5C47.4975 26.12 48.615 25 49.9975 25C51.38 25 52.4975 26.12 52.4975 27.5V30H53.1675C55.835 30 58.325 31.4375 59.6625 33.75C60.355 34.945 59.945 36.4725 58.7525 37.165C57.55 37.855 56.025 37.445 55.3375 36.2525C54.89 35.48 54.06 35.0025 53.17 35.0025H47.5C46.12 35.0025 45 36.125 45 37.5025C45 38.4475 45.6775 39.2475 46.61 39.4025L54.2125 40.67C57.5675 41.2275 60 44.1 60 47.5ZM32.5 20H48.85C47.9825 17.7175 46.65 15.6175 44.8725 13.84L36.1625 5.125C34.3825 3.3475 32.2825 2.015 30 1.15V17.5C30 18.8775 31.12 20 32.5 20ZM42.1725 59.0925C40.7275 59.6775 39.1525 60 37.5 60H12.5C5.6075 60 0 54.3925 0 47.5V12.5C0 5.6075 5.6075 0 12.5 0H23.7875C24.195 0 24.5975 0.0325 25 0.06V17.5C25 21.635 28.365 25 32.5 25H42.93C42.8175 25.3175 42.725 25.6425 42.655 25.9775C39.58 27.275 37.1275 29.7725 35.885 32.8775C35.2175 32.635 34.4975 32.5 33.7475 32.5H16.25C12.805 32.5 10 35.305 10 38.75V43.75C10 47.195 12.805 50 16.25 50H33.75C34.1775 50 34.595 49.9575 34.9975 49.875C34.9725 51.225 35.315 52.56 36.0075 53.755C37.4225 56.205 39.6175 58.065 42.1725 59.095V59.0925ZM10 15C10 16.38 11.12 17.5 12.5 17.5H17.5C18.88 17.5 20 16.38 20 15C20 13.62 18.88 12.5 17.5 12.5H12.5C11.12 12.5 10 13.62 10 15ZM10 25C10 26.38 11.12 27.5 12.5 27.5H17.5C18.88 27.5 20 26.38 20 25C20 23.62 18.88 22.5 17.5 22.5H12.5C11.12 22.5 10 23.62 10 25ZM16.25 37.5C15.56 37.5 15 38.06 15 38.75V43.75C15 44.44 15.56 45 16.25 45H33.75C34.44 45 35 44.44 35 43.75V38.75C35 38.06 34.44 37.5 33.75 37.5H16.25Z" fill="black" />
        </svg>
    )
}

export default InvoiceIcon