import axios from 'axios'
import React, { createContext, useState } from 'react'
import { port } from '../App'

export const AppStore = createContext()

const AppContext = ({ children }) => {
    let homeBanner = '../Assets/homeBanner.png'
    let homeBanner2 = ''
    let [allblog, setAllBlog] = useState()
    let [loading, setLoading] = useState(false)
    let getBlog = () => {
        setLoading(true)
        axios.get(`${port}/blog/BlogViewSet/`).then((response) => {
            setAllBlog(response.data.reverse())
            console.log(response.data);
            setLoading(false)
        }).catch((error) => {
            console.log(error);
            setLoading(false)
        })
    }
    let services = [
        {
            slug: 'periodical_bas_preparations',
            title: 'Periodical BAS Preparations',
            icon: '../../GIF/file.gif',
            bannerContent: `Stay tax-compliant with accurate and timely BAS reporting, 
            managed expertly by professionals who understand your business.`,
            content: `Timely preparation of BAS to ensure your business stays compliant with tax regulations.`,
            path: 'periodical-bas-preparations',
            whatContent: {
                qns: `What is Periodical BAS Preparation?`,
                ans: `BAS preparation involves calculating GST, PAYG, and other tax obligations to meet ATO standards. Our team ensures your financial records are accurate and compliant, helping you avoid penalties. Whether you're a small business owner or a growing enterprise, our process makes tax reporting stress-free, leaving you more time to focus on running your business.
                  Say goodbye to stress and missed deadlines with seamless BAS management that saves you time and effort.`
            },
            whyContent: {
                qns: 'Why Choose Us for Your BAS Preparations?',
                content: `We deliver exceptional quality, 
                innovative solutions, and ensure your needs are met with the highest standards of quality and service.`,
                points: ['Precision-driven BAS calculations every time',
                    'Expertise backed by years of industry knowledge',
                    'Complete ATO compliance with Australian tax regulations',
                    'Fast turnaround with zero missed deadlines'
                ]
            },
            offerContent: {
                title: 'What’s Included in the Services We Offer',
                content: 'Our experts handle everything from tax filings to compliance, ensuring your business stays accurate, timely, and fully aligned with Australian regulations.',
                listObj: [
                    {
                        title: 'Thorough Record Review',
                        content: `We meticulously review your financial records to ensure accuracy and compliance with ATO standards.`
                    },
                    {
                        title: 'GST Calculation & Report Generation',
                        content: `Calculating your GST obligations, we generate detailed reports that are accurate and ready for submission.`
                    },
                    {
                        title: 'Managing PAYG Withholding',
                        content: `We handle PAYG withholding calculations and ensure timely submission of installments according to ATO guidelines.`
                    },
                    {
                        title: 'Precise Tax Filing',
                        content: `Your BAS is filed with the utmost accuracy, following all ATO requirements to avoid any discrepancies.`
                    },
                    {
                        title: 'Compliance Assurance',
                        content: `Every step is verified to ensure compliance with all necessary Australian tax regulations for BAS reporting.`
                    },
                    {
                        title: 'Timely Submissions',
                        content: `We make sure your BAS is submitted on time without delays, keeping your business on track and compliant.`
                    },
                ]
            },
            whoContent: {
                title: 'Who Can Benefit from Our Services',
                content: `Our BAS preparation service is here to make tax reporting hassle-free. We take care of the complexities so you don’t have to. From small business owners to large enterprises, our experts handle everything for you. Our focus is on delivering precise, accurate, and timely BAS preparation that allows you to stay compliant while saving time and energy.
                 Let us do the hard work while you focus on growing your business and making important decisions.`,
                listObj: [
                    {
                        title: 'Small Business Owners',
                        content: 'We handle BAS filings, letting you focus on growing your business.'
                    },
                    {
                        title: 'Startups & Entrepreneurs',
                        content: 'Save time with our expert BAS service and focus on expanding your brand.'
                    },
                    {
                        title: 'Large Enterprises',
                        content: 'Stay compliant with accurate BAS submissions without worrying about deadlines.'
                    },
                    {
                        title: 'Accountants & Bookkeepers',
                        content: 'Leave the BAS calculations to us and provide accurate results to your clients.'
                    },
                ]
            },
            rightContent: [
                {
                    title: 'Our Approach to BAS Preparation',
                    arrycontent: [
                        {
                            title: 'Initial Consultation to Assess Your Needs',
                            content: `We start by understanding your unique business needs. Our experts assess your financial records, tax obligations, and business structure to create a 
                            tailored BAS preparation plan that fits perfectly with your goals and ensures accurate tax reporting.`
                        },
                        {
                            title: 'Detailed Record Review for GST Calculation',
                            content: 'Our team meticulously reviews your financial records, focusing on GST calculations, PAYG, and other tax obligations. We ensure every detail aligns with ATO standards, guaranteeing accurate data and precise reports that are ready for submission without errors.'
                        },
                        {
                            title: 'Timely Submission with Compliance Checks',
                            content: 'We take charge of all BAS submissions, ensuring they’re completed on time and fully compliant with ATO regulations. Our service ensures that you never miss a deadline, giving you peace of mind and keeping your business on track with tax requirements.'
                        },
                        {
                            title: 'Ongoing Support and Reporting',
                            content: 'Our commitment doesn’t end with submission. We offer ongoing support to answer any questions and provide reports to help you stay informed. We make sure that your business remains compliant, and we’re always available to assist you with any future BAS needs.'
                        },
                    ]
                },
                {
                    title: 'Benefits of Our Periodical BAS Preparation Services',
                    arrycontent: [
                        {
                            title: 'Accurate Tax Reporting',
                            content: 'With Periodical BAS Preparation, your GST and PAYG are calculated correctly, every time. You get accurate reports that meet ATO standards, ensuring that your tax obligations are handled without mistakes, saving you from future headaches.'
                        },
                        {
                            title: 'Never Miss a Deadline',
                            content: 'Our service ensures your BAS is submitted on time, every time. By handling your BAS submissions consistently, we help you avoid late fees and penalties, letting you focus on running your business without worrying about deadlines.'
                        },
                        {
                            title: 'Complete Compliance with ATO Standards',
                            content: 'Rest assured that every step we take meets Australian tax regulations. From thorough record reviews to meticulous GST and PAYG calculations, we ensure full ATO compliance, making your BAS reporting as easy as possible.'
                        },
                        {
                            title: 'Simplified Tax Management',
                            content: 'Periodical BAS Preparation makes tax management effortless. We handle the complex tasks while providing you with clear and simple reports so you can stay informed and focus on growing your business without worrying about tax details.'
                        },
                    ]
                }
            ]
        },
        {
            title: 'Annual IT Returns of Individuals , Companies & Trusts',
            icon: '../../GIF/profit.gif',
            path: `annual-it-returns-of-individuals-companies-trusts`,
            content: 'Accurate filing of annual tax returns for individuals, companies, and trusts made simple with zero hassle.',

            bannerContent: 'Ensure your business stays on track with accurate tax filing for your business with our tax return services handled by professionals.',
            slug: 'annual-it-returns-of-individuals-companies-trusts',
            whatContent: {
                qns: `What is Annual Tax Returns?`,
                ans: `Annual Tax Returns are a detailed report of your income, expenses, and taxes owed to the government. We handle the whole process, ensuring that your records are accurate, compliant, and filed on time. Our goal is to save you time, reduce stress, and make sure your business meets all tax requirements without the worry of penalties. It’s all about keeping your business on 
                track with simple and precise tax filings.`
            },
            whyContent: {
                qns: 'Why Choose Us for Your Annual Tax Return?',
                content: `Tax filing can be a daunting task, but with our annual tax returns service, 
                you can rest easy knowing your business is in expert hands. `,
                points: ['On-time tax return filing guaranteed',
                    'Ensuring you receive the maximum refund',
                    'Complete ATO compliance with Australian tax regulations',
                    'Transparent pricing with no hidden fees'
                ]
            },
            offerContent: {
                title: 'What’s Included in the Services We Offer',
                content: 'Get expert support in preparing and filing your annual tax returns, ensuring precision and full compliance with Australian tax laws!',
                listObj: [
                    {
                        title: 'Income & Expense Analysis',
                        content: `We analyze all income and expenses, ensuring they’re accurately
                         categorized for a smooth tax return process.`
                    },
                    {
                        title: 'Accurate Tax Calculation',
                        content: `Tax calculations are completed with precision, factoring in income and 
                        deductions to minimize errors and ensure ATO compliance.`
                    },
                    {
                        title: 'Strategic Tax Planning',
                        content: `Plans for future tax seasons are developed to optimize your financial approach and minimize 
                        potential tax liabilities year after year.`
                    },
                    {
                        title: 'Maximizing Tax Deductions',
                        content: `Eligible tax deductions are identified to reduce liability, ensuring you get the maximum 
                        benefit from your return.`
                    },
                    {
                        title: 'Quick Tax Refund Processing',
                        content: `We ensure rapid processing of your tax refund, getting your money back promptly without 
                        unnecessary delays or complications.`
                    },
                    {
                        title: 'Reliable Income Reporting',
                        content: `Income is verified and reported accurately, capturing all sources to prevent any 
                        reporting discrepancies.`
                    },
                ]
            },
            whoContent: {
                title: 'Who Can Benefit from Our Services',
                content: `Let our expert team handle your annual tax returns, lifting the load off your shoulders. From calculating tax obligations to filing returns, we manage every step for you. With our team, you can focus on your business, knowing everything is in good hands. Whether you're a small business, a startup, or a large enterprise, we ensure compliance, accuracy, 
                and timely filing, saving you time and effort. `,
                listObj: [
                    {
                        title: 'Small Business Owners',
                        content: 'Let us handle your tax returns while you focus on growing your small business.'
                    },
                    {
                        title: 'Startups & Entrepreneurs',
                        content: 'Maximize efficiency with our expert tax return services and avoid costly filing errors.'
                    },
                    {
                        title: 'Large Enterprises',
                        content: 'Ensure timely and accurate tax return submissions with our expert team handling all details.'
                    },
                    {
                        title: 'Accountants & Bookkeepers',
                        content: `Count on us for seamless tax return filings for your clients, ensuring compliance every time.`
                    },
                ]
            },
            rightContent: [
                {
                    title: 'Our Approach to Annual Tax Returns ',
                    arrycontent: [
                        {
                            title: 'Understanding Your Tax Situation',
                            content: `We begin by gathering all the necessary details about your financial records and obligations. Our team thoroughly reviews your income, expenses, and deductions to craft a clear tax return plan that ensures you meet all requirements and take advantage of every opportunity to 
                            minimize your tax burden.`
                        },
                        {
                            title: 'Careful Review of Deductions',
                            content: `Our experts review your eligible tax deductions, focusing on every detail to maximize your savings. By examining all areas like business expenses, medical costs, and property-related deductions, we make sure that every opportunity for reducing your tax 
                            liability is fully explored while staying within legal boundaries.`
                        },
                        {
                            title: 'Precise Tax Filing and Submission',
                            content: `Once all information is reviewed, we handle the submission process to ensure your returns are filed correctly and on time. Our team makes sure that all tax calculations are accurate, reducing the risk of penalties or additional fees. You can rest easy knowing your 
                            tax returns are managed by professionals.`
                        },
                        {
                            title: 'Post-Filing Assistance and Support',
                            content: `Our services don’t end with filing your tax return. We offer ongoing support, including providing updates on your tax status and advising on any future tax-related matters. If you receive correspondence from the tax office or have questions, we are here to help you with everything
                             you need throughout the year.`
                        },
                    ]
                },
                {
                    title: 'Benefits of Our Annual Tax Return Services',
                    arrycontent: [
                        {
                            title: 'Accurate Tax Filing with Zero Errors',
                            content: `Our team guarantees your tax return is thorough, precise, and error-free. We handle all the details, from income to deductions, ensuring your return is filed without mistakes, saving you from 
                            costly penalties or audits.`
                        },
                        {
                            title: 'Timely Filing with No Deadlines Missed',
                            content: `Never worry about missing a deadline again. We guarantee that your tax return will be filed on time, avoiding late fees and penalties. Our proactive approach ensures that everything is handled
                             well ahead of time.`
                        },
                        {
                            title: 'Maximized Deductions and Savings',
                            content: `We explore every potential deduction, ensuring that you keep as much of your hard-earned money as possible. Our knowledge of tax laws allows us to identify all opportunities to reduce your tax liability, maximizing your refund or
                             minimizing the amount you owe.`
                        },
                        {
                            title: 'Ongoing Tax Guidance and Assistance',
                            content: `We offer continuous support even after your tax return is filed. Whether you need help with tax planning for the next year or guidance on tax changes, our team is always available to provide valuable 
                            advice and keep you on track with your finances.`
                        },
                    ]
                }
            ]
        },
        {
            title: 'Invoice Management',
            icon: '../GIF/bill2.gif',
            content: `Easy creation and management of invoices to ensure smooth transactions and timely payments.`,
            path: 'invoice-management',

            bannerContent: `Streamline your invoicing process with precise and timely invoice management services designed for seamless operations.`,
            slug: 'invoice-management',
            whatContent: {
                qns: `What is Invoice Management?`,
                ans: `Invoice management involves efficiently handling, monitoring, and processing business invoices to ensure smooth financial operations. It ensures accurate billing, timely payments, and clear records for smooth financial operations. Effective management helps eliminate errors, avoid delays, and build stronger relationships with vendors. Say goodbye to late payments and disorganized records with reliable invoice management service designed to save you time and keep your finances in check. Our team tracks, verifies, 
                and manages invoices so your business operations run smoothly.`
            },
            whyContent: {
                qns: 'Why Choose Us for Your Invoice Management?',
                content: `We provide seamless, efficient, and accurate invoice management services, ensuring all your invoicing processes are 
                handled with precision and professionalism.`,
                points: ['Handling of bulk invoices by professionals',
                    'Real-time tracking for transparency',
                    'Guaranteed accuracy with zero errors',
                    'Customizable invoicing solutions for your business'
                ]
            },
            offerContent: {
                title: 'What’s Included in the Services We Offer',
                content: 'We help keep track of your invoices, ensuring payments are processed on time, and records are maintained accurately.',
                listObj: [
                    {
                        title: 'Invoice Tracking',
                        content: `Monitor all invoices to ensure timely payments and accurate cash flow, preventing overdue payments or missed opportunities.`
                    },
                    {
                        title: 'Payment Reminders',
                        content: `Send gentle reminders to customers about outstanding invoices, ensuring timely payments without overwhelming your clients.`
                    },
                    {
                        title: 'Accurate Recordkeeping',
                        content: `Organize and maintain records for all invoices, providing an easy reference for future audits or financial reviews.`
                    },
                    {
                        title: 'Discrepancy Resolution',
                        content: `Identify and resolve discrepancies between invoicing and payments quickly, maintaining financial accuracy without delays.`
                    },
                    {
                        title: 'Vendor Coordination',
                        content: `Manage relationships with vendors to streamline payments, ensuring timely disbursements and clear communication regarding outstanding balances.`
                    },
                    {
                        title: 'Detailed Reporting',
                        content: `Provide clear and concise reports of all invoicing activities, ensuring transparency and easy tracking for financial assessments.`
                    },
                ]
            },
            whoContent: {
                title: 'Who Can Benefit from Our Services',
                content: `Managing invoices can be a challenge, especially when you have many customers and clients to handle. Our Invoice Management service helps businesses of all sizes streamline this process. Whether you're a small startup or a large company, we manage invoices efficiently, eliminating the risk of errors or delayed payments. Our experts ensure that every invoice is clear, accurate, and sent promptly, so you can focus on growing your business while we handle the financial 
                details.`,
                listObj: [
                    {
                        title: 'Small and Medium Enterprises (SMEs)',
                        content: 'Focus on growing your business while keeping your invoicing timely and error-free.'
                    },
                    {
                        title: 'Freelancers & Independent Contractors',
                        content: 'Ensure accurate invoicing and prompt payments without the stress of manual tracking.'
                    },
                    {
                        title: 'Large Corporations',
                        content: 'Timely and precise invoicing keeps operations running smoothly and ensures a steady cash flow.'
                    },
                    {
                        title: 'E-commerce Businesses',
                        content: `Simplify online sales invoicing with accurate, quick invoice creation for faster transactions and customer trust.`
                    },
                ]
            },
            rightContent: [
                {
                    title: `Our Approach to Invoice Management`,
                    arrycontent: [
                        {
                            title: 'Invoice Creation and Tracking',
                            content: `Our team develops a smooth workflow for generating, tracking, and organizing invoices. We ensure every detail, from client information to payment terms, is correct. Our system helps you track outstanding invoices, so you never miss a payment. 
                            This reduces administrative hassle and improves financial management.`
                        },
                        {
                            title: 'Organized Invoice Collection',
                            content: `We ensure every invoice, from receipts to vendor bills, is collected and categorized. Our system eliminates errors and maintains a clear record of all transactions, streamlining your invoicing process while saving 
                            valuable time for your business operations.`
                        },
                        {
                            title: 'Automated Reminders and Follow-ups',
                            content: `We automate the process of sending payment reminders and follow-ups. If an invoice goes unpaid, our system sends gentle, professional reminders, helping clients settle payments quickly. This proactive approach ensures you maintain healthy cash flow while minimizing 
                            the effort involved in chasing overdue payments`
                        },
                        {
                            title: 'Seamless Integration for Efficient Workflow',
                            content: `We integrate your invoicing system with your existing software tools. Whether it’s accounting, CRM, or project management, our approach ensures smooth synchronization. This creates a unified system where your invoicing processes are automated, 
                            saving you time and reducing the chances of error or missed payments.`
                        },
                    ]
                },
                {
                    title: 'Benefits of Our Invoice Management Services',
                    arrycontent: [
                        {
                            title: 'Faster Payment Cycles for Better Cash Flow',
                            content: `Say goodbye to payment delays. Our proactive approach ensures timely follow-ups, efficient tracking, and quick resolutions to any invoicing issues. By streamlining payment collection, we improve your cash flow and ensure your business 
                            operates without interruptions or financial stress.`
                        },
                        {
                            title: 'Reduced Errors and Improved Accuracy',
                            content: `With our meticulous invoicing process, you can eliminate costly errors. Correctly generated invoices ensure that clients pay the right amounts on time, reducing confusion and minimizing disputes. 
                            Our attention to detail increases financial accuracy across the board`
                        },
                        {
                            title: 'Ongoing Tax Consultation',
                            content: `Concentrate on growing your business while we take care of your invoicing. Our services save you hours of manual work and reduce administrative burden, 
                            allowing you to dedicate more time to growing your business.`
                        },
                        {
                            title: 'Real-Time Monitoring and Reporting',
                            content: `Stay updated with insightful invoice tracking and detailed reports. Our system provides a clear overview of payments due, outstanding amounts, and cash flow trends, helping you stay 
                            informed and make better financial decisions.`
                        },
                    ]
                }
            ]

        },
        {
            title: 'Accounting & Bookkeeping',
            icon: '../GIF/accounting.gif',
            content: `Keep track of your finances with organized bookkeeping and accurate records for better decision-making.`,
            path: 'accounting-bookkeeping',

            bannerContent: `Keep your finances organized and accurate with expert accounting and bookkeeping services tailored for your business.`,
            slug: 'accounting-bookkeeping',
            whatContent: {
                qns: `What is Accounting & Bookkeeping?`,
                ans: `Accounting and bookkeeping involve recording, organizing, and maintaining a company’s financial transactions. These tasks ensure accurate financial records, help track business performance, and support compliance with regulations. With our professional accounting and bookkeeping services to keep your books balanced and up to date. From expense tracking to financial reports, we handle it all, giving you clear insights to make better 
                business decisions while staying financially organized.`
            },
            whyContent: {
                qns: 'Why Choose Us for Your Accounting & Bookkeeping?',
                content: `Our experts deliver meticulous accounting and bookkeeping services, keeping your finances organized, 
                updated, and always compliant with industry standards. `,
                points: ['Organized financial records for better management',
                    'Compliance with tax regulations assured',
                    'Expert support personalised to your unique needs',
                    'Hassle-free setup and maintenance'
                ]
            },
            offerContent: {
                title: 'What’s Included in the Services We Offer',
                content: 'Our accounting & bookkeeping services help you manage financial records and ensure everything is in order for taxes and business decisions.',
                listObj: [
                    {
                        title: 'Daily Record Updates',
                        content: `Update financial records regularly, ensuring each transaction is documented for seamless reporting and future reference.`
                    },
                    {
                        title: 'Expense Categorization',
                        content: `Sort expenses correctly for accurate reporting, helping identify spending patterns and areas where costs can be reduced.`
                    },
                    {
                        title: 'Financial Statement Preparation',
                        content: `Prepare detailed financial statements for internal review or external stakeholders, providing insights into your financial health.`
                    },
                    {
                        title: 'Tax Compliance',
                        content: `Ensure all accounting records align with local tax regulations, preventing issues with audits or legal non-compliance.`
                    },
                    {
                        title: 'Balance Verification',
                        content: `Regularly verify account balances to ensure everything matches, avoiding errors and discrepancies in your financial statements.`
                    },
                    {
                        title: 'Monthly Reporting',
                        content: `Generate accurate monthly financial reports that give you a clear view of your business's financial performance.`
                    },
                ]
            },
            whoContent: {
                title: 'Who Can Benefit from Our Services',
                content: `Say goodbye to confusing spreadsheets and endless paperwork! Our Accounting & Bookkeeping service helps you keep your finances on track with ease. We take care of all the little details, from recording transactions to preparing reports, so you never have to worry. Whether you're just starting or managing a large enterprise, we help you stay organized and ensure your financial health is on track. You can count on us to keep your records flawless and stress-free. Let us help you stay organized and keep your business running smoothly!`,
                listObj: [
                    {
                        title: 'Small & Growing Businesses',
                        content: 'Stay organized and manage your finances without the extra effort, allowing focus on growth.'
                    },
                    {
                        title: 'Freelancers & Consultants',
                        content: 'Track your income and expenses easily, ensuring everything is prepared for tax season.'
                    },
                    {
                        title: 'Retail & Service Providers',
                        content: 'Track your daily sales and expenses, keeping your books balanced and ready for audits.'
                    },
                    {
                        title: 'Non-profit Organizations',
                        content: `Easily manage donations and track your spending to maintain transparency and accountability.`
                    },
                ]
            },
            rightContent: [
                {
                    title: 'Our Approach to Accounting & Bookkeeping',
                    arrycontent: [
                        {
                            title: 'Thorough Assessment of Financial Needs',
                            content: `We begin by evaluating your current financial situation, including revenue streams and expenses. This analysis helps us understand your specific accounting and bookkeeping requirements. Our team ensures that every aspect of your financial structure is considered to provide 
                            clear insights and efficient management of your accounts.`
                        },
                        {
                            title: 'Organized and Accurate Bookkeeping Practices',
                            content: `Our team handles every aspect of your bookkeeping, tracking every expense and revenue. We record all transactions, monitor cash flow, and ensure accuracy in financial reporting. Our methodical approach to bookkeeping helps you maintain organized financial records that can be easily reviewed at any time, 
                            facilitating decision-making and financial management.`
                        },
                        {
                            title: 'Effective Tax Filing & Compliance',
                            content: `We handle every detail of tax preparation and filing with precision and care. By keeping track of your income and expenses, we ensure that you comply with tax regulations. Our team works to ensure that your business is up-to-date with all tax obligations, 
                            minimizing any risk of penalties and errors during tax season.`
                        },
                        {
                            title: 'Continual Financial Monitoring & Reporting',
                            content: `We provide continuous financial oversight with monthly or quarterly reports. These reports help you keep track of your business’s financial health. Our team will answer any questions and offer guidance to help you make informed financial decisions that 
                            promote growth and stability in your business operations.`
                        },
                    ]
                },
                {
                    title: 'Benefits of Our Accounting & Bookkeeping Services',
                    arrycontent: [
                        {
                            title: 'Precise Financial Records for Smooth Operations',
                            content: `Our accurate and organized accounting system ensures that every transaction is recorded correctly, helping you maintain clear financial records. This precision allows you to make confident decisions, track your cash flow, and manage budgets effectively, 
                            keeping your business on track without any accounting concerns.`
                        },
                        {
                            title: 'Timely & Accurate Tax Filing Support',
                            content: `With our bookkeeping services, tax filing is streamlined and accurate. We ensure all your financial documents are in order and submit tax returns on time, minimizing the risk of penalties. By staying on top of deadlines, we ensure your 
                            business remains fully compliant with all tax regulations.`
                        },
                        {
                            title: 'Actionable Insights for Better Financial Decisions',
                            content: `We provide you with detailed, accurate financial reports that offer valuable insights. These reports help you identify growth opportunities, track expenses, and analyze your profits, allowing you to make strategic decisions that drive your business forward.
                             Access the right data, exactly when it's needed.`
                        },
                        {
                            title: 'Cost Savings & More Time for Business Growth',
                            content: `Let us handle your accounting and bookkeeping, so you can spend less time on paperwork and more time growing your business. Our services eliminate the need for in-house accounting staff, reducing operational 
                            costs while ensuring your finances are always in order.`
                        },
                    ]
                }
            ]
        },
        {
            title: 'Receivable & Payable Accounts Management',
            icon: '../GIF/money-order.gif',
            content: `Ensure timely payments and collections, maintaining healthy cash flow for your business success.`,
            path: 'receivable-payable-accounts-management',

            bannerContent: 'Ensure smooth cash flow management with expert handling of your receivables and payables, all under one roof.',
            slug: 'receivable-payable-accounts-management',
            whatContent: {
                qns: `What is Receivable & Payable Accounts Management?`,
                ans: `Receivable and payable accounts management tracks money owed to and by your business. It ensures timely customer payments and accurate vendor settlements to maintain cash flow and financial stability. Experts at Accounts Point, manage your receivables and payables efficiently, reducing delays and preventing errors. We help your business maintain healthy cash flow and seamless transactions, so you can avoid payment hassles and 
                focus on achieving your goals.`
            },
            whyContent: {
                qns: 'Why Choose Us for Receivable & Payable Accounts Management?',
                content: `We streamline receivables and payables management to enhance cash flow and maintain smooth 
                operations for your business.`,
                points: ['Timely tracking of payments and collections',
                    'Improved cash flow management',
                    'Error-free reconciliation of accounts',
                    'Secure handling of sensitive data'
                ]
            },
            offerContent: {
                title: 'What’s Included in the Services We Offer',
                content: 'Efficient management of both receivables and payables to maintain smooth cash flow and positive vendor relationships.',
                listObj: [
                    {
                        title: 'Payment Monitoring',
                        content: `Track incoming and outgoing payments regularly, ensuring you stay on top of cash flow without missing important deadlines.`
                    },
                    {
                        title: 'Account Reconciliation',
                        content: `Match accounts payable and receivable records with actual bank statements to ensure complete and accurate financial data.`
                    },
                    {
                        title: 'Debt Collection',
                        content: `Follow up with customers for overdue payments, ensuring debts are collected promptly while maintaining professional relationships.`
                    },
                    {
                        title: 'Expense Management',
                        content: `Manage outstanding payables, ensuring that all bills are paid on time and avoiding late fees or penalties.`
                    },
                    {
                        title: 'Vendor Payment Processing',
                        content: `Streamline vendor payment processing by scheduling timely transactions, ensuring strong relationships with vendors and avoiding disruptions.`
                    },
                    {
                        title: 'Financial Forecasting',
                        content: `Use receivables and payables data to create accurate cash flow forecasts, helping you plan for business growth.`
                    },
                ]
            },
            whoContent: {
                title: 'Who Can Benefit from Our Services',
                content: `Keeping track of receivables and payables is essential to ensure a steady cash flow. Managing payments and bills can be overwhelming, but we make it easy! Our Receivable & Payable Accounts Management service ensures your payments come in on time and your bills get paid without delay. We help businesses of all sizes stay on top of transactions, so you don’t have to worry about late fees or missing payments. Whether you’re a small startup or a large company, we handle the hard work for you, so your business runs without
                 interruption. `,
                listObj: [
                    {
                        title: 'Small and Medium Businesses',
                        content: 'Manage your cash flow with ease, ensuring all incoming and outgoing payments are on time.'
                    },
                    {
                        title: 'Freelancers and Independent Contractors',
                        content: `Easily track what you owe and what you're owed, improving cash flow and financial planning.`
                    },
                    {
                        title: 'Corporate Enterprises',
                        content: 'Handle large volumes of payables and receivables, maintaining smooth financial operations across departments.'
                    },
                    {
                        title: 'Online Retailers & E-commerce',
                        content: `Track customer payments and vendor invoices effectively, keeping everything in check for accurate records.`
                    },
                ]
            },
            rightContent: [
                {
                    title: 'Our Approach to Receivable & Payable Accounts Management',
                    arrycontent: [
                        {
                            title: 'Precise Assessment of Accounts',
                            content: `We start by reviewing your receivable and payable accounts in detail. Our experts ensure accuracy by identifying discrepancies and optimizing processes to improve cash flow management. Every transaction is analyzed for alignment with your business needs, 
                            setting a solid foundation for smooth operations.`
                        },
                        {
                            title: 'Efficient Process Implementation',
                            content: `Next, we implement tailored processes for handling payables and receivables. We create a structured approach to managing invoices, payments, and outstanding dues, ensuring smooth cash flow and minimizing delays. Our methods are efficient, 
                            providing transparency and consistency in all financial dealings.`
                        },
                        {
                            title: 'On-Time Payment Processing',
                            content: `All payables are processed promptly, ensuring payments are made by their due dates. This approach helps avoid late fees and penalties while maintaining strong relationships with vendors. The streamlined process ensures all financial obligations are met, preventing disruptions in 
                            business operations and fostering a reliable payment history.`
                        },
                        {
                            title: 'Ongoing Expert Financial Support',
                            content: `Expert support is available to manage your receivables and payables. Our team offers guidance on best practices, resolves any issues, and ensures your business remains compliant. This continuous support helps maintain financial stability, allowing
                             your business to focus on growth without financial concerns.`
                        },
                    ]
                },
                {
                    title: 'Benefits of Our Receivable & Payable Accounts Management Services',
                    arrycontent: [
                        {
                            title: 'Smooth Cash Flow Management',
                            content: `Increased efficiency in invoice tracking and timely payments directly improves cash flow. This proactive approach ensures better financial control, providing the liquidity needed for business operations and long-term sustainability 
                            without cash flow disruptions or delays.`
                        },
                        {
                            title: 'Reduced Risk of Financial Errors',
                            content: `Payables are carefully monitored to ensure payments are made on time, significantly reducing the risk of late fees and penalties. This approach ensures that your financial obligations are met, reducing unnecessary costs and helping 
                            your business maintain a clean financial record.`
                        },
                        {
                            title: 'Better Financial Planning & Insights',
                            content: `Regular financial reports and cash flow forecasts provide essential insights into the business’s financial position. These insights allow for smarter business planning, making it easier to allocate resources effectively and 
                            optimize financial strategies for growth and profitability.`
                        },
                        {
                            title: 'Stronger Client and Supplier Relations',
                            content: `With a reliable system in place for managing payable accounts, you strengthen vendor relationships. Timely payments and clear communication lead to better terms, discounts, and a solid reputation. This ongoing trust between your business and suppliers results in 
                            smoother operations and potential cost savings for your company.`
                        },
                    ]
                }
            ]
        },
        {
            title: 'Payroll Processing',
            icon: '../GIF/progress.gif',
            content: `Accurate and timely salary calculations, ensuring your team gets paid without any errors or delays.`,
            path: 'payroll-processing',

            bannerContent: 'Accurately process employee payments on time with our reliable and efficient payroll management services.',
            slug: 'payroll-processing',
            whatContent: {
                qns: `What is Payroll Processing?`,
                ans: `Payroll processing involves calculating employee wages, deducting taxes, and managing benefits. This ensures your employees are paid accurately and on time, while staying compliant with regulations. Our payroll services handle every detail, from salary calculations to tax filings, so you don’t have to worry about errors or missed deadlines. Let us simplify your payroll management 
                while you focus on building a productive and happy workforce.`
            },
            whyContent: {
                qns: 'Why Choose Us for Payroll Processing?',
                content: `Our payroll services ensure timely, accurate, and hassle-free salary disbursements, keeping 
                your workforce happy and compliant with all regulations.`,
                points: ['On-time payroll every month',
                    'Complete compliance with labor laws',
                    'Secure and confidential payroll data',
                    'Customized solutions for unique needs'
                ]
            },
            offerContent: {
                title: 'What’s Included in the Services We Offer',
                content: 'Accurate payroll management that ensures employees are paid on time, and taxes are calculated correctly.',
                listObj: [
                    {
                        title: 'Salary Calculations',
                        content: `Calculate wages, deductions, and tax amounts for employees, ensuring they are paid accurately and on time.`
                    },
                    {
                        title: 'Tax Deduction Compliance',
                        content: `Ensure all tax deductions are calculated and submitted according to local regulations, avoiding fines or penalties.`
                    },
                    {
                        title: 'Overtime and Benefits',
                        content: `Accurately calculate overtime hours, bonuses, and employee benefits to ensure fairness and transparency in compensation.`
                    },
                    {
                        title: 'Payslip Generation',
                        content: `Generate clear payslips for employees, outlining earnings, deductions, and net pay for transparency and recordkeeping.`
                    },
                    {
                        title: 'Leave Management',
                        content: `Track and apply employee leave, ensuring accurate payroll adjustments for sick days, vacation, and personal time.`
                    },
                    {
                        title: 'Payroll Reports',
                        content: `Prepare detailed payroll reports to provide a clear overview of all salary disbursements and adjustments for management.`
                    },
                ]
            },
            whoContent: {
                title: 'Who Can Benefit from Our Services',
                content: `Payroll processing can be time-consuming. Leave the payroll to us! With our Payroll Processing service, we guarantee your employees receive their payments promptly and correctly, every single time. We handle everything from calculating taxes to managing deductions, so you don’t have to. By outsourcing payroll to experts, businesses can focus on what they do best, knowing that their team will always get paid accurately and on schedule. Stay compliant with tax laws and focus on scaling  your business
                 while we ensure your staff gets paid correctly and on schedule.`,
                listObj: [
                    {
                        title: 'Small and Medium Businesses',
                        content: 'Save time and ensure employees are paid on time without dealing with complex payroll calculations.'
                    },
                    {
                        title: 'Startups and Growing Teams',
                        content: 'Focus on building your business while we handle payroll processing efficiently and accurately.'
                    },
                    {
                        title: 'Large Corporations',
                        content: 'Manage payroll across multiple departments with ease, ensuring compliance and smooth operations.'
                    },
                    {
                        title: 'E-commerce Companies',
                        content: `Payroll management for remote or part-time employees is simplified, keeping your team happy and motivated.`
                    },
                ]
            },
            rightContent: [
                {
                    title: 'Our Approach to Payroll Processing',
                    arrycontent: [
                        {
                            title: 'Seamless Payroll Setup',
                            content: `We begin by understanding your business structure and specific payroll requirements. Our team ensures all employee details, payment schedules, and deductions are organized efficiently, setting a 
                            solid foundation for accurate and timely payroll processing.`
                        },
                        {
                            title: 'Precise Salary Calculation',
                            content: `Our experts accurately calculate employee wages, taking into account hours worked, overtime, bonuses, and applicable deductions. We ensure that every figure is correct and complies with current tax laws,
                             offering complete clarity in payroll distribution.`
                        },
                        {
                            title: 'On-Time Payroll Distribution',
                            content: `We prioritize timely payroll disbursement, ensuring employees are paid on schedule every month. Our team manages all payment processes, including direct deposits and pay slips, without fail, 
                            keeping your payroll operations smooth and uninterrupted.`
                        },
                        {
                            title: 'Detailed Payroll Reporting',
                            content: `Reports are generated to offer full transparency into each payroll cycle. These reports cover tax deductions, salary distributions, and benefits, allowing you to keep track of all financial movements. This service provides clarity for both management and employees, enabling 
                            efficient tracking and management of payroll data.`
                        },
                    ]
                },
                {
                    title: 'Benefits of Our Payroll Processing Services',
                    arrycontent: [
                        {
                            title: 'Scalable to Your Business Needs',
                            content: `Our payroll processing solution adapts to your business as it grows. Whether you're expanding your workforce or changing your structure, we scale to match your needs, ensuring seamless payroll management, even as your company 
                            evolves and your payroll requirements increase.`
                        },
                        {
                            title: 'Improves Employee Satisfaction',
                            content: `Timely payroll management plays a crucial role in enhancing employee satisfaction. Our efficient service ensures your employees are paid on time with correct deductions, boosting their morale and trust in the company. This positive impact leads 
                            to higher retention and a more motivated workforce.`
                        },
                        {
                            title: 'Reduced Administrative Burden',
                            content: `Our payroll service minimizes administrative tasks, saving you time and resources. With streamlined processes and accurate reporting, we reduce the burden on your internal team, allowing them to focus on business growth and strategic 
                            initiatives instead of payroll management.`
                        },
                        {
                            title: 'Cost-Effective Payroll Management',
                            content: `Get cost-effective solutions that help you avoid the overheads of managing an in-house payroll system. By outsourcing, you save on labor costs, software, and training, while ensuring that your payroll is accurate, timely, and handled 
                            by professionals with expertise in payroll management.`
                        },
                    ]
                }
            ]
        },
        {
            title: 'Superannuation',
            icon: '../GIF/recurring.gif',
            content: `Manage employee retirement funds seamlessly with compliance to all legal and financial requirements.`,
            path: 'superannuation',

            bannerContent: 'Secure your employees’ future with accurate and hassle-free superannuation services handled by trusted professionals.',
            slug: 'superannuation',
            whatContent: {
                qns: `What is Superannuation?`,
                ans: `Superannuation is the process of managing employee retirement contributions as required by law. It ensures funds are calculated correctly and deposited on time, securing employees’ futures. We ensure superannuation contributions are handled accurately and comply with legal requirements. From calculations to submissions, we make the process hassle-free, helping your business meet 
                obligations while supporting your employees’ long-term security.`
            },
            whyContent: {
                qns: 'Why Choose Us for Superannuation?',
                content: `We manage your superannuation contributions with utmost precision and compliance, ensuring 
                a stress-free process for employers and employees. `,
                points: ['Accurate contributions without delays',
                    'Compliance with legal superannuation standards',
                    'Hassle-free setup for employers and employees',
                    'Reliable support for all your queries'
                ]
            },
            offerContent: {
                title: 'What’s Included in the Services We Offer',
                content: 'Ensure your superannuation contributions are calculated correctly and submitted on time for your employees’ financial security.',
                listObj: [
                    {
                        title: 'Contribution Calculation',
                        content: `Calculate superannuation contributions based on employee wages, 
                        ensuring accurate and timely payments to their super funds.`
                    },
                    {
                        title: 'Compliance with Laws',
                        content: `Ensure all contributions meet the legal requirements, keeping your business in compliance with 
                        Australian tax and superannuation laws.`
                    },
                    {
                        title: 'Employee Record Updates',
                        content: `Maintain updated records of employee superannuation contributions, ensuring accurate and efficient reporting when needed.`
                    },
                    {
                        title: 'Fund Communication',
                        content: `Coordinate directly with super funds to ensure correct processing of contributions, 
                        handling any discrepancies promptly.`
                    },
                    {
                        title: 'Timely Payments',
                        content: `Ensure all superannuation payments are made within deadlines, avoiding fines and ensuring employees’ 
                        funds are properly contributed.`
                    },
                    {
                        title: 'Annual Reporting',
                        content: `Prepare clear annual reports outlining contributions made on behalf of employees, 
                        ensuring transparency and compliance with tax regulations.`
                    },
                ]
            },
            // Pending
            whoContent: {
                title: 'Who Can Benefit from Our Services',
                content: `Superannuation is crucial for employee benefits, our Superannuation service ensures that all your employee contributions are calculated correctly and paid on time. Our service takes the stress out of managing super for your team, ensuring compliance with current regulations. We handle everything from calculation to payment, helping you focus on running your business while providing employees with the benefits they deserve. No more worrying about missed payments 
                or compliance issues! Let us take care of your superannuation needs.`,
                listObj: [
                    {
                        title: 'Small Businesses & Startups',
                        content: 'Stay compliant with superannuation contributions without adding extra tasks to your plate.'
                    },
                    {
                        title: 'Freelancers and Contractors',
                        content: 'Effortlessly manage your own superannuation contributions while staying compliant with regulations.'
                    },
                    {
                        title: 'Medium to Large Businesses',
                        content: 'Easily manage superannuation for your employees, ensuring all contributions are processed on time.'
                    },
                    {
                        title: 'E-commerce Employers',
                        content: `Ensure that superannuation is paid on time for your remote or local workers, maintaining employee satisfaction.`
                    },
                ]
            },
            rightContent: [
                {
                    title: 'Our Approach to Superannuation',
                    arrycontent: [
                        {
                            title: 'Initial Superannuation Assessment',
                            content: `A thorough evaluation of your current financial situation helps identify your retirement goals and superannuation needs. Experts review existing funds, contribution history, and investment strategies to ensure that every decision aligns with long-term growth and secure retirement, 
                            optimizing the potential for wealth accumulation over time.`
                        },
                        {
                            title: 'Expert Review of Your Super Fund',
                            content: `Our professionals carefully examine your superannuation fund's performance and structure. We focus on strategies that enhance your retirement savings, such as salary sacrificing and government co-contributions. We make sure that your contributions align with your retirement goals and provide long-term growth 
                            opportunities for your superannuation fund.`
                        },
                        {
                            title: 'Strategic Investment Advice for Superannuation Growth',
                            content: `We offer personalized investment strategies to help grow your superannuation. Our experts assess market trends and provide guidance on the best investment options that align with your risk profile. With a forward-thinking approach, we help you optimize returns and build a secure financial future for your 
                            retirement.`
                        },
                        {
                            title: 'Ongoing Superannuation Monitoring and Adjustment',
                            content: `Superannuation is not a one-time task. Regular reviews of your super fund’s performance allow for timely adjustments. By monitoring the investment choices, contribution levels, and market conditions, we ensure that your super plan adapts to your evolving 
                            needs and remains on track to meet your retirement objectives.`
                        },
                    ]
                },
                {
                    title: 'Benefits of Our Superannuation Services',
                    arrycontent: [
                        {
                            title: 'Secure Retirement Planning',
                            content: `Building a secure future starts with strategic planning and efficient superannuation management. Our services are designed to help you grow your super balance while ensuring your retirement goals are achieved. 
                            Every step ensures your future is financially stable, allowing you to retire with peace of mind.`
                        },
                        {
                            title: 'Maximized Contributions for Growth',
                            content: `Optimizing contributions involves careful consideration of tax laws, government schemes, and personal finances. By strategically planning contributions, your super fund grows faster, benefitting from tax advantages and maximizing 
                            long-term savings, ensuring your retirement fund reaches its fullest potential.`
                        },
                        {
                            title: 'Custom Investment Options for Success',
                            content: `Tailored investment strategies provide a way to grow your super while minimizing risk. Carefully selecting high-performing, stable assets ensures a consistent increase in your retirement funds. This focused approach guarantees a portfolio 
                            designed to match your risk profile and growth aspirations.`
                        },
                        {
                            title: 'Complete Regulatory Compliance',
                            content: `Adhering to superannuation rules and regulations is crucial. Monitoring changes in ATO guidelines ensures your contributions and investments are always compliant, eliminating risks and preventing costly penalties. With our expertise, you can trust that your 
                            super is being managed according to the highest standards of compliance.`
                        },
                    ]
                }
            ]
        },
        {
            title: 'Month & Year End Reconciliations & Reporting',
            icon: '../GIF/labour-day.gif',
            content: `Complete and error-free financial reconciliations with clear reports for better business insights.`,
            path: 'month-year-end-reconciliations-reporting',

            bannerContent: 'Close your books with confidence using our month-end and year-end reconciliation services for error-free records.',
            slug: 'month-year-end-reconciliations-reporting',
            whatContent: {
                qns: `What is Month-End & Year-End Reconciliation?`,
                ans: `Month-end and year-end reconciliation compare financial records with actual transactions to ensure accuracy. It identifies discrepancies, prepares accounts for reporting, and ensures your financials are ready for audits. Our experts perform detailed reconciliations to balance your books and resolve any inconsistencies. With our help, your financial records stay accurate and organized, ensuring smooth 
                reporting and compliance at the end of each period.`
            },
            whyContent: {
                qns: 'Why Choose Us for Month-End & Year-End Reconciliation?',
                content: `We ensure clean and accurate financial reconciliation, preparing your business for better 
                audits and financial clarity.`,
                points: ['Flawless matching of financial statements',
                    'Organized reports for easy understanding',
                    'Fast turnaround without compromising accuracy',
                    'Supportive assistance for complex reconciliations'
                ]
            },
            offerContent: {
                title: 'What’s Included in the Services We Offer',
                content: 'Thorough reconciliation at month and year-end ensures your accounts are balanced and ready for audits and tax filing.',
                listObj: [
                    {
                        title: 'Account Review',
                        content: `Conduct a full review of your accounts at month-end and year-end to 
                        ensure accuracy and avoid discrepancies.`
                    },
                    {
                        title: 'Transaction Matching',
                        content: `Match all transactions against your bank statements, ensuring that both sides 
                        of the ledger align properly.`
                    },
                    {
                        title: 'Adjustment Entry',
                        content: `Make necessary adjustments for any discrepancies found during reconciliation,
                         ensuring everything is correctly recorded.`
                    },
                    {
                        title: 'Tax Filing Preparation',
                        content: `Prepare your accounts for tax filing, making sure everything is in order for smooth submission.`
                    },
                    {
                        title: 'Financial Reporting',
                        content: `Generate clear financial statements that reflect your business’s performance, ready for any audits or investor reviews.`
                    },
                    {
                        title: 'Audit Assistance',
                        content: `Provide all necessary documentation and support during audits, ensuring compliance with tax laws and regulations.`
                    },
                ]
            },
            // Pending
            whoContent: {
                title: 'Who Can Benefit from Our Services',
                content: `Month-end and year-end reconciliation is essential to maintaining financial accuracy. Our service helps businesses ensure that their accounts match with the bank statements and records are up-to-date. We ensure all your financial records are accurate and match up with your bank statements. Whether you're preparing for tax season or audits, we ensure that everything adds up, saving you time and reducing errors. With our expertise, you can confidently close your books each month and year, knowing everything is accurate.`,
                listObj: [
                    {
                        title: 'Small and Medium-Sized Businesses',
                        content: 'Ensure monthly accounts are accurate and ready for tax season without stress.'
                    },
                    {
                        title: 'Freelancers and Independent Contractors',
                        content: 'Reconcile earnings and expenses with ease, ensuring you stay on top of your finances year-round.'
                    },
                    {
                        title: 'Corporate Companies',
                        content: 'Reconcile accounts across different departments with a streamlined process, ensuring accuracy.'
                    },
                    {
                        title: 'Retail & E-commerce Businesses',
                        content: `Ensure your online sales and expenses match bank statements, keeping records error-free.`
                    },
                ]
            },
            rightContent: [
                {
                    title: 'Our Approach to Month-End & Year-End Reconciliation Management',
                    arrycontent: [
                        {
                            title: 'Initial Review of Financial Data',
                            content: `We begin by reviewing your financial data to ensure accuracy. Our experts analyze your accounts, identify discrepancies, and provide insights into any necessary adjustments. This step helps streamline the reconciliation process and ensures your financial records are in 
                            perfect order for the end-of-year review.`
                        },
                        {
                            title: 'Thorough Analysis of Tax Obligations',
                            content: `Our team focuses on analyzing your tax obligations, including outstanding taxes and relevant deductions. We review your records and assess potential tax liabilities to ensure all taxes are calculated correctly, reducing the risk of errors and helping your business stay on track for compliance.`
                        },
                        {
                            title: 'Error-Free Data Verification',
                            content: `Data verification is essential for producing flawless financial reports. By cross-checking all data sources, such as invoices and bank records, any inconsistencies are resolved. This ensures that your reports are error-free and comply with regulatory requirements, eliminating potential risks and 
                            ensuring smooth submission without last-minute adjustments.`
                        },
                        {
                            title: 'Regulatory Compliance Assurance',
                            content: `Remaining compliant with all relevant tax and financial regulations is critical. Regular reconciliations ensure that reports meet industry standards and legal requirements. Any discrepancies or risks of non-compliance are immediately flagged and addressed, helping your business avoid fines, audits, or delays while ensuring full adherence to regulatory mandates.`
                        },
                    ]
                },
                {
                    title: 'Benefits of Our Month-End & Year-End Reconciliation Management Services',
                    arrycontent: [
                        {
                            title: 'Better Decision-Making with Clear Financial Reports',
                            content: `Precise and consistent financial reports provide a clear picture of your business’s health. Regular reconciliations allow you to make informed decisions with confidence, knowing that all financial data is accurate and up-to-date,
                             empowering you to plan strategically for future growth.`
                        },
                        {
                            title: 'Minimized Risk of Financial Errors',
                            content: `We help minimize financial discrepancies through regular reconciliation. Our expert team promptly corrects errors, preventing costly mistakes and maintaining precise records. This improves your company’s accuracy, helping you make confident financial decisions while avoiding potential risks in reporting and taxation.`
                        },
                        {
                            title: 'Improved Operational Efficiency',
                            content: `Efficient reconciliations streamline financial management, allowing your team to focus on other critical tasks. With clear, accurate reports generated consistently, time spent on corrections or audits is minimized. This boosts productivity and supports smoother operations, enhancing overall business efficiency.`
                        },
                        {
                            title: 'Time-Saving and Cost-Effective Service',
                            content: `Outsourcing your month-end and year-end reconciliation allows your team to focus on more strategic tasks while we handle the financial details. This time-saving approach ensures that your business operations run smoothly without sacrificing accuracy, all while keeping costs manageable and within budget.`
                        },
                    ]
                }
            ]
        },
        {
            title: 'Bank Reconciliations',
            icon: '../GIF/negotiation.gif',
            content: `Accurate matching of your records with bank statements to ensure every penny is accounted for.`,
            path: 'bank-reconciliations',

            bannerContent: `Keep your accounts in sync with error-free bank reconciliation services that save time and reduce financial discrepancies.`,
            slug: 'bank-reconciliations',
            whatContent: {
                qns: `What is Bank Reconciliation?`,
                ans: `Bank reconciliation matches your company’s financial records with bank statements. This process identifies errors, tracks unrecorded transactions, and ensures accurate financial accounts. Our team handles bank reconciliation with precision, ensuring your business records align perfectly with your bank’s data. With accurate accounts, you can avoid financial errors, reduce risks, and make better financial decisions for your business.`
            },
            whyContent: {
                qns: 'Why Choose Us for Bank Reconciliation?',
                content: `Our bank reconciliation services provide unmatched accuracy, ensuring your bank statements and accounting records are always aligned.`,
                points: ['Quick identification of transaction mismatches',
                    'Smooth syncing with your accounting software',
                    'Accurate matching of every single transaction',
                    'Detailed updates for clear financial records'
                ]
            },
            offerContent: {
                title: 'What’s Included in the Services We Offer',
                content: 'Efficient bank reconciliation helps keep your financial records accurate by ensuring your bank statements match your business accounts.',
                listObj: [
                    {
                        title: 'Transaction Verification',
                        content: `Cross-check bank transactions with business records to ensure there are no errors or discrepancies in your financial data.`
                    },
                    {
                        title: 'Account Matching',
                        content: `Match your bank account balances with the company ledger to ensure consistency in your financial reports.`
                    },
                    {
                        title: 'Error Resolution',
                        content: `Quickly resolve any mismatches between bank statements and business records, maintaining accurate and up-to-date accounts.`
                    },
                    {
                        title: 'Unrecorded Transactions',
                        content: `Identify and record any unlisted transactions, ensuring every detail is captured for financial accuracy.`
                    },
                    {
                        title: 'Fraud Detection',
                        content: `Monitor bank transactions for any unauthorized charges or discrepancies, taking action to prevent financial loss.`
                    },
                    {
                        title: 'Reconciliation Reporting',
                        content: `Provide clear, concise reconciliation reports for internal records and tax reporting purposes.`
                    },
                ]
            },
            // Pending
            whoContent: {
                title: 'Who Can Benefit from Our Services',
                content: `Bank reconciliation is crucial for ensuring that your business's financial records match your bank statements. We simplify this process by checking every transaction to ensure accuracy. Our service ensures that your bank records and financial data are consistent, minimizing errors. Whether you're a small business or large corporation, we make sure your accounts stay in perfect alignment with your bank, helping you avoid costly mistakes. Our service ensures you never miss a detail, reducing the risk of errors as we take care of the time-consuming work. `,
                listObj: [
                    {
                        title: 'Small Businesses & Entrepreneurs',
                        content: 'Easily reconcile transactions with your bank’s records, keeping your books accurate and up-to-date.'
                    },
                    {
                        title: 'Freelancers and Consultants',
                        content: 'Track personal and business transactions with accurate bank reconciliation for financial clarity.'
                    },
                    {
                        title: 'Retail Businesses',
                        content: 'Reconcile your daily sales and expenses against bank records to keep track of your financial health.'
                    },
                    {
                        title: 'Online Businesses & E-commerce',
                        content: `Track every online transaction and reconcile them with your bank to ensure accuracy and prevent errors.`
                    },
                ]
            },
            rightContent: [
                {
                    title: 'Our Approach to Bank Reconciliation',
                    arrycontent: [
                        {
                            title: 'Thorough Review of Bank Statements',
                            content: `Each bank statement is carefully reviewed to match every transaction. We compare the details of your accounts with the records to identify errors or mismatches. This thorough process ensures that all figures align correctly, maintaining the integrity of your 
                            financial data and preventing potential discrepancies.`
                        },
                        {
                            title: 'Meticulous Transaction Matching',
                            content: `Once the records are reviewed, Each transaction is carefully matched to confirm that no data is overlooked. Our team checks for inconsistencies between bank statements and your records, ensuring that every entry is verified. This thorough approach guarantees that your financial documents remain precise,
                             avoiding any errors that could lead to financial setbacks or misunderstandings.`
                        },
                        {
                            title: 'Reconciliation Adjustments and Resolutions',
                            content: `We make necessary adjustments to resolve discrepancies between your accounts and bank statements. Our team works diligently to correct errors and adjust entries, ensuring that your financial records are flawless and aligned. This step guarantees that your bank reconciliation is 100% accurate and compliant with your business needs.`
                        },
                        {
                            title: 'Continuous Monitoring and Updates',
                            content: `We offer continuous oversight of your bank reconciliation to ensure ongoing accuracy. As new transactions occur, our team reviews and updates your records regularly. This proactive approach keeps your financial data up-to-date and consistent with your bank statements, supporting smooth financial operations for your business.`
                        },
                    ]
                },
                {
                    title: 'Benefits of Our Bank Reconciliation Services',
                    arrycontent: [
                        {
                            title: 'Accurate Financial Insights',
                            content: `Bank reconciliation ensures that your financial records are precise, helping you understand your true financial position. Regular reconciliations reduce errors and give you a clearer picture of your business's health, enabling better financial decision-making and control.`
                        },
                        {
                            title: 'Improved Cash Flow Management',
                            content: `With accurate bank reconciliations, you can monitor your cash flow effectively. By keeping track of every transaction, you avoid overestimating available funds and ensure that your business remains on a steady financial course, allowing for timely payments and business growth.`
                        },
                        {
                            title: 'Minimized Risk of Financial Errors',
                            content: `Our thorough reconciliation process reduces the risk of costly financial errors. By identifying discrepancies early, we prevent them from becoming larger issues, saving your business money and time. This approach helps avoid penalties, enhances financial health, and keeps your accounts compliant with relevant regulations.`
                        },
                        {
                            title: 'Time-Saving and Efficient Service',
                            content: `Bank reconciliation can be time-consuming, but our service streamlines the process. By handling it for you, we save you time and effort, allowing you to focus on more critical aspects of your business. You can rely on our efficiency to keep your financial records in order without delay.`
                        },
                    ]
                }
            ]
        },
        {
            title: 'Budgeting & Forecasting',
            icon: '../GIF/budgeting.gif',
            content: `Plan smarter with expert budgeting and forecasting to achieve your business goals.`,
            path: 'budgeting-forecasting',

            bannerContent: `Plan for success with precise budgeting and forecasting services to keep your business on the right financial path.`,
            slug: 'budgeting-forecasting',
            whatContent: {
                qns: 'What is Budgeting & Forecasting?',
                ans: `Budgeting and forecasting help plan your financial future by tracking income, expenses, and predicting growth. This process provides a clear roadmap to achieve your business goals. We help you create accurate budgets and forecasts personalized to your needs. With data-backed insights, we help you manage costs, plan investments, and achieve long-term success. Trust us to guide your business toward financial stability and growth.`
            },
            whyContent: {
                qns: 'Why Choose Us for Budgeting & Forecasting?',
                content: `We help you create insightful, data-driven budgets and forecasts to empower your business decisions and maximize profitability.`,
                points: ['Realistic & Data-backed budgeting for better decisions',
                    'Clear forecasts based on reliable insights',
                    'Customized plans for business-specific goals',
                    'Improved financial stability over time'
                ]
            },
            offerContent: {
                title: 'What’s Included in the Services We Offer',
                content: 'Create accurate budgets and forecasts to help guide your business towards financial success and sustainability.',
                listObj: [
                    {
                        title: 'Budget Planning',
                        content: `Assist in planning your annual budget, allocating funds efficiently across different business areas.`
                    },
                    {
                        title: 'Expense Projections',
                        content: `Predict future expenses based on historical data, ensuring your business stays within budget without surprises.`
                    },
                    {
                        title: 'Revenue Estimation',
                        content: `Estimate potential revenue based on past performance and market trends, helping guide future business decisions.`
                    },
                    {
                        title: 'Cash Flow Forecasting',
                        content: `Create accurate forecasts for cash flow to ensure there is always enough liquidity to meet obligations.`
                    },
                    {
                        title: 'Financial Strategy Development',
                        content: `Develop strategic financial plans to help the business grow while managing costs effectively.`
                    },
                    {
                        title: 'Performance Tracking',
                        content: `Monitor actual performance against your budget, adjusting as needed to stay on track with business goals.`
                    },
                ]
            },
            whoContent: {
                title: 'Who Can Benefit from Our Services',
                content: `Effective budgeting and forecasting help businesses plan ahead and make informed decisions. We help you create realistic budgets and forecasts that keep your finances on track. Whether it’s managing day-to-day expenses or planning for long-term growth, our experts provide guidance so that you can allocate resources wisely and stay ahead of any financial challenges. You’ll have clear financial goals with the right tools to achieve them and make smart financial decisions`,
                listObj: [
                    {
                        title: 'Small and Medium Enterprises',
                        content: 'Plan your finances and forecast future expenses, keeping your business on track for growth.'
                    },
                    {
                        title: 'Freelancers and Self-Employed Individuals',
                        content: 'Manage your personal and business finances with simple budgeting tools to reach your financial goals.'
                    },
                    {
                        title: 'Corporations',
                        content: 'Plan and forecast business expenses and growth, ensuring your financials align with company goals.'
                    },
                    {
                        title: 'E-commerce Businesses',
                        content: `Budget for seasonal changes, inventory, and marketing, ensuring your online business stays profitable year-round.`
                    },
                ]
            },
            rightContent: [
                {
                    title: 'Our Approach to Annual Tax Returns ',
                    arrycontent: [
                        {
                            title: 'Initial Consultation to Understand Your Financial Needs',
                            content: `We begin by examining your financial situation to understand your specific requirements. Our experts conduct a detailed analysis of your revenue, expenses, and goals to create a customized budgeting and forecasting 
                            plan that aligns with your business vision, setting you up for long-term success.`
                        },
                        {
                            title: 'Realistic Financial Projections',
                            content: `Our team develops accurate forecasts based on past financial data and market trends. By utilizing these projections, we ensure your business can plan for upcoming costs, potential risks, and growth opportunities. The goal is to prepare your business for the 
                            future with realistic financial expectations that can be met.`
                        },
                        {
                            title: 'Strategic Planning and Budget Allocation',
                            content: `Based on the data analysis, we help allocate your budget across crucial areas of your business. Our strategic planning ensures resources are used efficiently, focusing on growth while maintaining financial stability. The plan provides clarity on how to manage costs while achieving set objectives, leading to sustainable growth.`
                        },
                        {
                            title: 'Regular Monitoring and Adjustments',
                            content: `We don’t stop at planning; we continuously monitor your financial performance. Our experts track your budget’s progress and make necessary adjustments, ensuring that any changes in market conditions or business operations are addressed promptly. 
                            This ensures your budget and forecast remain relevant and accurate over time.`
                        },
                    ]
                },
                {
                    title: 'Benefits of Our Budgeting & Forecasting Services',
                    arrycontent: [
                        {
                            title: 'Improve Financial Clarity',
                            content: `Our services provide a clear picture of your business’s financial status. By establishing a realistic budget and forecasting future performance, you’ll have a better understanding of 
                            where your money is going and how to manage it effectively.`
                        },
                        {
                            title: 'Maximize Resource Allocation',
                            content: `Effective budgeting allows for better allocation of resources, ensuring that every dollar is spent wisely. Our approach ensures you invest in areas that will bring the most value to your business, supporting growth while managing costs efficiently.`
                        },
                        {
                            title: 'Increase Financial Control',
                            content: `With regular forecasting and monitoring, you’ll have greater control over your financial decisions. This allows you to react to changing circumstances quickly, making informed decisions that drive profitability and financial success.`
                        },
                        {
                            title: 'Achieve Long-Term Business Stability',
                            content: `By setting clear financial goals and tracking progress, budgeting and forecasting help your business stay on track. With our support, you can anticipate future financial challenges, plan for growth, and maintain long-term stability, ensuring your business remains competitive in the market.`
                        },
                    ]
                }
            ]
        },
    ]
    let value = { homeBanner, loading, services, allblog, getBlog }

    return (
        <AppStore.Provider value={value} >
            {children}
        </AppStore.Provider>
    )
}

export default AppContext