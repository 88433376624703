import React, { useEffect, useState } from 'react'
import JobApplying from './JobApplying'
import axios from 'axios'
import { port } from '../App'

const ApplyJob = () => {
    let [availableJob, setAvailableJobs] = useState([])
    let getAvailableJobs = () => {
        axios.get(`${port}/blog/job_description/`).then((response) => {
            console.log(response.data);
            setAvailableJobs(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        getAvailableJobs()
    }, [])
    return (
        <div className=' py-5 pb-0 ' >
            <main className='row justify-between container mx-auto ' >
                <section className='col-md-5 ' >
                    <h3 className='text-4xl sm:text-5xl roman fw-semibold '> Submit Your Application </h3>
                    <p className=' my-3 poppins text-slate-600 fw-medium ' >
                        Send in your application by filling out the Job Application Form.
                    </p>
                    <div className='h-[20px] ' >

                    </div>
                    {
                        availableJob.map((obj) => (
                            <div className='rounded my-3 border-[1px] border-slate-800
                             p-3 flex items-center justify-between ' >
                                <p className='m-0 text-slate-600 fw-medium' >
                                    {obj.Title}
                                </p>
                                <p className=' m-0 text-sm text-slate-500 ' >
                                    {obj.job_type}
                                </p>
                            </div>))}
                </section>
                <section className='col-md-5 ' >
                    <img src={require('../Assets/careerapply.png')} alt="CareerApply"
                        className=' rounded-xl w-[80%] sm:w-[70%] mx-auto ' />
                </section>
            </main>
            <JobApplying availableJobs={availableJob} />
        </div>
    )
}

export default ApplyJob