import React, { useEffect, useState } from 'react'
import Banner from '../Components/Home/Banner'
import Footer from '../Components/Home/Footer'
import axios from 'axios'
import { port } from '../App'
import { toast } from 'react-toastify'

const ContactPage = () => {
  let [formData, setFormData] = useState({
    first_name: '',
    phone: '',
    email: '',
    message: '',
  })
  let handleChange = (e) => {
    let { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  let submitForm = () => {
    if (formData.email != '' && formData.first_name != '' && formData.phone != '') {
      axios.post(`${port}/blog/ContactsInformation/`, formData).then((response) => {
        toast.success('Form Submitted')
        setFormData({
          first_name: '',
          phone: '',
          email: '',
          message: '',
        })
      }).catch((error) => {
        console.log(error);
        toast.error('Error occured')

      })
    }
    else
      toast.warning('Fill all the fields')
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Banner title='Partner with Us for Smarter Finances' page content='   ' />
      <main className='container mx-auto text-center py-3 ' >
        <h5 className=' txtclrv poppins text-xl fw-medium ' >Contact us </h5>
        <h2 className='roman text-3xl fw-semibold my-4 sm:text-5xl ' >
          Want to Manage Your Finances? <br /> Let’s Connect
        </h2>
      </main>
      <div className='min-h-[80vh] mb-5 container mx-auto rounded-xl relative sm:flex ' >
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.526357967026!2d77.53827311095945!3d13.00211781415493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d70ffffffc7%3A0xf6ba454a964cc45!2sAccounts%20Point!5e0!3m2!1sen!2sin!4v1735976156591!5m2!1sen!2sin"
          className='w-full md:absolute z-0 h-[50vh] md:h-[80vh] rounded-xl ' loading="lazy" referrerpolicy="no-referrer-when-downgrade">
        </iframe>

        <div className=' rounded-xl p-0 overflow-hidden row md:w-[90%] lg:w-[70%] md:left-10 relative z-10 m-auto ' >
          <section className='col-md-6  bg-white order-2 order-md-1 p-3 flex ' >
            <article className='m-auto w-full ' >
              <div className=' my-4 '>
                <h4 className='roman text-3xl fw-semibold ' > Address </h4>
                <p className='poppins fw-medium text-sm text-slate-500 fw-medium my-2 ' >
                  Accounts Point Mahalakshmipuram, 1466, 14th Main Rd, near Shankar Mutt Road,
                  Mahalakshmipuram, Bengaluru, Karnataka India
                </p>
              </div>
              <div className=' my-4 '>
                <h4 className='roman text-3xl fw-semibold ' > Call Us </h4>
                <a href="tel:+919980331886" className='text-decoration-none text-slate-500 fw-medium my-2 ' >  +91-99-803-31886</a> <br />
                <a href="tel:+61390137786" className='text-decoration-none text-slate-500 fw-medium my-2 ' >  +61-39-013-7786</a>
              </div>
              <div className=' my-4 ' >
                <h4 className='roman text-3xl fw-semibold ' > Email Address </h4>
                <a href="mailto:info@accountspoint.com" className='text-decoration-none text-slate-500 fw-medium ' > info@accountspoint.com </a>
              </div>
            </article>
          </section>
          <section className='col-md-6 order-1  bg-white p-3 ' >
            {/* Form */}
            <article className=' w-[90%] mx-auto ' >
              <input type="text" placeholder='Name' value={formData.first_name} name='first_name' onChange={handleChange}
                className='p-3 my-3 bg-[#E7E5E5] outline-none rounded w-full ' />
              <input type="text" placeholder='Phone' value={formData.phone} name='phone' onChange={handleChange}
                className='p-3 my-3 bg-[#E7E5E5] outline-none rounded w-full ' />
              <input type="text" placeholder='Email' value={formData.email} name='email' onChange={handleChange}
                className='p-3 my-3 bg-[#E7E5E5] outline-none rounded w-full ' />
              <textarea type="text" placeholder='Message' rows={5} value={formData.message} name='message' onChange={handleChange}
                className='p-3 my-3 bg-[#E7E5E5] outline-none rounded w-full ' ></textarea>
              <button onClick={submitForm} className='border-2 border-slate-800 p-2 rounded px-3 fw-medium poppins ms-auto flex ' >
                Submit
              </button>
            </article>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ContactPage