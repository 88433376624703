import React, { useEffect, useState } from 'react'

const SipCalculator = ({ setActiveSection }) => {
  let [mathValues, setMathValues] = useState({
    amount: 500,
    interest: 1,
    years: 1
  })
  let handleChange = (e) => {
    let { name, value } = e.target
    setMathValues((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  function calculateSIP(principal, rateOfReturn, tenure) {
    // Convert annual rate of return to monthly rate
    const monthlyRate = rateOfReturn / 100 / 12;
    const months = tenure * 12; // Convert years to months

    // Calculate the future value
    const futureValue =
      principal * ((Math.pow(1 + monthlyRate, months) - 1) / monthlyRate) * (1 + monthlyRate);

    return futureValue.toFixed(2); // Return result rounded to 2 decimal places
  }
  useEffect(() => {
    setActiveSection('sip')
  }, [])
  return (
    <div>
      <main className='row items-center justify-between ' >
        <section className='col-lg-5 poppins fw-semibold  txtclrv ' >
          {/* Amount */}
          <article className='my-4 py-2' >
            <div className='flex justify-between ' >
              <label htmlFor="">Monthly Investment </label>
              <span className=' bgclrv text-slate-200 p-1 px-2 rounded text-center min-w-[90px] text-sm ' >$ {mathValues.amount} </span>
            </div>
            <input type="range" value={mathValues.amount} className='w-full mt-4 p-0 '
              name='amount' min='500' max='50000' step='100' onChange={handleChange} />
          </article>
          {/* INterest */}
          <article className='my-4 py-2 ' >
            <div className='flex justify-between ' >
              <label htmlFor="">Expected return rate </label>
              <span className=' bgclrv text-slate-200 p-1 px-2 rounded text-center min-w-[90px] text-sm ' > {mathValues.interest} % </span>
            </div>
            <input type="range" value={mathValues.interest} className='w-full mt-4 p-0 '
              name='interest' min='1' step='0.1' max='20' onChange={handleChange} />
          </article>
          {/* Loan Tenure */}
          {/* INterest */}
          <article className='my-4 py-2 ' >
            <div className='flex justify-between ' >
              <label htmlFor="">Time Period  </label>
              <span className=' bgclrv text-slate-200 p-1 px-2 rounded text-center min-w-[90px] text-sm ' > {mathValues.years} yrs </span>
            </div>
            <input type="range" value={mathValues.years} className='w-full mt-4 p-0 '
              name='years' min='1' max='30' onChange={handleChange} />
          </article>
        </section>
        <section className='col-lg-6 flex flex-wrap  ' >
          {/* Card 1 */}
          <div className='w-1/2 text-center poppins my-3 ' >
            <span className='txtgrd text-3xl sm:text-4xl fw-semibold ' >$ {mathValues.amount * mathValues.years * 12} </span>
            <p className='poppins my-2 fw-semibold text-xl text-slate-700 ' >Invested amount</p>
          </div>
          <div className='w-1/2 text-center poppins my-3 ' >
            <span className='txtgrd text-3xl sm:text-4xl fw-semibold ' > $ {
            ((calculateSIP(mathValues.amount, mathValues.interest, mathValues.years))
             - (mathValues.amount* mathValues.years * 12)).toFixed(2)} </span>
            <p className='poppins my-2 fw-semibold text-xl text-slate-700 ' >Est. Return</p>
          </div>
          <div className='w-1/2 text-center poppins my-3 ' >
            <span className='txtgrd text-3xl sm:text-4xl fw-semibold ' > $ {calculateSIP(mathValues.amount, mathValues.interest, mathValues.years)} </span>
            <p className='poppins my-2 fw-semibold text-xl text-slate-700 ' >Total Value </p>
          </div>

        </section>
      </main>
    </div>
  )
}

export default SipCalculator