import React, { useState } from 'react'

const OtherServices = () => {
    let [selectedIndex, setSelectedIndex] = useState(-1)
    let otherServices = [
        {
            icon: '../../Assets/marketing.png',
            title: 'Creative Marketing & Advertising',
            hovericon: '../../GIF/marketing.gif'
        },
        {
            icon: '../../Assets/factory.png',
            title: 'Manufacturing & Production',
            hovericon: '../../GIF/factory.gif'
        },
        {
            icon: '../../Assets/container-ship.png',
            title: 'Logistics & Supply Chain',
            hovericon: '../../GIF/container-ship.gif'
        },
        {
            icon: '../../Assets/lawyer.png',
            title: 'Legal & Advisory',
            hovericon: '../../GIF/lawyer-man.gif'
        },
        {
            hovericon: '../../GIF/map.gif',
            icon: '../../Assets/travel.png',
            title: 'Hospitality & Tourism',
        },
        {
            icon: '../../Assets/rental.png',
            title: 'Real Estate & Rentals',
            hovericon: '../../GIF/rental.gif'
        },
        {
            icon: '../../Assets/education.png',
            title: 'Educational Institutions',
            hovericon: '../../GIF/education.gif'
        },
        {
            icon: '../../Assets/charity.png',
            title: 'Non-Profit Organization',
            hovericon: '../../GIF/charity.gif'
        },
    ]
    return (
        <div className='bg-[#ebe7f7] py-4 ' >
            <main className='container mx-auto ' >
                <h2 className='text-3xl sm:text-4xl lg:text-5xl fw-semibold
                 roman w-[80%] sm:w-[70%] text-center mx-auto' > Industry-Specific Financial Services </h2>
                <p className='my-3 mx-auto text-center text-slate-500 w-[80%] sm:w-[60%] lg:w-[50%] ' >
                    We provide expert accounting and financial support to businesses
                    across various sectors, ensuring smooth operations and growth.
                </p>
                <section className='row  ' >
                    {
                        otherServices.map((obj, index) => (
                            <div className=' col-6 my-3 duration-500 p-3 col-lg-4 flex gap-3 items-center ' >
                                <div onMouseEnter={() => setSelectedIndex(index)}
                                    onMouseLeave={() => setSelectedIndex(-1)}
                                    className=' rounded-full p-3 bg-white shadow  ' >
                                    {selectedIndex != index && <img src={obj.icon} width={43} alt="Icno" className=' d-none d-sm-block ' />}

                                    <img src={obj.hovericon} width={45} alt="Icno" className={`  ${selectedIndex == index ? ' d-block ' : ' d-sm-none  '} `} />
                                </div>
                                <h6 className='roman text-xl fw-medium ' > {obj.title} </h6>
                            </div>
                        ))
                    }
                </section>
            </main>
        </div>
    )
}

export default OtherServices