import React, { useState } from 'react';
import Slider from 'react-slick';
import DoubleQuotes from '../../Icons/DoubleQuotes';
import RatingCom from '../AccountsBookkeeping/RatingCom';

const Testimonial = () => {
    const [centerIndex, setCenterIndex] = useState(1); // Default to the first slide (index 0)

    // const afterChangeHandler = (current) => {
    //     // alert(current)
    //     setCenterIndex(current == testimonial.length - 1 ? 0 : current + 1); // Update the center index after the slide change
    // };

    const settings2 = {
        dots: false,
        arrows: false,
        speed: 2000,
        centerMode: true, // Ensures the center slide is highlighted
        centerPadding: '30%', // Optional: Adjust padding for better alignment
        slidesToShow: 1,
        className: 'center',
        // afterChange: afterChangeHandler, 
        // Updates the center index after every slide
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const testimonial = [
        {
            name: 'Jeff Cremer',
            img: '',
            position: 'Rain Forest & Starfire One LLC',
            content: `This guy is amazing. He was always available and got everything in order for me.
             I am going to be working with him on a regular basis now. GREAT ACCOUNTANT!! I RECOMMEND HIM TO EVERYONE!!!`,
        },
        {
            name: 'Kathy Nielsen',
            img: '',
            position: 'Deadlines Inc | USA',
            content: `I am very satisfied with the outcome of this job. Chidanand is a very professional 
            accountant as well as a likeable person and I will definitely be working with him in the future for my bookkeeping needs`,
        },
        {
            name: 'Jag Karnan',
            img: '',
            position: 'Databacx Pte Ltd | Singapore',
            content: `Great service provider, Responsive and provided more than expected`,
        },
        {
            name: 'Suniti Jain',
            img: '',
            position: '7 Hangers Inc | USA',
            content: `We are Very are happy with the knowledge and work attitude of Chida. I will continue his accounting service.
             He was very patient and understanding of my little knowledge of accounting. I highly recommend him.`
        },
        {
            name: 'Ronmac Holdings Pty Ltd',
            img: '',
            position: 'Australia',
            content: `Great service and good knowledge of Australian organisations. Will use again.`
        },
        {
            name: 'Srefano Giusppe',
            img: '',
            position: 'FORMICA USA',
            content: `Excellent work; reliable, on time, and detail oriented. Thank you.`
        },
        {
            name: 'David',
            img: '',
            position: 'PO Boys Restaurant | USA',
            content: `Excellent and quick service! Everything was delivered as promised`
        },
        {
            name: 'Kevin Kogler',
            img: '',
            position: 'Microbizz LLC | USA',
            content: `I am very satisfied with the outcome of this job. Chidanand is a very professional 
            accountant as well as a likeable person and I will definitely be working with him in the future for my bookkeeping needs`
        },
        {
            name: 'AMTB Pty Ltd (EasyABC )',
            img: '',
            position: 'Australia',
            content: `We had some issues and miscommunications at the beginning but we worked through it. 
            I am happy and look forward to a long term business relationship! Thanks.`
        },
        {
            name: 'Kayvon Andrew & Lily Yang Jia',
            img: '',
            position: 'HongKong',
            content: `Again very good experience working with accountpoint`
        },
        {
            name: 'Luontolife',
            img: '',
            position: 'Michigan',
            content: `It is been a pure pleasure to work with Accountpoint.Response time has been fast and all issues are solved professionally.I have never been a
             huge fan of accounting, but Accountpoint made it pleasurable experience.Will definitely work with them in future too.`
        },
        {
            name: 'Maja Antonic',
            img: '',
            position: 'Camp California',
            content: `We were very happy with Accounts Point’s responsiveness, 
            speed and accuracy. They are pleasure to work with and come highly recommended.`
        },
        {
            name: 'Yee Meng Kum David Advance Management Consultant',
            img: '',
            position: 'Singapore',
            content: `I must say that I am very pleased with Accounts Point. They are professional in their work and very fast in responding. 
            You can always catch hold of them online. I highly recommend that you consider them for your account/finance work.`
        },
    ];

    return (
        <div className='pb-5 ' >
            <main className="min-h-[35vh] text-center relative z-10  row container mx-auto items-center">
                <section className="  ">
                    <h2 className='poppins my-2 text-[#4636FF] text-3xl ' > Testimonial </h2>
                    <h2 className="text-3xl sm:text-5xl roman fw-semibold">
                        What Our <br /> Clients Have to Say
                    </h2>
                    <p className="text-slate-500 poppins my-4 mb-1 w-[80%] lg:w-[60%] mx-auto   tracking-wide poppins">
                        Don’t just take our word for it. Check out how we've simplified financial management for businesses
                        like yours with reliable and personalized financial solutions.
                    </p>
                </section>

            </main>
            <section className="  ">
                <div className=" ">
                    <Slider {...settings2} className=" slidercustom2 ">
                        {testimonial.map((obj, index) => (
                            <div className=' poppins my-3 ' >
                                <div key={index}
                                    className={`min-h-[50vh] slick-obj poppins text-center
                                    relative shadow rounded-xl p-3 my-3  flex flex-col items-center `}>
                                    <article className='my-auto ' >
                                        <p className='mb-0 nameSection text-xl lg:text-2xl fw-semibold'> {obj.name.slice(0, 1)} </p>
                                        <div className='w-fit  mx-auto my-3 ' >
                                            <RatingCom num={5} />
                                        </div>
                                        {/*  */}
                                        <h5 className='title text-3xl my-2 fw-semibold ' > {obj.name} </h5>
                                        <p className=' my-2 ' > {obj.position} </p>
                                        <p className='  content text-sm w-[90%] mx-auto ' >
                                            {obj.content}
                                        </p>

                                    </article>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        </div>
    );
};

export default Testimonial;
