import React from 'react'
import { useNavigate } from 'react-router'

const BlogCard = ({ size, data, titlecss }) => {
    let navigate = useNavigate()
    return (
        <div>
            <main onClick={() => navigate(`/blogs/${data.slug}`)} 
            className='shadow bg-white  cursor-pointer rounded-xl items-center p-3 row ' >
                <section className={` ${size} `} >
                    <img loading='lazy' src={data.img} alt="Blog Image" className='w-full rounded-xl ' />
                </section>
                <section className={` ${size} py-3 `} >
                    <span className='poppins uppercase bg-violet-100 text-sm fw-medium txtclrv p-2 rounded ' >
                        {data.Category ? data.Category : data.Blog_Category && data.Blog_Category?.name}
                    </span>
                    <h4 className={` roman ${titlecss ? titlecss : "text-2xl"} line-clamp-2 my-3 fw-semibold`}>
                        {data.Main_Title} </h4>
                    <p className='line-clamp-3 my-3 poppins text-[#787785] fw-base ' >
                        {data.Paragraph1}
                    </p>
                </section>
            </main>
        </div>
    )
}

export default BlogCard