import React, { useEffect } from 'react'
import Banner from '../Components/Home/Banner'
import ShakyColor from '../Icons/ShakyColor'
import Ourservices from '../Components/Home/Ourservices'
import ContiresWeWork from '../Components/Home/ContiresWeWork'
import SoftwareWeUse from '../Components/Home/SoftwareWeUse'
import BigRing from '../Icons/BigRing'
import Testimonial from '../Components/Home/Testimonial'
import Footer from '../Components/Home/Footer'

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Banner content={`End-to-end financial management that’s 
        transparent, reliable, and designed in a way that helps you run your business without financial stress. `} />
      {/* Cost & time */}
      <main className='row col-12 col-sm-11 my-4 mx-auto  ' >
        <section className='col-lg-5 ' >
          <img src={require('../Assets/costandtime.png')} alt="CostAndTime"
            className=' object-cover ' loading='lazy' />
        </section>
        <section className='col-lg-7 flex  ' >
          <div className=' ' >
            <h2 className='roman text-4xl mt-4 fw-semibold sm:text-5xl lg:text-6xl ' >
              Financial Precision & Data Security Guaranteed       </h2>
            <p className='poppins text-slate-600 my-3 leading-[160%] ' >
              Business thrives when finances are under control, and that’s exactly what we deliver at Accounts Point. We take the burden of financial management off your shoulders and manage  your accounts like they’re our own. We take care of everything, from recording daily transactions and paying salaries on time to planning for the future. Our services are designed to fit the specific needs of businesses of all types and sizes, no matter where they are located. We ensure your financial records are always updated and accurate, so you don’t have to worry. We keep you informed with regular updates, so you always know what’s happening with your finances.
              <br />
              <br />
              While we focus on keeping your financial records accurate and up to date, you can put all your energy into growing your business. Let us handle the numbers so you can achieve your goals with confidence.
            </p>
          </div>
        </section>
      </main>
      {/* Expert Accountants */}
      <main className='container-fluid min-h-[40vh] px-0 mx-0 row relative ' >
        <section className='relative col-lg-6 bgclr flex' >
          <div className='absolute top-0 left-0  ' >
            <ShakyColor />
          </div>
          <div className='relative z-10 w-[80%] text-slate-200 m-auto ' >
            <h2 className='roman text-4xl mt-4 fw-semibold sm:text-5xl lg:text-6xl ' >
              Accuracy in Every Financial Report! </h2>
            <p className='leading-7 text-slate-300 my-3 ' >
              Get the financial insights you need, right at your fingertips. With clear reports, timely updates, and real-time financial data, our team ensures you’re always informed and in control of your business’s financial health. Every report is simple to understand, leaving no room for confusion. We break down all the important details so you can make smart decisions quickly.
              Stay updated and confident, knowing that we’re always here to help you manage your finances better.
            </p>
          </div>
        </section>
        <section className='col-lg-6 px-0 ' >
          <img src={require('../Assets/employeeImage.png')}
            className=' w-full h-full object-cover ' alt="ExperImage" />
        </section>
      </main>
      {/* Services */}
      <Ourservices />
      <ContiresWeWork />
      <SoftwareWeUse />
      {/* THe origin story of Account points */}
      <div className='relative worldbg ' >
        <main className='row container relative z-1 mx-auto min-h-[60vh] items-center ' >
          <section className='col-md-6  ' >
            <h2 className=' roman text-4xl text-center sm:text-5xl lg:text-6xl tracking-wider 
             fw-semibold text-slate-200 ' >The Origin Story of
              <span className=' my-3 txtgrd ' > Accounts Point</span> </h2>
          </section>
          <section className='col-md-6 h-[40vh] overflow-y-scroll hide-scrollbar ' >
            <p className='poppins text-slate-300 tracking-wider leading-[160%] text-xl fw-light my-4 ' >
              For over two decades, AccountsPoint has been a trusted name in accounting and tax advisory services, helping individuals and businesses confidently manage their finances. With a dedicated team of experienced accountants,
              we are committed to delivering reliable and professional solutions tailored to your needs.
            </p>
            <p className='poppins text-slate-300 tracking-wider leading-[160%] text-xl fw-light my-4 ' >
              Our team consists of qualified professionals with hands-on expertise in all aspects of accounting and taxation. We take pride in simplifying complex financial matters, empowering our clients to make decisions with confidence. By staying updated on the latest regulations and standards, we ensure that your financial dealings remain accurate and fully compliant with Australian laws.
            </p>
            <p className='poppins text-slate-300 tracking-wider leading-[160%] text-xl fw-light my-4 ' >
              At AccountsPoint, we value the unique needs of every client. Our approach is centered on providing personalized advice and solutions that are easy to understand and effective. Whether your financial situation is simple or more intricate, we dedicate our time and knowledge to
              ensure you feel informed and supported every step of the way.
            </p>
            <p className='poppins text-slate-300 tracking-wider leading-[160%] text-xl fw-light my-4 ' >
              We specialize in helping clients navigate the Australian taxation system. From identifying eligible deductions to ensuring the right tax payments, we make the process smooth and hassle-free. With our expert guidance, you can take advantage of every deduction and exemption available, ensuring you pay exactly what’s required—nothing more, nothing less. Let us handle your
              financial complexities to keep your business running smoothly and free from financial worry.
            </p>
          </section>
        </main>
        <div className='absolute d-none d-sm-block bottom-0 z-0 left-0 translate-y-1/2 -translate-x-1/2 ' >
          <BigRing opacity={30} size={300} border={60} />
        </div>
      </div>
      {/* Testimonial */}
      <Testimonial />
      <main className='container mx-auto row justify-between items-center ' >
        <section className='col-md-6 my-4 ' >
          <img src={require('../Assets/accountsPointLogo.png')}
            className=' ' alt="Logo" />
          <h4 className='roman text-2xl lg:text-3xl fw-semibold mt-4 tracking-wider ' >
            Set Your Finances Right with <span className='txtgrd block ' > Accounts point </span> </h4>
        </section>
        <section className='my-4 col-md-5 ' >
          <div className='flex gap-4 items-center ' >
            <input type="text" placeholder='Enter your mail' className='bg-transparent outline-none flex-1 border-2 rounded-xl p-2 ' />
            <button className='bggrd text-slate-200 roman fw-semibold p-2 px-3 rounded ' >
              Try Now
            </button>
          </div>
        </section>
      </main>
      {/* Footer */}
      <Footer />
    </div>
  )
}

export default HomePage