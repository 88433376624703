import React from 'react'
import { useNavigate } from 'react-router'

const ThankYouPage = () => {
    let navigate = useNavigate()
    return (
        <div className='h-[100vh] relative poppins ' >
            <img src={require('../../Assets/thankYouPagePattern.png')} alt="ThankYou"
                className='h-[100vh] w-full object-cover ' />
            <main className='absolute h-full flex w-full top-0 ' >
                <div className='text-center m-auto' >
                    <h6 className='text-[10rem] fw-semibold leading-[100%] ' > Thank <br /> You </h6>
                    <p>Thanks For Reaching Out!</p>
                    <p>Your Message Just Showed in my inbox. Talk to you Soon!</p>
                    <button onClick={() => navigate(-1)} className='p-2 px-3 rounded bgclrac text-slate-50 my-4 ' >
                        Go Back
                    </button>
                </div>
            </main>
        </div>
    )
}

export default ThankYouPage