import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { data, useNavigate, useParams } from 'react-router'
import { port } from '../App'
import Banner from '../Components/Home/Banner'
import Footer from '../Components/Home/Footer'
import RecentBlog from '../Components/Home/RecentBlog'
import { AppStore } from '../Context/AppContext'
import RightArrow from '../Icons/RightArrow'
import DoubleQuotes from '../Icons/DoubleQuotes'
import { Spinner } from 'react-bootstrap'

const ParticularBlog = () => {
    let { slug } = useParams()
    let { services } = useContext(AppStore)
    let [loading, setLoading] = useState(false)
    let navigate = useNavigate()
    let [blog, setBlog] = useState()
    useEffect(() => {
        window.scrollTo(0, 0)
        if (slug) {
            setLoading(true)
            axios.get(`${port}/blog/data/${slug}`).then((response) => {
                console.log(response.data, 'blog');
                setBlog(response.data)
                setLoading(false)
            }).catch((error) => {
                console.log(error);
                setLoading(false)
            })
        }
    }, [slug])
    function formatDate(isoDate) {
        const date = new Date(isoDate); // Parse the ISO string into a Date object

        // Format options for desired output
        const options = {
            weekday: 'long',
            day: '2-digit',
            month: 'long',
            year: 'numeric'
        };

        // Use Intl.DateTimeFormat to format the date
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    }
    return (
        <div>
            <Banner title='Blogs' page
                content='We are committed to helping your business succeed. 
                We will work with you to simplify your life & reach your goals' />
            <main className='row container py-4 mx-auto ' >
                <section className=' col-md-7 col-lg-8  ' >

                    {loading ? <div className='min-h-[60vh] flex ' >
                        <Spinner className='m-auto ' />
                    </div> : blog && <  >
                        {/* Blog Details */}
                        <p className='poppins text-slate-500 fw-medium flex items-center gap-2 ' >
                            <span className='w-[5px] rounded-full bg-violet-600 h-[5px] ' ></span>  {blog.created_at && formatDate(blog.created_at)}
                        </p>
                        <h1 className='text-slate-800 roman text-3xl sm:text-4xl lg:text-5xl my-3 ' > {blog.Main_Title} </h1>
                        <p className='text-slate-600 poppins my-3 ' dangerouslySetInnerHTML={{ __html: blog.Paragraph1 }} ></p>
                        {blog.Sub_Title && <h3 className='text-slate-800 roman text-xl sm:text-2xl my-3 lg:text-3xl ' > {blog.Sub_Title} </h3>}

                        {/* HightLight */}
                        {blog &&
                            <div className='bg-[#F1F1F1] relative p-3 py-4 rounded-xl flex gap-2 ' >
                                <span className='txtclrv ' >
                                    <DoubleQuotes size={80} />
                                </span>
                                <p className='poppins my-4 leading-[200%] text-slate-700 fw-medium ' >
                                    {blog.Highlight ? blog.Highlight :
                                        `Tosser argy-bargy mush loo at public school Elizabeth up the duff buggered chinwag on your bike mate don't
                                 get shirty with me super, Jeffrey bobby Richard cheesed off spend a penny a load of old tosh blag horse.` }
                                </p>
                                <img src={require('../Assets/shapeCurvy1.png')} alt="Shape curvt"
                                    className='absolute bottom-0 left-0 ' />
                            </div>
                        }
                        <p className='text-slate-600 poppins my-3 ' dangerouslySetInnerHTML={{ __html: blog.Paragraph2 }} ></p>
                        <img src={blog.img} alt="Blog Image"
                            className=' rounded ' />
                        {/* Text Content */}
                        {
                            blog.Text_Content && blog.Text_Content.map((obj) => (
                                <div className='my-3 ' >
                                    <h3 className='text-slate-800 roman text-xl sm:text-2xl lg:text-3xl ' > {obj.Heading} </h3>
                                    <p className='text-sm my-3 text-slate-600 poppins ' dangerouslySetInnerHTML={{ __html: obj.Paragraph }} >

                                    </p>
                                </div>
                            ))
                        }
                    </>}

                </section>
                <section className='col-md-5 sticky top-0 col-lg-4 ' >
                    <RecentBlog btoshow={3} theme />
                    {/* Services */}
                    <main className='rounded-[2rem] roman p-3 mb-4 text-slate-800  ' >
                        <h3 className='text-xl lg:text-3xl py-1 ' >Our Services  </h3>
                        <hr className='border-violet-600 opacity-100 border-4 mt-1 w-[25%] rounded mb-2 ' />
                        {
                            services && services.map((obj, index) => (
                                <div onClick={() => navigate(`/services/${obj.slug ? obj.slug : 'periodical_bas_preparations'}`)}
                                    className={` my-1 py-1 flex gap-2 items-center hover:translate-x-3 duration-500 hover:text-violet-600
                                               ${index == services.length - 1 ? '' : " "} cursor-pointer `} >
                                    <span className='txtclrv  ' >
                                        <RightArrow /> </span>   {obj.title}
                                </div>
                            ))
                        }
                    </main>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default ParticularBlog