import React, { useEffect, useState } from 'react'

const NumberCounter = ({ start, num, timing }) => {
    let [count, setCount] = useState(start ? start : 0)

    useEffect(() => {
        if (num > 0) {
            let current = start;
            const interval = setInterval(() => {
                if (current < num) {
                    setCount(current + 1); // Update count
                    current++;
                } else {
                    clearInterval(interval); // Stop the interval when count reaches num
                }
            }, timing ? timing : 50);

            return () => clearInterval(interval); // Cleanup the interval on unmount or num change
        }
    }, [num]);
    return (
        <span>{count}</span>
    )
}

export default NumberCounter