import React from 'react'

const FinancialIcon = ({ size }) => {
    return (
        <svg width={size ? size : "60"} height={size ? size : "60"} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 0C13.4575 0 0 13.4575 0 30C0 46.5425 13.4575 60 30 60C46.5425 60 60 46.5425 60 30C60 13.4575 46.5425 0 30 0ZM26.61 26.9L34.2125 28.1675C37.5675 28.725 40 31.6 40 35C40 39.135 36.635 42.5 32.5 42.5V47.5H27.5V42.5C23.365 42.5 20 39.135 20 35H25C25 36.38 26.12 37.5 27.5 37.5H32.5C33.88 37.5 35 36.38 35 35C35 34.055 34.3225 33.255 33.39 33.1L25.7875 31.8325C22.4325 31.275 20 28.4 20 25C20 20.865 23.365 17.5 27.5 17.5V12.5H32.5V17.5C36.635 17.5 40 20.865 40 25H35C35 23.6225 33.88 22.5 32.5 22.5H27.5C26.12 22.5 25 23.6225 25 25C25 25.945 25.6775 26.745 26.61 26.9Z" fill="black" />
        </svg>

    )
}

export default FinancialIcon