import React, { useEffect } from 'react'
import Banner from '../Components/Home/Banner'
import Ourservices from '../Components/Home/Ourservices'
import Footer from '../Components/Home/Footer'
import OtherServices from '../Components/About/OtherServices'

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Banner page title=" End-to-End Accounting and Financial Solutions " img2='../Assets/serviceImageBanner.png'
      content=' ' />
      <Ourservices />
      <OtherServices />
      <Footer />
    </div>
  )
}

export default Services