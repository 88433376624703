import React, { useEffect, useState } from 'react'

const EMICalculator = ({ setActiveSection }) => {
  let [mathValues, setMathValues] = useState({
    amount: 500,
    interest: 3,
    years: 3
  })
  let handleChange = (e) => {
    let { name, value } = e.target
    setMathValues((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  let [answer, setAnswer] = useState({
    monthlyEmi: 0,


  })
  useEffect(() => {
    setActiveSection('emi')
  }, [])
  return (
    <div>
      <main className='row justify-between items-center ' >
        <section className='col-lg-5 poppins fw-semibold  txtclrv ' >
          {/* Amount */}
          <article className='my-4 py-2' >
            <div className='flex justify-between ' >
              <label htmlFor="">Loan Amount </label>
              <span className=' bgclrv text-slate-200 p-1 px-2 rounded text-center min-w-[90px] text-sm ' >$ {mathValues.amount} </span>
            </div>
            <input type="range" value={mathValues.amount} className='w-full mt-4 p-0 '
              name='amount' min='0' max='100000' step='100' onChange={handleChange} />
          </article>
          {/* INterest */}
          <article className='my-4 py-2 ' >
            <div className='flex justify-between ' >
              <label htmlFor="">Rate of Interest (p.a) </label>
              <span className=' bgclrv text-slate-200 p-1 px-2 rounded text-center min-w-[90px] text-sm ' > {mathValues.interest} % </span>
            </div>
            <input type="range" value={mathValues.interest} className='w-full mt-4 p-0 '
              name='interest' min='1' max='30' onChange={handleChange} />
          </article>
          {/* Loan Tenure */}
          {/* INterest */}
          <article className='my-4 py-2 ' >
            <div className='flex justify-between ' >
              <label htmlFor="">Loan Tenure </label>
              <span className=' bgclrv text-slate-200 p-1 px-2 rounded text-center min-w-[90px] text-sm ' > {mathValues.years} yrs </span>
            </div>
            <input type="range" value={mathValues.years} className='w-full mt-4 p-0 '
              name='years' min='1' max='30' onChange={handleChange} />
          </article>
        </section>
        <section className='col-lg-6 flex flex-wrap  ' >
          {/* Card 1 */}
          <div className='w-1/2 text-center poppins my-3 ' >
            <span className='txtgrd text-3xl fw-semibold ' > $ {((Number(mathValues.amount) + 
            Number((mathValues.amount * mathValues.years * mathValues.interest) / 100) ) / (mathValues.years*12)).toFixed(2)} </span>
            <p className='poppins my-2 fw-semibold text-xl text-slate-700 ' >Monthly EMI</p>
          </div>
          <div className='w-1/2 text-center poppins my-3 ' >
            <span className='txtgrd text-3xl fw-semibold ' > $ {mathValues.amount} </span>
            <p className='poppins my-2 fw-semibold text-xl text-slate-700 ' >Principal Amount </p>
          </div>
          <div className='w-1/2 text-center poppins my-3 ' >
            <span className='txtgrd text-3xl fw-semibold ' > $ {(mathValues.amount * mathValues.years * mathValues.interest) / 100} </span>
            <p className='poppins my-2 fw-semibold text-xl text-slate-700 ' >Total Interest </p>
          </div>
          <div className='w-1/2 text-center poppins my-3 ' >
            <span className='txtgrd text-3xl fw-semibold ' > $ {Number(mathValues.amount) + 
            Number((mathValues.amount * mathValues.years * mathValues.interest) / 100) } </span>
            <p className='poppins my-2 fw-semibold text-xl text-slate-700 ' >Total Amount </p>
          </div>

        </section>
      </main>
    </div>
  )
}

export default EMICalculator