import React from 'react'
import Slider from 'react-slick';
import RatingCom from './RatingCom';

const TestimonialSection = () => {
    const settings2 = {
        dots: false,
        arrows: false,
        speed: 2000,
        centerMode: true, // Ensures the center slide is highlighted
        centerPadding: '60px', // Optional: Adjust padding for better alignment
        slidesToShow: 3,
        className: 'center',
        // afterChange: afterChangeHandler, 
        // Updates the center index after every slide
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const testimonial = [
        {
            name: 'Jeff Cremer',
            img: '../../Assets/testimonial1.png',
            position: 'Rain Forest & Starfire One LLC',
            content: `This guy is amazing. He was always available and got everything in order for me.
             I am going to be working with him on a regular basis now. GREAT ACCOUNTANT!! I RECOMMEND HIM TO EVERYONE!!!`,
        },
        {
            name: 'Kathy Nielsen',
            img: '../../Assets/testimonial2.png',
            position: 'Deadlines Inc | USA',
            content: `I am very satisfied with the outcome of this job. Chidanand is a very professional 
            accountant as well as a likeable person and I will definitely be working with him in the future for my bookkeeping needs`,
        },
        {
            name: 'Jag Karnan',
            img: '../../Assets/testimonial3.png',
            position: 'Databacx Pte Ltd | Singapore',
            content: `Great service provider, Responsive and provided more than expected`,
        },
        {
            name: 'Suniti Jain',
            img: '../../Assets/testimonial1.png',
            position: '7 Hangers Inc | USA',
            content: `We are Very are happy with the knowledge and work attitude of Chida. I will continue his accounting service.
             He was very patient and understanding of my little knowledge of accounting. I highly recommend him.`
        },
        {
            name: 'Ronmac Holdings Pty Ltd',
            img: '../../Assets/testimonial2.png',
            position: 'Australia',
            content: `Great service and good knowledge of Australian organisations. Will use again.`
        },
        {
            name: 'Srefano Giusppe',
            img: '../../Assets/testimonial3.png',
            position: 'FORMICA USA',
            content: `Excellent work; reliable, on time, and detail oriented. Thank you.`
        },
        {
            name: 'David',
            img: '../../Assets/testimonial1.png',
            position: 'PO Boys Restaurant | USA',
            content: `Excellent and quick service! Everything was delivered as promised`
        },
        {
            name: 'Kevin Kogler',
            img: '../../Assets/testimonial2.png',
            position: 'Microbizz LLC | USA',
            content: `I am very satisfied with the outcome of this job. Chidanand is a very professional 
            accountant as well as a likeable person and I will definitely be working with him in the future for my bookkeeping needs`
        },
        {
            name: 'AMTB Pty Ltd (EasyABC )',
            img: '../../Assets/testimonial3.png',
            position: 'Australia',
            content: `We had some issues and miscommunications at the beginning but we worked through it. 
            I am happy and look forward to a long term business relationship! Thanks.`
        },
        {
            name: 'Kayvon Andrew & Lily Yang Jia',
            img: '../../Assets/testimonial1.png',
            position: 'HongKong',
            content: `Again very good experience working with accountpoint`
        },
        {
            name: 'Luontolife',
            img: '../../Assets/testimonial2.png',
            position: 'Michigan',
            content: `It is been a pure pleasure to work with Accountpoint.Response time has been fast and all issues are solved professionally.I have never been a
             huge fan of accounting, but Accountpoint made it pleasurable experience.Will definitely work with them in future too.`
        },
        {
            name: 'Maja Antonic',
            img: '../../Assets/testimonial3.png',
            position: 'Camp California',
            content: `We were very happy with Accounts Point’s responsiveness, 
            speed and accuracy. They are pleasure to work with and come highly recommended.`
        },
        {
            name: 'Yee Meng Kum David Advance Management Consultant',
            img: '../../Assets/testimonial1.png',
            position: 'Singapore',
            content: `I must say that I am very pleased with Accounts Point. They are professional in their work and very fast in responding. 
            You can always catch hold of them online. I highly recommend that you consider them for your account/finance work.`
        },
    ];
    return (
        <div className='py-4  poppins' >
            <h2 className=' p-2 px-3 bg-[#b8cdf6] flex gap-2 items-center txtclrac w-fit rounded-full fw-semibold mx-auto ' >
                <p className='w-[0.4rem] h-[0.4rem] bgclrac rounded-full ' ></p> Testimonials </h2>
            <h4 className=' text-center my-3 text-4xl fw-semibold ' >
                What Our Clients Have to Say </h4>
            <p className=' my-1 text-lg fw-medium text-center ' > Trusted by 500+ Clients Across the USA, Australia, UK & More! </p>
            <p className=' w-[90%] mx-auto sm:w-[70%] lg:w-[60%] text-slate-700 text-center my-2 text-sm' >
                At Accounts Point, we take pride in delivering high-quality, cost-effective
                accounting solutions that help businesses scale effortlessly. Here’s what our satisfied clients have to say:
            </p>
            <div className=" " >
                <Slider {...settings2} className=" py-3 ">
                    {
                        testimonial.map((obj) => (
                            <div className=' poppins min-h-[50vh] ' >
                                <div className='shadow min-h-[50vh] rounded text-center my-3 p-3 ' >
                                    <img src={obj.img} alt="Testimonial Image"
                                        className=' mx-auto w-[130px] sm:w-[180px] ' />
                                    {/* Stars */}
                                    <div className='mx-auto my-2 flex justify-center ' >
                                        <RatingCom num={5} />
                                    </div>
                                    <h6 className=' text-xl fw-semibold ' > {obj.name} </h6>
                                    <p className='text-sm my-2 text-slate-700 ' >
                                        {obj.content}
                                    </p>
                                    <p className='txtclrac amita my-2 ' >
                                        {obj.position}
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            </div>
        </div>
    )
}

export default TestimonialSection