import React from 'react'
import BigRing from '../../Icons/BigRing'
import { useNavigate } from 'react-router'
import LocationIcon from '../../Icons/LocationIcon'
import MailIcon from '../../Icons/MailIcon'
import CallIcon from '../../Icons/CallIcon'
import FacebookIcon from '../../Icons/FacebookIcon'
import InstagramIcon from '../../Icons/InstagramIcon'
import LinkedInIcon from '../../Icons/LinkedInIcon'

const Footer = ({ dark }) => {
    let navigate = useNavigate()
    return (
        <div className={`${dark ? '' : "bgclr"} min-h-[40vh] flex overflow-hidden relative`} >
            <div className='absolute bottom-0 left-0 translate-y-1/2 z-0 -translate-x-1/2 ' >
                <BigRing size={300} border={60} opacity={30} />
            </div>
            <main className={` relative fw-semibold tracking-wide py-3 z-10 m-auto roman 
            ${dark ? '' : "text-slate-200"} row items-start container mx-auto `} >
                <section className='col-6 col-md-3 my-2 ' >
                    <h5 className=' text-2xl sm:text-3xl' >Company </h5>
                    <button onClick={() => navigate('/')} className='my-3 sm:text-xl block ' >Home</button>
                    <button onClick={() => navigate('/about')} className='my-3 sm:text-xl block ' >About Us</button>
                    <button onClick={() => navigate('/career')} className='my-3 sm:text-xl block ' >Career</button>
                </section>
                <section className='col-6 col-md-3 my-2 ' >
                    <h5 className=' text-2xl sm:text-3xl' >Services </h5>
                    <button onClick={() => navigate('/services')} className='my-3 sm:text-xl block ' >Our Services</button>
                    <button onClick={() => navigate('/why-choose-us')} className='my-3 sm:text-xl block ' > Why Choose US </button>
                </section>
                <section className='col-6 col-md-3 my-2 ' >
                    <h5 className=' text-2xl sm:text-3xl' >Resources </h5>
                    <button onClick={() => navigate('/blogs')} className='my-3 sm:text-xl block ' >Blog</button>
                    <button onClick={() => navigate('/contact-us')} className='my-3 sm:text-xl block ' >Contact Us</button>
                </section>
                <section className=' col-md-3 my-2 ' >
                    <h5 className=' text-2xl sm:text-3xl' >Information </h5>
                    <div className='flex gap-2 my-2 items-start poppins fw-light text-sm ' >
                        <span className='mt-1  ' ><LocationIcon /></span>
                        <p className='' >
                            Accounts Point, Mahalakshmipuram, 14th Main Rd, near Shankar Mutt Rd, Bengaluru, Karnataka, India
                        </p>
                    </div>
                    <div className='flex gap-2 my-3 items-start poppins fw-light text-sm ' >
                        <span className='mt-1  ' ><MailIcon /></span>
                        <a href='mailto:info@accountspoint.com' className={` ${dark ? '' : 'text-slate-200 text-decoration-none'} `} >
                            info@accountspoint.com
                        </a>
                    </div>
                    <div className='flex gap-2 my-3 items-start poppins fw-light text-sm ' >
                        <span className='mt-1  ' ><CallIcon /></span>
                        <div>

                            <a href="tel:+919980331886" className={` block mb-2 ${dark ? '' : 'text-slate-200 text-decoration-none'} `}> +91-99-803-31886 </a>
                            <a href="tel:+61390137786" className={` ${dark ? '' : 'text-slate-200 text-decoration-none'} `} > +61-39-013-7786 </a>
                        </div>

                    </div>
                </section>
                <hr />
                <div className='flex items-center justify-between py-3 gap-3 flex-wrap ' >
                    <section className=' flex items-center gap-3 ' >
                        <a href="https://www.facebook.com/people/Accounts-Point/100075747411020/" target='_blank'
                            className=' ' >
                            <span className=' '>   <FacebookIcon size={20} /></span>
                        </a>
                        <a href="https://www.instagram.com/accountspoint_/" target='_blank'
                            className=' ' >
                            <span className=' '>  <InstagramIcon size={20} /></span>
                        </a>
                        <a href="https://www.linkedin.com/company/accounts-point/" target='_blank'
                            className=' ' >
                            <span className=' '>    <LinkedInIcon size={20} /></span>
                        </a>
                    </section>
                    <section>
                        <a href="" className=' text-decoration-none ' >
                            Accounts Point © 2025
                        </a>
                    </section>
                </div>
            </main>
        </div>
    )
}

export default Footer