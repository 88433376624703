import React from 'react'

const ShakyColor = () => {
    return (
        <svg width="146" height="290" viewBox="0 0 146 290" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M142.558 31.2352C145.312 25.0455 146 7.64163 146 0H0V290C5.16306 285.415 6.59725 283.982 10.3261 276.532C14.9155 270.8 18.9312 256.472 20.3654 249.022C22.2012 245.812 24.3811 230.682 25.2416 223.518L31.5521 191.136C33.0819 184.832 36.7151 170.447 39.0098 163.34C41.8782 154.457 47.6149 144.427 49.0491 140.128C50.4833 135.83 59.6621 125.227 62.5304 121.502C63.6778 118.923 79.4538 105.741 82.0354 104.022C87.1984 101.443 94.943 95.1383 97.2377 93.7055C102.114 91.413 111.293 80.2371 116.456 75.9387C121.619 71.6403 125.921 64.1897 127.642 62.1838C129.363 60.1779 133.666 52.4407 134.813 50.4348C135.961 48.4289 139.69 40.6917 140.263 38.9723C140.55 37.8261 141.984 33.5277 142.558 31.2352Z" fill="url(#paint0_linear_14_40)" />
            <defs>
                <linearGradient id="paint0_linear_14_40" x1="73" y1="0" x2="73" y2="290" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C420FD" />
                    <stop offset="1" stop-color="#9304F8" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default ShakyColor