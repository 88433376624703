import React from 'react'
import { Modal } from 'react-bootstrap'
import CloseButtonIcon from '../Icons/CloseButtonIcon'

const VideoPlayer = ({ show, setShow }) => {
    return (
        <div>
            <Modal centered size='xl' className='bg-violet-600 bg-opacity-45 ' show={show} onHide={() => setShow(false)} >
                <button onClick={() => setShow(false)} className=' absolute w-fit -right-12 bg-slate-900 p-[10px] text-violet-400 bg-opacity-70 rounded-full ' >
                    <CloseButtonIcon size={17} />
                </button>
                <Modal.Body className='p-0 ' >
                    <video src={require('../Assets/bannerVideo.mp4')} className='rounded ' autoPlay controls ></video>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default VideoPlayer