import React, { useEffect } from 'react'
import Banner from '../Components/Home/Banner'
import Footer from '../Components/Home/Footer'
import ApplyJob from '../Components/ApplyJob'

const CareerPage = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  return (
    <div>
      <Banner title='Build Your Career with Accounts Point' page
        content={`We value fresh ideas and passion. Step into a positive workplace that nurtures 
        talent and rewards effort. Join our team and grow your skills in a supportive space.`} />
      {/* Why choose us */}
      <main className=' min-h-[40vh] py-3 flex ' >
        <section className='container mx-auto m-auto ' >
          <h2 className='text-4xl text-center sm:text-6xl roman fw-semibold ' >Perks of Working with Us</h2>
          <p className='my-3 text-center poppins w-[90%] sm:w-[80%] mx-auto text-slate-500 leading-[190%] ' >
            At Accounts Point, we believe in creating a happy and supportive workplace where everyone thrives. Enjoy a flexible work environment, continuous learning opportunities, and recognition for your hard work. With us, you’ll find a team that values your growth, supports your goals, and celebrates every success together.
            Join us and build a career you’ll truly be proud of!
          </p>
        </section>
      </main>
      {/* Galary */}
      <main className='row container-fluid px-0 mx-0 ' >
        <section className='col-md-3 my-2 ps-sm-0' >
          <img src={require('../Assets/career1.png')} alt="Career1" className='rounded  w-full md:h-[100%] object-cover ' />
        </section>
        <section className='col-md-3 my-2 flex flex-col justify-between ' >
          <img src={require('../Assets/career2.png')} alt="Career1" className='rounded  w-full md:h-[48%] object-cover ' />
          <img src={require('../Assets/career3.png')} alt="Career1" className='rounded  w-full mt-3 md:h-[48%] object-cover' />
        </section>
        <section className='col-md-3 my-2' >
          <img src={require('../Assets/career4.png')} alt="Career1" className='rounded w-full md:h-[100%] object-cover ' />
        </section>
        <section className='col-md-3 my-2 pe-sm-0 flex flex-col justify-between ' >
          <img src={require('../Assets/career5.png')} alt="Career1" className='rounded w-full ' />
          <img src={require('../Assets/career6.png')} alt="Career1" className='rounded mt-3 ' />
        </section>
      </main>
      {/* Apply Job */}
      <ApplyJob />
      <Footer />
    </div>
  )
}

export default CareerPage