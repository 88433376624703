import React from 'react'
import { useNavigate } from 'react-router'

const WhyChooseUs = () => {
    let navigate = useNavigate()
    return (
        <div>
            <section className='col-10 min-h-[40vh] p-3 relative row mx-auto rounded bg-white shadow lg:translate-y-1/3 ' >
                <article className='col-lg-6 ' >
                    <h5 className=' txtclrac text-3xl sm:text-4xl tracking-wide w-[90%] italic fw-semibold ' >
                        Want to Be Our Next Success Story?
                    </h5>
                    <p className='text-slate-700 my-3 ' >
                        Join accounts point and take control of your finances while saving up to 50% on accounting costs.
                        Hundreds of businesses have streamlined their accounting with us—now it's your turn!
                    </p>
                    <p className='amita ' >
                        Join <span className=' txtclrac ' > 500+  </span>happy clients & experience hassle-free accounting today!
                    </p>
                    <button onClick={() =>
                        document.getElementById('getfreeQuote').scrollIntoView({ behavior: 'smooth', block: 'center' })} className=' p-2 px-3 rounded bgclrac text-white my-3 mt-4 ' >
                        Get Free Quote
                    </button>

                </article>
                <img src={require('../../Assets/personChoose.png')} alt="Person People"
                    className=' absolute right-0 bottom-0 w-[25rem] d-none d-lg-block ' />
            </section>
            <main className='bg-[#001F5C] min-h-[80vh] flex flex-col ' >
                <div className='min-h-[20vh] ' >

                </div>
                <section className='  lg:mt-auto col-12 col-sm-11 row mx-auto  ' >
                    <div className='col-lg-6 my-3 ' >
                        <img src={require('../../Assets/whyChoose.png')} alt="WhyChoose"
                            className='' />
                    </div>
                    <div className='col-lg-6 my-3 poppins' >
                        <h2 className=' p-2 px-3  flex gap-2 items-center bg-slate-100 bg-opacity-50 text-white w-fit rounded-full fw-semibold ' >
                            <p className='w-[0.4rem] h-[0.4rem] bg-white rounded-full ' ></p> Why Choose Us </h2>
                        <h6 className='text-4xl text-slate-50 my-4 fw-semibold tracking-wide w-[80%] ' >  Why Global Businesses Trust Us? </h6>
                        <p className=' text-slate-200 text-sm my-3 ' >
                            💼 Certified Accountants & Tax Experts – Experienced professionals handling global accounting standards </p>
                        <p className=' text-slate-200 text-sm my-3 ' >
                            💰 Cost Savings of up to 50% – Reduce operational expenses with our offshore team </p>
                        <p className=' text-slate-200 text-sm my-3 ' >
                            🔒 100% Data Security & Confidentiality – Advanced security protocols & encrypted data handling </p>
                        <p className=' text-slate-200 text-sm my-3 ' >
                            ⏳ Time-Zone Flexibility – Round-the-clock support for seamless collaboration </p>
                        <p className='amita text-4xl my-3 text-slate-50 ' >
                            Start Outsourcing Today & Scale Your Business Hassle-Free!
                        </p>
                        <button onClick={() =>
                            document.getElementById('getfreeQuote').scrollIntoView({ behavior: 'smooth', block: 'center' })}
                            className='bg-slate-50 px-3 p-2 rounded txtclrac my-4 fw-medium ' >
                            Get Free Quote
                        </button>
                    </div>
                </section>

            </main>
        </div>
    )
}

export default WhyChooseUs