import React from 'react'

const PayrollIcon = ({ size }) => {
    return (
        <svg width={size ? size : "60"} height={size ? size : "60"} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 22.5C19.645 22.5 11.25 30.895 11.25 41.25C11.25 51.605 19.645 60 30 60C40.355 60 48.75 51.605 48.75 41.25C48.75 30.895 40.355 22.5 30 22.5ZM33.44 52.5H32.5C32.5 53.88 31.38 55 30 55C28.62 55 27.5 53.88 27.5 52.5C24.68 52.5 22.22 50.935 20.9375 48.63C20.0325 47.0025 21.32 45 23.18 45C24.035 45 24.885 45.405 25.285 46.1625C25.705 46.9575 26.54 47.5 27.4975 47.5H33.4375C34.2975 47.5 34.9975 46.8 34.9975 45.94C34.9975 45.1725 34.4475 44.5275 33.695 44.4L25.7525 43.075C23.0175 42.62 20.6725 40.615 20.1325 37.895C19.2975 33.695 22.505 29.9975 26.56 29.9975H27.5C27.5 28.6175 28.62 27.4975 30 27.4975C31.38 27.4975 32.5 28.6175 32.5 29.9975C35.32 29.9975 37.78 31.5625 39.0625 33.8675C39.9675 35.495 38.68 37.4975 36.82 37.4975C35.965 37.4975 35.115 37.0925 34.715 36.335C34.295 35.54 33.4625 34.9975 32.5025 34.9975H26.5625C25.7025 34.9975 25.0025 35.6975 25.0025 36.5575C25.0025 37.325 25.55 37.97 26.305 38.0975L34.25 39.4225C36.985 39.8775 39.3275 41.8825 39.8675 44.6025C40.7025 48.8025 37.495 52.5 33.44 52.5ZM60 18.75V47.135C60 50.49 58.78 53.7225 56.56 56.2325C55.8175 57.07 54.785 57.5 53.7475 57.5C52.865 57.5 51.98 57.19 51.2675 56.56C49.715 55.19 49.5675 52.8175 50.94 51.2675C51.945 50.1275 52.5 48.6625 52.5 47.1375V25.0025H47.2275C42.9025 20.4075 36.81 17.5025 30 17.5025C23.19 17.5025 17.0825 20.405 12.76 25.0025H7.5V47.1375C7.5 48.66 8.055 50.1275 9.0625 51.27C10.4325 52.8225 10.285 55.1925 8.73 56.5625C8.0175 57.19 7.1325 57.5 6.25 57.5C5.21 57.5 4.1775 57.0725 3.4375 56.23C1.22 53.7175 0 50.4875 0 47.135V18.75C0 11.155 6.155 5 13.75 5H15V3.75C15 1.6775 16.6775 0 18.75 0C20.8225 0 22.5 1.6775 22.5 3.75V5H37.5V3.75C37.5 1.6775 39.18 0 41.25 0C43.32 0 45 1.6775 45 3.75V5H46.25C53.845 5 60 11.155 60 18.75Z" fill="#282828" />
        </svg>
    )
}

export default PayrollIcon