import React from 'react'
import GetInTouch from '../GetInTouch'
import GetQuoteForm from './GetQuoteForm'

const ContactSection = () => {
    return (
        <div className='py-4'>
            <main className='col-11 row mx-auto poppins ' >
                <section className='col-lg-6 my-3 flex flex-col gap-4' >
                    <div>
                        <p className='poppins text-4xl fw-light txtclrac' >
                            <span className='fw-semibold text-slate-900 ' >    Save Up to 50% </span>On Accounting Costs & Focus On Growing Your Business!
                        </p>
                        <p className=' my-3 ' >
                            Say goodbye to high accounting fees and manual bookkeeping.
                            Our expert accounting solutions help businesses like yours cut costs by up to 50%—so you can invest more in growth.
                        </p>
                    </div>
                    <p className='fw-light text-4xl lg:text-5xl my-3 ' >
                        Let’s Discuss Your Requirements <span className='txtclrac fw-semibold ' >
                            – Get a Free Consultation!  </span>
                    </p>
                </section>
                <section className='col-lg-6 my-3 ' >
                    <div id='getfreeQuote' className='sm:w-[80%] mx-auto ' >
                        <GetQuoteForm />
                        {/* <GetInTouch mainCss='text-slate-800 shadow rounded mx-auto ' name='Contact Us Today' /> */}
                    </div>
                </section>
            </main>
        </div>
    )
}

export default ContactSection