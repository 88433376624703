
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import './Css/background.css'
import './Css/font.css'
import { BrowserRouter, Route, Routes } from 'react-router';
import HomePage from './Pages/HomePage';
import Services from './Pages/Services';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutPage from './Pages/AboutPage';
import HowWeWork from './Pages/HowWeWork';
import CareerPage from './Pages/CareerPage';
import ContactPage from './Pages/ContactPage';
import ResourcePage from './Pages/ResourcePage';
import { ToastContainer } from 'react-toastify';
import ParticularServicePage from './Pages/ParticularServicePage';
import AllBlogs from './Pages/AllBlogs';
import ParticularBlog from './Pages/ParticularBlog';
import './Css/slider.css'
import AccountingAndBookKeeping from './Pages/AccountingAndBookKeeping';
import './Css/accountingAndBookkeepingLanding.css'
import ThankYouPage from './Components/AccountsBookkeeping/ThankYouPage';
import SocialTag from './Components/AccountsBookkeeping/SocialTag';
import './Css/carousel.css'
import './Css/pagination.css'

export const domain = 'https://www.fortunetradingacademy.com/'
export const port = 'https://backendapi.skilllearningacademy.com/'
// export const port = 'http://192.168.18.34:7000/'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <SocialTag css='text-violet-600 bg-slate-50  ' />
        <ToastContainer autoClose={3000} position='top-center' />
        <Routes>
          <Route path='/*' element={<HomePage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/services/*' element={<Services />} />
          <Route path='/services/:slug' element={<ParticularServicePage />} />
          <Route path='/why-choose-us' element={<HowWeWork />} />
          <Route path='/career' element={<CareerPage />} />
          <Route path='/contact-us' element={<ContactPage />} />
          <Route path='/resources/*' element={<ResourcePage />} />
          <Route path='/blogs/*' element={<AllBlogs />} />
          <Route path='/blogs/:slug' element={<ParticularBlog />} />
          <Route path='/accounting-bookkeeping-service' element={<AccountingAndBookKeeping />} />
          <Route path='/thankYou' element={<ThankYouPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
