import React from 'react'
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'

const NavBarCom = () => {
    let expand = 'lg'
    return (
        <div className=' ' >
            <Navbar key={expand} expand={expand} className={` mb-3`}>
                <Container fluid className='p-3 px-4 ' >
                    <Navbar.Brand href="/">
                        <img src={require('../Assets/accountsPointWhiteLogo.png')} alt="WhiteLogo" width={140} />
                    </Navbar.Brand>
                    <Navbar.Toggle className="custom-toggle"
                        aria-controls={`offcanvasNavbar-expand-${expand}`} />
                    <Navbar.Offcanvas
                        className=' bgclr '
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton className="custom-close  " >
                            <Offcanvas.Title className='text-slate-200' id={`offcanvasNavbarLabel-expand-${expand}`}>
                                <img src={require('../Assets/accountsPointWhiteLogo.png')} alt="AccountPointLogo"
                                    width={140} className=' ' />
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end poppins sm:items-center gap-lg-4 flex-grow-1 pe-3">
                                <Nav.Link href="/">
                                    <button className=' text-slate-200 fw-medium my-2 my-lg-0 ' >
                                        Home </button></Nav.Link>
                                <Nav.Link href="/about">
                                    <button className=' text-slate-200 fw-medium my-2 my-lg-0 ' >
                                        About Us
                                    </button></Nav.Link>
                                <Nav.Link href="/why-choose-us"> <button className=' text-slate-200 fw-medium my-2 my-lg-0 ' >
                                    Why Choose Us
                                </button></Nav.Link>
                                <Nav.Link href="/services">
                                    <button className=' text-slate-200 fw-medium my-2 my-lg-0 ' >
                                        Service
                                    </button></Nav.Link>
                                <Nav.Link href="/resources"> <button className=' text-slate-200 fw-medium my-2 my-lg-0 ' >
                                    Resources
                                </button></Nav.Link>
                                <Nav.Link href="/career"> <button className=' text-slate-200 fw-medium my-2 my-lg-0 ' >
                                    Careers
                                </button></Nav.Link>
                                <Nav.Link href="/blogs"> <button className=' text-slate-200 fw-medium my-2 my-lg-0 ' >
                                    Blogs
                                </button></Nav.Link>
                                <Nav.Link
                                    href="/contact-us">
                                    <button className=' text-slate-200 fw-medium my-2 my-lg-0
                                 lg:text-violet-600 lg:bg-slate-200 fw-medium rounded-xl p-lg-2' >
                                        Contact Us
                                    </button>
                                </Nav.Link>

                            </Nav>

                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </div>
    )
}

export default NavBarCom