import axios from 'axios'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { port } from '../../App'
import { useNavigate } from 'react-router'
import CloseButtonIcon from '../../Icons/CloseButtonIcon'

const GetQuoteForm = ({ maincss, page }) => {
    let [formData, setFormData] = useState({
        first_name: '',
        phone: '',
        email: '',
        message: '',
        company: '',
        services: [],
    })
    let handleChange = (e) => {
        let { name, value } = e.target
        if (name == 'services') {
            if (formData.services.indexOf(value) != -1)
                return
            setFormData((prev) => ({
                ...prev,
                [name]: [prev.services, value]?.flat()
            }))
            return
        }
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let inputCredentials = {
        first_name: { label: 'Name', type: 'text', },
        phone: { label: 'Phone', type: 'text' },
        email: { label: 'Email', type: 'text' },
        company: { label: 'Company', type: 'text' },
        services: {
            label: 'Business Type', type: 'search',
            options: [
                { value: 'Accounting', label: 'Accounting and Bookkeeping' },
                { value: 'Taxation', label: 'Taxation and Compliance' },
                { value: 'Payroll', label: 'Payroll processing' },
                { value: 'Invoice', label: 'Invoice and Expense' },
                { value: 'Financial', label: 'Financial reporting' },
            ]
        },
        message: { label: 'Comment', type: 'textarea' },
    }
    let navigate = useNavigate()
    let submitForm = () => {
        if (formData.email != '' && formData.first_name != '' && formData.phone != '') {
            axios.post(`${port}/blog/ContactsInformation/`, formData).then((response) => {
                toast.success('Form Submitted')
                setFormData({
                    first_name: '',
                    phone: '',
                    email: '',
                    message: '',
                    company: '',
                    services: [],
                })
            }).catch((error) => {
                console.log(error);
                toast.error('Error occured')
            })
        }
        else
            toast.warning('Fill all the fields')
    }
    return (
        <div>
            <main className={` ${page ? '' : 'shadow'} rounded-xl p-3 `} >
                <h5 className='text-center poppins text-3xl fw-semibold  ' > Contact Us Today </h5>
                {
                    Object.keys(inputCredentials).map((field) => {
                        let input = inputCredentials[field]
                        return (
                            <div className={` my-4 flex items-center justify-between `} >
                                <label htmlFor="" className='w-[30%] ' >{input?.label} </label>
                                <div className='w-[65%] ' >
                                    {input?.type == 'search' ?
                                        <div className={`w-full p-2 rounded border-[1.5px] outline-none bg-transparent ${page ? ' border-slate-50 ' : "border-slate-800"} `} >
                                            <section className='flex gap-2 flex-wrap text-sm ' >

                                                {formData?.services?.map((val) => (
                                                    <div className='p-1 text-white rounded bgclrac gap-3 flex items-center' >
                                                        {val}
                                                        <button onClick={() => setFormData((prev) => ({
                                                            ...prev,
                                                            services: prev.services?.filter((value) => value != val)
                                                        }))} className='bg-white txtclrac rounded-full p-[3px] ' >
                                                            <CloseButtonIcon size={7} />
                                                        </button>
                                                    </div>
                                                ))}
                                            </section>
                                            {/* Select options */}
                                            <select name={field} onChange={handleChange} value='' id="" className='outline-none bg-transparent w-full ' >
                                                <option value="">Select</option>
                                                {
                                                    input.options?.map((obj) => (
                                                        <option value={obj.value}> {obj.label} </option>
                                                    ))
                                                }
                                            </select>
                                        </div> : input?.type == 'textarea' ?
                                            <textarea id="" value={formData[field]} name={field}
                                                onChange={handleChange} className={` w-full p-2 rounded border-[1.5px] outline-none bg-transparent ${page ? ' border-slate-50 ' : "border-slate-800"} `}></textarea> :
                                            <input type={input?.type ? input.type : "text"} value={formData[field]} name={field}
                                                onChange={handleChange} className={` w-full p-2 rounded border-[1.5px] outline-none bg-transparent ${page ? ' border-slate-50 ' : "border-slate-800"} `} />}
                                </div>
                            </div>
                        )
                    })
                }
                <button onClick={() => navigate('/thankYou')} className='bgclrac p-2 px-3 rounded text-slate-50 ms-auto w-[65%] flex text-center ' >
                    <span className='text-center w-full ' >
                        {page ? 'Submit' : " Get Free Quote"}
                    </span>
                </button>
            </main>
        </div>
    )
}

export default GetQuoteForm