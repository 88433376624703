import React from 'react'

const SoftwareWeUse = () => {
    return (
        <div className='relative overflow-hidden' >
            <img src={require('../../Assets/design1.png')} alt="Design" className='min-h-[140vh] absolute z-0 w-[120%] ' />
            <p className='h-[10vh] ' > </p>
            <main className='relative z-1 min-h-[60vh] py-4 mx-3 bg-violet-50 rounded-[2rem] ' >
                <article className='row container mx-auto min-h-[60vh] items-center' >
                    <section className='col-lg-6 m-auto ' >
                        <h3 className=' text-4xl sm:text-5xl fw-semibold roman ' >Financial Management Powered by Advanced Tools </h3>
                        <p className='text-slate-500 poppins my-4 fw-medium ' >
                            From bookkeeping to reports, our tech stack ensures every task is
                            handled smoothly and with utmost accuracy for your business.
                        </p>
                    </section>
                    <section className='col-lg-6 ' >
                        <img src={require('../../Assets/company1.png')} alt="Company Image" />
                    </section>

                </article>
            </main>
            <p className='h-[10vh] sm:h-[27vh] ' ></p>
            <div className='absolute w-[400px] -translate-y-1/2 -translate-x-1/2 top-1/2 left-1/2  h-[400px] rounded-full border-[80px] border-violet-600 border-opacity-10 ' >

            </div>
            <main className='relative z-1 min-h-[55vh] sm:min-h-[60vh] py-4 mx-3 bg-violet-50 rounded-[2rem] ' >
                <article className='row container mx-auto min-h-[55vh] sm:min-h-[60vh] items-center' >

                    <section className='col-lg-6 ' >
                        <img src={require('../../Assets/company2.png')} alt="Company Image" />

                    </section>
                    <section className='col-lg-6 m-auto ' >
                        <h3 className=' text-4xl sm:text-5xl fw-semibold roman sm:text-end ' >Collaboration with Industry-Leading Companies  </h3>
                        <p className='text-slate-500 poppins my-4 fw-medium sm:text-end ' >
                        Our journey includes working with top companies, a testament to the trust and quality we bring to every collaboration.
                        </p>
                    </section>
                </article>
            </main>
        </div>
    )
}

export default SoftwareWeUse