import React, { useContext, useEffect, useState } from 'react'
import { port } from '../../App'
import { AppStore } from '../../Context/AppContext'
import Slider from 'react-slick'
import { useNavigate } from 'react-router'

const RecentBlog = ({ btoshow, theme }) => {
    let { allblog, getBlog } = useContext(AppStore)
    useEffect(() => {
        if (!allblog)
            getBlog()
    }, [])
    const settings2 = {
        dots: false,
        arrows: false,
        speed: 2000,
        centerMode: true, // Ensures the center slide is highlighted
        centerPadding: '0px', // Optional: Adjust padding for better alignment
        slidesToShow: btoshow ? btoshow : 2,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        vertical: true,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: btoshow ? btoshow : 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: btoshow ? btoshow : 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: btoshow ? btoshow : 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    let navigate = useNavigate()
    return (
        <div className=' my-4 ' >
            <main className={` ${theme ? theme : "worldbg text-slate-200 "} rounded-[2rem] p-3  `} >
                <h3 className='text-3xl lg:text-4xl text-center roman fw-semibold ' >Recent Blogs </h3>
                <Slider className='py-3 ' {...settings2} >
                    {
                        allblog && allblog.slice(0, 3).map((obj) => (
                            <div onClick={() => navigate(`/blogs/${obj.slug}`)} className=' cursor-pointer ' >
                                <section className='flex gap-2 items-center ' >
                                    <div className='my-2 w-1/2 ' >
                                        <img src={obj.img} alt="Blog Image"
                                            className=' rounded ' />
                                    </div>
                                    <div className=' w-1/2 ' >
                                        <h6 className='roman text-md line-clamp-1' > {obj.Main_Title} </h6>
                                        <p className='line-clamp-2 text-slate-300 poppins text-xs ' >
                                            {obj.Paragraph1}
                                        </p>
                                    </div>
                                </section>
                            </div>
                        ))}
                </Slider>
            </main>

        </div>
    )
}

export default RecentBlog