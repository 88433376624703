import React, { useContext, useEffect, useRef, useState } from 'react'
import { AppStore } from '../../Context/AppContext'
import NavBarCom from '../NavBarCom';
import TriangleIcon from '../../Icons/TriangleIcon';
import GetInTouch from '../GetInTouch';
import NumberCounter from '../NumberCounter';
import VideoPlayer from '../VideoPlayer';
import { useNavigate } from 'react-router';

const Banner = ({ title, content, page, img, img2 }) => {
    let { homeBanner } = useContext(AppStore)
    let [show, setShow] = useState()
    const [iconPosition, setIconPosition] = useState({ x: 20, y: 20 }); // Initial position
    const handleMouseMove = (e) => {
        setIconPosition({ x: 50, y: 50 })
        const mouseX = e.clientX;
        const mouseY = e.clientY;

        const dx = mouseX;
        const dy = mouseY;

        const distance = Math.sqrt(dx * dx + dy * dy);
        const angle = Math.atan2(dy, dx);

        const offsetX = 20 * Math.cos(angle);
        const offsetY = 20 * Math.sin(angle);

        setIconPosition({ x: offsetX, y: offsetY }); // Updating only transform values
    };
    let navigate = useNavigate()
    useEffect(() => {

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);
    return (
        <>

            <div className={`relative bggrd3 overflow-hidden ${page?'min-h-[100vh] ': "min-h-[90vh]"} `} >
                {img &&
                    <img src={img ? img : homeBanner} alt="Banner Image"
                        className={`w-full right-0 absolute h-full z-0 object-cover ${page?'min-h-[100vh] ': "min-h-[90vh]"}`} />
                }
                {img2 &&
                    <img src={img2 ? img2 : homeBanner} alt="Banner Image"
                        className={` col-6 right-0 absolute h-full z-0 object-cover ${page?'min-h-[100vh] ': "min-h-[90vh]"}`} />
                }
                {img2 && <div className={` col-6 right-0 absolute h-full z-0 bg-slate-900 bg-opacity-30 object-cover ${page?'min-h-[100vh] ': "min-h-[90vh]"} `} >

                </div>}
                <NavBarCom />
                <VideoPlayer show={show} setShow={setShow} />
                <main
                    onMouseMove={handleMouseMove}
                    className={`${page ? 'items-center ' : ''} mx-auto text-slate-200 min-h-[70vh] order-2 order-sm-1 row relative  container `} >
                    <section className=' col-lg-7 pb-4 order-2 order-sm-1 flex flex-col   ' >
                        {!page &&
                            <article className='d-none d-lg-block min-h-[15vh] py-3 w-fit relative items-center text-slate-50 flex gap-[18px] ' >
                                <div onClick={() => setShow(true)} className='dropani  bg-slate-50 bg-opacity-40 rounded-full ' >
                                    <button className='flex txtclrac bg-slate-50 w-[3rem] h-[3rem]  rounded-full  items-center justify-center ' >
                                        <TriangleIcon w={40} />
                                    </button>
                                </div>
                                {/* <p className='mb-0 fw-light ' > Watch Video </p> */}
                            </article>}
                        {title ? <h1 className='roman text-5xl sm:text-6xl ' > {title} </h1>
                            : <h1 className='roman text-5xl sm:text-6xl ' > Expert Accounting &
                                Financial Services for Your Business </h1>}
                        <p className=' my-4 poppins sm:w-[75%] ' >
                            {content ? content : `  Your dependable accounting & financial services partner.
                            Allow our professional services to assist you in achieving your financial objectives.`}
                        </p>
                        {!page &&
                            <div className='flex gap-3 items-center ' >

                                {/* Price */}
                                {/* <section className='flex h-fit ' >
                                    <div className='border-2 border-slate-100 p-2 text-center ' >
                                        <h6 className='fw-semibold tracking-wider poppins ' > $10/hr </h6>
                                        <p className='mb-0 text-sm my-2 fw-medium ' >Bookkeeping Services </p>
                                    </div>
                                    <div className='border-[2px] border-slate-100 border-s-0 p-2 text-center ' >
                                        <h6 className='fw-semibold tracking-wider poppins  ' > $15/hr </h6>
                                        <p className='mb-0 text-sm my-2 fw-medium ' >Tax Return Services </p>
                                    </div>
                                </section> */}
                                < button onClick={() => navigate('/contact-us')} className=' border-slate-200 border-2 rounded 
                                            text-violet-600 bg-slate-100 p-2  poppins fw-semibold h-fit ' >
                                    Get In Touch
                                </button>
                            </div>
                        }
                    </section>
                    <section className='col-lg-5 order-1 order-sm-2 items-center ' >
                        {!page && <div className='w-full h-full ' >
                            <img src={require('../../Assets/HomeBannerImage.png')} alt="HomeBanner"
                                className=' h-full lg:absolute object-contain mt-auto flex ' />
                        </div>}
                        {page && <GetInTouch mainCss=' bg-slate-50 bg-opacity-10 backdrop-blur ' />}
                        {!page && <div className='  w-[2rem] h-[2rem] border-2 border-violet-600 rounded-sm
                         rotatespin absolute top-[10%] right-[3%] ' >   </div>}

                        {!page && <div
                            style={{
                                transform: `translate(${iconPosition.x}px, ${iconPosition.y}px)`,
                            }}
                            className={`absolute bottom-[10%] left-[30%] sm:bottom-[20%] sm:left-[50%] text-orange-600 duration-500`} >
                            <TriangleIcon w={20} />
                        </div>}
                    </section>
                </main>
                {/* Hollow circle */}
                {/* <div className='absolute bottom-0 right-32 translate-y-1/2 
            flex items-center justify-center w-[400px] h-[400px]  bg-transparent rounded-full border-[50px] 
         border-violet-600/20 ' >
                    
                </div> */}
                {/* Statistic box */}

            </div >
            {!page &&
                <main className='max-w-[90vw] container mx-auto min-h-[14vh] text-slate-200
            p-3 rounded-xl bggrd2 shadow lg:-translate-y-1/2 relative roman text-center flex flex-wrap ' >
                    <div className=' flex flex-col items-center justify-center fw-semibold  w-[50%] my-2 my-sm-0 sm:w-[25%] lg:w-[20%] border-r-[1px] border-opacity-50 ' >
                        <p className='text-3xl sm:text-4xl tracking-wider ' > <NumberCounter start={192} num={200} timing={93} /> +</p>
                        <p className='text-xl sm:text-xl fw-normal ' >Happy Clients </p>
                    </div>
                    <div className=' flex flex-col items-center justify-center fw-semibold w-[50%] my-2 my-sm-0 sm:w-[25%] lg:w-[20%] sm:border-r-[1px] border-opacity-50 ' >
                        <p className='text-3xl sm:text-4xl tracking-wider ' > <NumberCounter start={492} num={500} timing={93} /> +</p>
                        <p className='text-xl sm:text-xl fw-normal ' > Projects Delivered </p>
                    </div>
                    <div className=' flex flex-col items-center justify-center fw-semibold w-[50%] my-2 my-sm-0 sm:w-[25%] lg:w-[20%] border-r-[1px] border-opacity-50 ' >
                        <p className='text-3xl sm:text-4xl tracking-wider ' > <NumberCounter start={7} num={15} timing={93} /> +</p>
                        <p className='text-xl sm:text-xl fw-normal ' >Years of Experience </p>
                    </div>
                    <div className=' flex flex-col items-center justify-center fw-semibold w-[50%] my-2 my-sm-0 sm:w-[25%] lg:w-[20%] sm:border-r-[1px] border-opacity-50 ' >
                        <p className='text-3xl sm:text-4xl tracking-wider ' ><NumberCounter start={2} num={10} timing={93} />+</p>
                        <p className='text-xl sm:text-xl fw-normal ' >Tools Used </p>
                    </div>
                    <div className=' flex flex-col items-center justify-center fw-semibold w-[50%] my-2 my-sm-0 sm:w-[25%] lg:w-[20%] d-none d-lg-flex ' >
                        <p className='text-3xl sm:text-4xl tracking-wider ' ><NumberCounter start={0} num={7} timing={93} />+</p>
                        <p className='text-xl sm:text-xl fw-normal ' >Countries Served Globally </p>
                    </div>

                </main>
            }
        </>
    )
}

export default Banner